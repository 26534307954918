import React from "react";

function LockIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 10H4.5L3.5 10.5L3 11.5L2.5 18.5L3 19.5L4 20H16.5H18L19 19V12.5V10.5L17.5 10Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.3832C9.93029 2.3832 8.9044 2.80814 8.148 3.56454C7.3916 4.32094 6.96666 5.34683 6.96666 6.41654V9.5332H15.0333V6.41654C15.0333 5.34683 14.6084 4.32094 13.852 3.56454C13.0956 2.80814 12.0697 2.3832 11 2.3832ZM16.1333 9.5332V6.41654C16.1333 5.05509 15.5925 3.74941 14.6298 2.78672C13.6671 1.82403 12.3614 1.2832 11 1.2832C9.63855 1.2832 8.33287 1.82403 7.37018 2.78672C6.4075 3.74941 5.86666 5.05509 5.86666 6.41654V9.5332H4.58333C3.26705 9.5332 2.2 10.6003 2.2 11.9165V18.3332C2.2 19.6495 3.26705 20.7165 4.58333 20.7165H17.4167C18.7329 20.7165 19.8 19.6495 19.8 18.3332V11.9165C19.8 10.6003 18.7329 9.5332 17.4167 9.5332H16.1333ZM4.58333 10.6332C3.87456 10.6332 3.3 11.2078 3.3 11.9165V18.3332C3.3 19.042 3.87456 19.6165 4.58333 19.6165H17.4167C18.1254 19.6165 18.7 19.042 18.7 18.3332V11.9165C18.7 11.2078 18.1254 10.6332 17.4167 10.6332H4.58333Z"
        fill="#1F2430"
      />
      <circle cx="11" cy="15.125" r="1.375" fill="#1F2430" />
    </svg>
  );
}

export default LockIcon;
