import React, { FC, PropsWithChildren } from "react";
import { Stack, IStackProps } from "@fluentui/react";
export const HorizontalStack: FC<IStackProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Stack {...rest} enableScopedSelectors horizontal>
      {children}
    </Stack>
  );
};

export const VerticalStack: FC<PropsWithChildren | IStackProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Stack {...rest} enableScopedSelectors>
      {children}
    </Stack>
  );
};
