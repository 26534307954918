import {
  DefaultButton,
  mergeStyles,
  IButtonStyles,
  Text,
} from "@fluentui/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HorizontalStack } from "../Stack";

interface BackButtonProps {
  onClickFunction: () => void;
}

const textClass = mergeStyles({
  fontWeight: "normal",
  fontSize: "16px",
  fontFamily:"Poppins"

});


export const BackButton: FC<BackButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <HorizontalStack style={{cursor:"pointer"}} onClick={props.onClickFunction}>
      <img src={require('../../assets/Icons/Union.png')} 
      style={{marginRight:"0.5rem", backgroundColor:'', alignSelf:'center', marginTop:'0.2rem',width:'15px',height:"11px",color:"#323232"}}
      />
      <Text className={textClass}>{t("back")}</Text>
    </HorizontalStack>
  );
};
