import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormModal } from "../Modal";
import { Person, ViewType } from "@microsoft/mgt-react";
import {
  Stack,
  mergeStyles,
} from "@fluentui/react";
import { HorizontalStack } from "../../Stack";
import Loader from "../../Loader";
const loading = mergeStyles({
  margin: "2%",
  height: "100%",
  paddingTop: "10%",
});


export const VersionMembersModal: FC<any> = (props) => {
  const { t } = useTranslation();

  return (
    <FormModal
      title={String(t("members_of_doc"))}
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      cancelButton="true"
      width="25%"
    >
      {props.isLoading ? (
        <Stack className={loading}>
          <Loader />
        </Stack>
      ) : (
        <div>
          {props.members &&
            props.members.map((mem: any) => {
              return (
                <div key={mem} style={{ padding: "1%" }}>
                  <HorizontalStack
                    horizontalAlign="space-between"
                    title={mem.email}
                  >
                    <Stack.Item align="center">
                      <Person personQuery={mem.email} view={ViewType.oneline} />
                    </Stack.Item>
                    <Stack.Item align="center" style={{ paddingRight: "1%" }}>
                      {mem.roles[0] === "read" && props.userScope === "edit" ? (
                        <HorizontalStack>
                          <img
                            src={require("../../../assets/Icons/lock.png")}
                          />
                          <img
                            src={require("../../../assets/Icons/Edit.png")}
                          />
                        </HorizontalStack>
                      ) : (
                        props.userScope === "view" && (
                          <img
                            src={require("../../../assets/Icons/lock.png")}
                          />
                        )
                      )}
                    </Stack.Item>
                  </HorizontalStack>
                </div>
              );
            })}
        </div>
      )}
    </FormModal>
  );
};
