import { FC } from "react";
import { FunctionalityButton } from "../FunctionalityButton";
import { VerticalStack } from "../../../components/Stack";
import { IStackStyles, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { functionalityState } from "../../redux/DocumentStackSlice";
import { colorPallete } from "../../../assets/color";
import "./index.css";

const verticalStackStyles: IStackStyles = {
  root: {
    minHeight: "15rem",
    width: "100%",
    backgroundColor: "",
    justifyContent: "space-evenly",
    marginTop:"1%"
  },
};

const click2 = () => {};

export const FunctionalityButtonStack: FC = () => {
  const dispatch = useDispatch();
  function click() {
    dispatch(functionalityState("draft"));
  }

  function click1() {
    // dispatch(functionalityState("negotiate"));
  }
  const { t } = useTranslation();
  return (
    <VerticalStack styles={verticalStackStyles}>
      <Text
        style={{
          fontSize: "32px",
          color: colorPallete.black2,
          textAlign: "center",
          fontWeight: "500",
          lineHeight: "40px",
          fontStyle: "normal",
          fontFamily: "Poppins",
        }}
      >
        {t("secure_document_negotiation_made_easy")}
      </Text>
      <div className="ButtonStack">
        <FunctionalityButton
          buttonText={t("draft")}
          color={colorPallete.btBlue}
          onClickFunction={click}
          flex={"1 1 30vw"}
          icon="draft"
        />
        <FunctionalityButton
          buttonText={t("negotiate")}
          color={colorPallete.btGreen}
          onClickFunction={click1}
          flex={"1 1 30vw"}
          icon="negotiate"
        />
        <FunctionalityButton
          buttonText={t("sign")}
          color={colorPallete.btRed}
          onClickFunction={click2}
          flex={"1 1 30vw"}
          icon="sign"
        />
      </div>
    </VerticalStack>
  );
};
