import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './index.css';

interface FadeTransitionProps {
  children: React.ReactNode;
}

function FadeTransition({ children }: FadeTransitionProps) {
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShow(false); // Trigger exit animation
    setTimeout(() => {
      setShow(true); // Trigger enter animation after a delay
    }, 10);
  }, [location]);

  return (
    <div className={`fade-wrapper ${show ? 'fade-enter-active' : 'fade-exit-active'}`}>
      {children}
    </div>
  );
}

export default FadeTransition;