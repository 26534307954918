import React from "react";
import ReactDOM from "react-dom/client";
import LanguageDetector from "i18next-browser-languagedetector";
import { Providers, CacheService } from "@microsoft/mgt-element";
import HttpApi from "i18next-http-backend";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import App from "./App";
import "./index.css";
import translationEn from "./assets/locales/en/translation.json";
import translationFr from "./assets/locales/fr/translation.json";
import translationEs from "./assets/locales/es/translation.json";
import { isValidEmail } from "./utils";
import { Msal2Provider, PromptType } from "@microsoft/mgt-msal2-provider";

CacheService.config.users.isEnabled = true;
CacheService.config.people.isEnabled = true;
CacheService.config.users.invalidationPeriod = 86400000;
CacheService.config.people.invalidationPeriod = 86400000;

const resources = {
  en: { translation: translationEn },
  fr: { translation: translationFr },
  es: { translation: translationEs },
  // Add more language resources as needed
};
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    resources,
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    react: { useSuspense: false },
  });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const url = window.location.search;
const LINK = window.location.href;

const loginHint = new URLSearchParams(url).get("loginHint");
loginHint &&
  loginHint?.length > 0 &&
  localStorage.setItem("loginHint", loginHint);
  loginHint &&
  loginHint?.length > 0 && localStorage.setItem("LINK", LINK);

function getLogin() {
  if (loginHint && loginHint.length > 0) {
    return isValidEmail(loginHint);
  } else {
    return (
      localStorage.getItem("loginHint") &&
      isValidEmail(localStorage.getItem("loginHint"))
    );
  }
}

Providers.globalProvider = new Msal2Provider({
  clientId: process.env.REACT_APP_CLIENT_ID!,
  redirectUri: process.env.REACT_APP_REDIRECT_URI!,
  authority: process.env.REACT_APP_AUTHORITY!,
  isMultiAccountEnabled: false,
  prompt: getLogin() ? PromptType.LOGIN : PromptType.SELECT_ACCOUNT,
  loginHint:
    localStorage.getItem("loginHint")!,
  options: {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID!,
      redirectUri: process.env.REACT_APP_REDIRECT_URI!,
      authority: process.env.REACT_APP_AUTHORITY!,
      postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI!,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
    system:{
      loggerOptions:{
        logLevel: 0,
        loggerCallback: (level, message, containsPii)=>{
          if(containsPii){
            return;
          }
          switch(level){
            case 0:
              console.error(message);
              return;
            case 1:
              console.warn(message);
              return;    
            case 2:
              console.info(message);
              return;
            case 3:
              console.debug(message);
              return;
          }
        },
        piiLoggingEnabled: false
      }
    }
  },
});

root.render(<App />);
