import { Stack, Text } from "@fluentui/react";
import { FC } from "react";
import { VerticalStack } from "../../components/Stack";
import DocumentNotFound from "../../assets/Icons/svg/docNotFound";
import { colorPallete } from "../../assets/color";
import { Providers } from "@microsoft/mgt-element";
import Help from "../../components/Help";
import { t } from "i18next";

export const PageNotFound: FC<any> = () => {
  function signIn() {
    if (Providers.globalProvider.state) {
      Providers.globalProvider.login!();
    }
  }
  return (
    <VerticalStack style={styles.container}>
      <DocumentNotFound />
      <Stack style={styles.top}>
        <Text style={styles.longText}>{t("houston_problem")}</Text>
        <Text style={{ ...styles.mediumText, textAlign: "center" }}>
         { t("page_not_found_msg")}
        </Text>
        <Text
          style={{ ...styles.signInButton, textAlign: "center" }}
          onClick={signIn}
        >
       { t("sign_in")}
        </Text>
      </Stack>
      <Help/>
    </VerticalStack>
  );
};

const styles = {
  container: {
    width: "100%",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "8px",
    height: "380px",
    justifyContent: "space-between",
  },
  top: {
    width: "64%",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "24px",
  },
  longText: {
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: colorPallete.black1,
  },
  mediumText: {
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
    color: "#717070",
  },
  signInButton: {
    minWidth: "120px",
    padding: "12px 24px",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    backgroundColor: colorPallete.btBlue,
    color: "#F4F4F4",
    fontWeight: "500",
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "20px",
    borderRadius: "4px",
    cursor: "pointer",
  },
};
