import { FC, useEffect, useState } from "react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { Checkbox, Text } from "@fluentui/react";
import { Document } from "../../../models";
import Draft from "../MultiShareModal/icons/Draft";
import Recieved from "../MultiShareModal/icons/Received";
import Sent from "../MultiShareModal/icons/Sent";
import Redline from "../MultiShareModal/icons/Redline";
import { useTranslation } from "react-i18next";
import { getFormattedDateTime } from "../../../utils";
import { useAppSelector } from "../../../hooks/hooks";
import "./selectDocument.css";
import PdfIcon from "../../../assets/Icons/svg/pdfIcon";

type SelectDocumentProps = {
  document: Document;
  selectedDocuments: Document[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};

type SelectDocumentGroupProps = {
  selectedDocuments: Document[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
};
const selectDocumentStackStyle = {
  gap: "10px",
  width: "696px",
  height: "42px",
  padding: "9px 20px",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: "6px",
  border: "1px solid #E8ECF4",
  cursor:"pointer",
  ":hover": {
    backgroundColor: "#F3F5F9",
    border: "1px solid #007CFF",
  },
};

const textStyle = {
  color: "#717070",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 400,
  lineHeight: "14px",
};

const dateStyle = {
  color: "#1F2430",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 600,
  lineHeight: "14px",
};

const selectDocumentGroupStyle = {
  maxHeight: "600px",
  background: "#FFF",
  padding: "14px",
  display: "flex",
  gap: "6px",
  overflowY: "auto",
};
const documentNameStyle = {
  color: "#1F2430",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "14px",
  width: "238px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const SelectDocument: FC<SelectDocumentProps> = (props) => {
  const { t } = useTranslation();
  const [selectedVersion, setSelectedVersion] = useState(() => {
    const initialSelectedVersion = props.selectedDocuments.find(
      (doc) => doc.rootDocumentId === props.document.rootDocumentId
    );

    return initialSelectedVersion || props.document.versions[0];
  });
  const [checked, setChecked] = useState(
    props.selectedDocuments.some(
      (doc) => doc.rootDocumentId === props.document.rootDocumentId
    )
  );
  const handleSelectChange = (event: any) => {
    const selectedIndex = event.target.selectedIndex;
    const newSelectedVersion = props.document.versions[selectedIndex];
    setSelectedVersion(newSelectedVersion);
  };

  const handleCheckboxChange = (e:any) => {
    // e.stopPropagation();
    setChecked(chec=>!chec);
  };

  useEffect(() => {
    if (checked) {
      props.setSelectedDocuments((prevSelectedDocuments) => {
        const filteredDocuments = prevSelectedDocuments.filter(
          (doc) => doc.rootDocumentId !== props.document.rootDocumentId
        );

        return [...filteredDocuments, selectedVersion];
      });
      props.setError(false);
    }else{
      props.setSelectedDocuments((prevSelectedDocuments) =>
        prevSelectedDocuments.filter(
          (doc) => doc.rootDocumentId !== props.document.rootDocumentId
        )
      );
    }
  }, [selectedVersion, checked]);

  function getIcon(fileType: any, isRedlined: any) {
    if (fileType == "sent") {
      return <Sent />;
    } else if (fileType == "received") {
      return <Recieved />;
    } else if (isRedlined) {
      return <Redline />;
    } else {
      return <Draft />;
    }
  }
  return (
    <HorizontalStack styles={{ root: selectDocumentStackStyle }} onClick={handleCheckboxChange}>
      <HorizontalStack style={{
        alignItems:'center',
        gap:"12px",
        width:"50%"
      }}>
      <Checkbox
        styles={{
          checkbox: {
            width: "12px",
            height: "12px",
            stroke: "#007CFF",
            strokeWidth: "0.8px",
            borderColor: "#007CFF",
          },
        }}
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <HorizontalStack style={{ gap: "4px" }}>
        {getIcon(selectedVersion.fileType, selectedVersion.isRedlined)}
        {selectedVersion?.pdfDetails && selectedVersion.pdfDetails?.url && (
          <PdfIcon />
        )}
      </HorizontalStack>
      <Text style={{ whiteSpace: "nowrap", ...documentNameStyle }}>
        {props.document.rootDocumentName?.replace(/\.[^/.]+$/, "")}
      </Text>
      </HorizontalStack>
      <HorizontalStack style={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "50%",
      }}>
      <select
        style={{ ...selectStyle }}
        id="versionSelect"
        onChange={handleSelectChange}
        value={selectedVersion ? `v${selectedVersion.documentVersion}` : ""}
      >
        {props.document.versions.map(
          (version, index) =>
            version.documentVersion !== null && (
              <option key={index} value={`v${version.documentVersion}`}>
                {index === 0 ? (
                    `v${version?.documentVersion} ${t("most_recent")}`
                ) : (
                  `v${version?.documentVersion}`
                )}
              </option>
            )
        )}
      </select>
      <HorizontalStack
        styles={{
          root: { gap: "4px", minWidth: "155px", justifyContent: "flex-end" },
        }}
      >
        <Text style={textStyle}>
          {selectedVersion?.fileType === "draft"
            ? t("last_saved_on")
            : selectedVersion?.fileType === "sent"
            ? t("sent_on")
            : t("received_on")}
        </Text>
        <Text style={dateStyle}>
          {getFormattedDateTime(new Date(selectedVersion?.lastUpdatedDate!))[0]}
          &nbsp;
          {getFormattedDateTime(new Date(selectedVersion?.lastUpdatedDate!))[1]}
        </Text>
      </HorizontalStack>
      </HorizontalStack>
    </HorizontalStack>
  );
};

const SelectDocumentGroup: FC<SelectDocumentGroupProps> = (props) => {
  const documents = useAppSelector(
    (state) => state.fetchDocumentWithVersions.documents
  );

  return (
    <VerticalStack styles={{ root: selectDocumentGroupStyle }}>
      {documents.map((document: Document) => (
        <SelectDocument
          key={document.rootDocumentId}
          document={document}
          selectedDocuments={props?.selectedDocuments}
          setSelectedDocuments={props?.setSelectedDocuments}
          setError={props?.setError}
        />
      ))}
    </VerticalStack>
  );
};

export default SelectDocumentGroup;

const selectStyle = {
  width: "132px",
  borderRadius: "4px",
  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
  background: "#F8F8F8",
  padding: "4px",
  color: "#18468F",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  cursor: "pointer",
};
