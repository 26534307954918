import { FC, useState } from "react";
import { Document, UserModel } from "../../../models";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { Person, ViewType } from "@microsoft/mgt-react";
import { useTranslation } from "react-i18next";
import {
  mergeStyles,
  IStackStyles,
  Label,
  Text,
  Stack,
  DefaultButton,
} from "@fluentui/react";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { CopyDocumentModal } from "../../modals/CopyDocumentModal";
import useModal from "../../../hooks/useModal";
import { DocPreviewModal } from "../../modals/DocPreviewModal";
import { colorPallete } from "../../../assets/color";
import { Thumbnails } from "../../Thumbnails";
import OpenFile from "../../../navigation/NoobOrPro";
initializeFileTypeIcons();

const styles = {
  documentDivStyle: {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "0.3rem",
    border: "1px solid #DFE6EF",
    // marginLeft: 9,
    alignItems: "center",
    // boxShadow: "0 8px 16px 0 rgba(0, 0, 0, .21), 0 4px 8px 0 rgba(0, 0, 0, .15)",
  },
  greyText: { color: colorPallete.grey, fontFamily: "Poppins" },
  IconStyle: {
    paddingRight: "2.5%",
    paddingTop: "2%",
    overflow: "visible",
  },
  DocumentsStack: {
    width: "75rem",
    height: "6rem",

    alignSelf: "center",
    borderRadius: "0.2rem",
  },
  DocDetails: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  ThumbnailDiv: {
    margin: "0.5%",
    border: "1px solid grey",
    borderRadius: "0.2rem",
    height: "5rem",

    cursor: "pointer",
  },
  OpenButton: {
    backgroundColor: colorPallete.btLightBlue,
    height: "35px",
    width: "80px",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: colorPallete.black,
    fontSize: "13px",
    lineHeight: "16px",
    fontFamily: "Poppins",
  },
  OpenButtonStack: {
    padding: "1% 0%",
    width: "10%",
    alignItems: "center",
    justifyContent: "center",
  },
};

const BodyStyles = mergeStyles({
  fontWeight: 500,
  fontSize: "14px",
  padding: "1%",
  lineHeight: "20px",
  color: "#323232",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: "80%",
  maxWidth: "80%",
  fontFamily: "Poppins",
});

const outerStackStyles: IStackStyles = {
  root: {
    paddingLeft: "4%",
    flex: 1,
    paddingTop: "1%",
    paddingBottom: "1%",
    backgroundColor: "",
    maxWidth: "35%",
  },
};

type Props = {
  user?: UserModel;
  document: Document;
};

export const DocumentInGrid: FC<Props> = (props) => {
  // const openFile = (webUrl: string | undefined) => {
  //   window.open(`ms-word:ofe|u|${webUrl}`, "_self");
  // };

  const dateModifier = (date: Date | undefined | string) => {
    if (date) {
      let a = new Date(date).toString().split(" ");
      return a[1] + " " + a[2] + ", " + a[3];
    }
  };
  const { t } = useTranslation();
  const { isModalOpen, showModal, hideModal } = useModal();
  const [previewModal, setPreviewModal] = useState(false);

  function showPreviewModal() {
    setPreviewModal(true);
  }
  function hidePreviewModal() {
    setPreviewModal(false);
  }


  return (
    <Stack style={styles.DocumentsStack}>
      <Stack
        style={styles.documentDivStyle}
        title={props.document?.rootDocumentName}
      >
        <HorizontalStack
          horizontalAlign="space-between"
          style={styles.DocDetails}
        >
          <div style={styles.ThumbnailDiv}>
            <Thumbnails
              driveItemId={props.document.driveItemId}
              height={75}
              width={60}
              openFile={() =>
                OpenFile(
                  props.document.documentLink,
                  props.document.documentWebLink
                )
              }
            />
          </div>
          <VerticalStack styles={outerStackStyles}>
            <Label
              htmlFor="documentName"
              style={styles.greyText}
            >
              {t("document_name")}
            </Label>
            <HorizontalStack>
              <Text className={BodyStyles}>
                {props.document?.rootDocumentName}
              </Text>
            </HorizontalStack>
          </VerticalStack>
          <VerticalStack
            style={{ padding: "1% 0%", width: "18%", backgroundColor: "" }}
          >
            <Label
              htmlFor="projectOwner"
              style={styles.greyText}
            >
              {t("project_owner")}
            </Label>
            {/* <Person
              personQuery={
                props.document?.projectOwner
                  ? props.document?.projectOwner
                  : "me"
              }
              view={ViewType.oneline}
              style={{ fontFamily: "Poppins" }}
            ></Person> */}
            <Text className={BodyStyles}>{props.document?.projectOwnerName}</Text>
          </VerticalStack>
          <VerticalStack
            style={{ padding: "1% 2%", width: "15%", backgroundColor: "" }}
          >
            <Label
              htmlFor="folderName"
              style={styles.greyText}
            >
              {t("project_name")}
            </Label>
            <Text className={BodyStyles}>{props.document?.projectName}</Text>
          </VerticalStack>
          <VerticalStack style={{ padding: "1% 0%", width: "15%" }}>
            <Label
              htmlFor="lastUpdatedDate"
              style={styles.greyText}
            >
              {t("last_updated")}
            </Label>
            <Text className={BodyStyles}>
              {dateModifier(props.document?.lastUpdatedDate)}
            </Text>
          </VerticalStack>
          <VerticalStack style={styles.OpenButtonStack}>
            <DefaultButton
              style={styles.OpenButton}
              onClick={() =>
                OpenFile(
                  props.document.documentLink,
                  props.document.documentWebLink
                )
              }
            >
              {t("open")}
            </DefaultButton>
          </VerticalStack>
        </HorizontalStack>
      </Stack>
      <CopyDocumentModal
        document={props.document}
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
      />
      <DocPreviewModal
        fileImage={
          <Thumbnails driveItemId={props.document.driveItemId} height={660} />
        }
        fileName={props.document?.rootDocumentName}
        showModal={showPreviewModal}
        hideModal={hidePreviewModal}
        isModalOpen={previewModal}
      />
    </Stack>
  );
};
