import React from "react";

function WhiteBoxIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.22225 1H1V7.22222H7.22225V1Z" fill="#18468F" />
      <path d="M17.0001 1H10.7778V7.22222H17.0001V1Z" fill="#18468F" />
      <path d="M17.0001 10.7778H10.7778V17H17.0001V10.7778Z" fill="#18468F" />
      <path d="M7.22225 10.7778H1V17H7.22225V10.7778Z" fill="#18468F" />
      <path
        d="M7.22225 1H1V7.22222H7.22225V1Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0001 1H10.7778V7.22222H17.0001V1Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0001 10.7778H10.7778V17H17.0001V10.7778Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22225 10.7778H1V17H7.22225V10.7778Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WhiteBoxIcon;
