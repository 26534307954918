import {
  IStackStyles,
  mergeStyles,
  Text,
  Stack,
  HoverCard,
  IPlainCardProps,
  HoverCardType,
} from "@fluentui/react";
import { FC, useState } from "react";
import { Document, UserModel } from "../../../models";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { useTranslation } from "react-i18next";
import { FormModal } from "../../modals/Modal";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  createNewVersion,
  getDocumentversions,
} from "../../../modules/redux/Documents/document";
import { TextForPageHeaders } from "../../Text";
import Loader from "../../Loader";
import { VersionMembersModal } from "../../modals/VersionMembersModal";
import { colorPallete } from "../../../assets/color";
import { EmailNotesHoverCard } from "../HoverCard";
import { Thumbnails } from "../../Thumbnails";
import LockIcon from "../../../assets/Icons/svg/lockIcon";
import EditIcon from "../../../assets/Icons/svg/editIcon";
import DocumentIcon from "../../../assets/Icons/svg/documentIcon";
import RedLineDocumentIcon from "../../../assets/Icons/svg/redLineDocument";
import OpenFile from "../../../navigation/NoobOrPro";
import {
  getFormattedDateTime,
  getFullName,
  getSplitDocumentName,
} from "../../../utils";
import TrashIcon from "../../../assets/Icons/svg/trashIcon";
import PdfIcon from "../../../assets/Icons/svg/pdfIcon";
import "./index.css";
initializeFileTypeIcons();

const styles = {
  documentDivStyle: {
    height: "16rem",
    minWidth: "13rem",
    width: "13rem",
    margin: 3,
    marginRight: 6,
    zIndex: 1,
    position: "absolute",
  },
  notesDivStyle: {
    height: "10rem",
    minWidth: "13rem",
    width: "13rem",
    backgroundColor: "#f3f2f1",
    borderRadius: "0.4rem",
    border: "2px light grey",
    margin: 3,
    marginRight: 6,
    zIndex: 2,
  },
  ImageStyle: {
    borderTopLeftRadius: "0.4rem",
    borderTopRightRadius: "0.4rem",
  },
  IconStyle: {
    marginBottom: "13%",
    paddingRight: "2%",
    overflow: "visible",
  },
  FileNameStackStyle: {
    height: "22%",
    backgroundColor: "",
    width: "100%",
  },
  notesStyle: {
    backgroundColor: "",
    height: "70%",
  },
  bottomContainer: {
    backgroundColor: "",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
  },
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "",
    cursor: "pointer",
  },
  docText: { fontSize: "18px", paddingBottom: "4%", fontFamily: "Poppins" },
  modalContainer: { backgroundColor: "" },
  button: {
    backgroundColor: colorPallete.btBlue,
    color: "white",
    borderRadius: "4px",
    border: "0px ",
    height: "40px",
    width: "180px",
    fontFamily: "Poppins",
    fontweight: "500",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  iconContainer: {
    backgroundColor: "yellow",
    alignItems: "center",
    width: "12%",
    height: "100%",
    gap: "3%",
  },
  redLinedIcon: {
    backgroundColor: "",
    alignItems: "center",
    width: "12%",
    height: "90%",
    justifyContent: "center",
  },
  receivedIcon: {
    backgroundColor: "",
    marginTop: "12%",
    height: "100%",
    width: "100%",
    alignItems: "center",
  },
  dateAndTime: {
    fontSize: "10px",
    lineHeight: "16px",
    color: colorPallete.black,
    fontWeight: 600,
    paddingLeft: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
  },
  emailNotes: {
    fontSize: "12px",
    lineHeight: "18px",
    color: colorPallete.black,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    fontFamily: "Poppins",
  },
  Info: {
    fontSize: "12px",
    lineHeight: "18px",
    color: colorPallete.black,
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  myDraft: {
    fontSize: "12px",
    lineHeight: "18px",
    color: colorPallete.black,
    fontWeight: 600,
    marginTop: "18px",
    marginBottom: "8px",
    fontFamily: "Poppins",
    height: "38%",
  },
  headers: {
    color: colorPallete.grey,
    fontSize: "11px",
    lineHeight: "18px",
    fontFamily: "Poppins",
  },
  versionName: {
    fontSize: "12px",
    color: colorPallete.black,
    lineHeight: "18px",
    paddingLeft: "10px",
    fontFamily: "Poppins",
    fontWeight: "600",
  },
  dateAndIconStack: {
    width: "91%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fileTypeText: {
    color: colorPallete.grey,
    fontSize: "10px",
    lineHeight: "18px",
    paddingLeft: "10px",
    fontFamily: "Poppins",
  },
  fileTypeTextStack: {
    bottom: "12px",
    width: "100%",
    height: "20%",
    alignSelf: "center",
    left: 5,
  },
  DocumentNameStack: {
    width: "100%",
    top: "50%",
  },
  InfoStack: {
    backgroundColor: "",
    width: "100%",
    gap: "10%",
    alignItems: "flex-start",
  },
  DocCover: {
    height: "250px",
    minWidth: "200px",
    width: "200px",
    background: "rgba(223, 230, 239, 0.55)",
    boxShadow: "0px 4px 6px rgb(0 0 0 / 25%)",
    padding: "15px",
  },
  VersionCard: {
    height: "300px",
    minWidth: "220px",
    width: "220px",
    borderRadius: "1px",
    border: "1px solid #DFE6EF",
    boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, .25)",
  },
  recepientList: {
    marginTop: "2%",
    display: "inline-block",
    height: "100%",
    width: "90%",
  },
  RecepientHoverCard: {
    fontSize: "12px",
    borderRadius: "1rem",
    minWidth: "1px",
    maxWidth: "fit-content",
    color: "rgb(6 42 108)",
    cursor: "pointer",
    lineHeight: "18px",
  },
  CreateVersionButtonsStack: {
    width: "100%",
    justifyContent: "space-around",
    bottom: 0,
    height: "40%",
    alignItems: "center",
  },
  CreateVersionText: {
    width: "94%",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: "4%",
    marginTop: "1%",
  },
};

const uploadModal = mergeStyles({
  display: "flex",
  justifyContent: "flex-start",
  width: "98%",
  minHeight: "8rem",
  alignItems: "center",
  padding: "1%",
});

const success = mergeStyles({
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "30px",
});

const loading = mergeStyles({
  margin: "2%",
  paddingTop: "30px",
});

const stackStyles: IStackStyles = {
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0.4,
  },
};

type Props = {
  version: Document;
  members?: UserModel[];
  length?: number;
  delete?: boolean;
  showModal?: any;
  setDeleteversion?: any;
};

interface Props1 {
  recipients: UserModel[] | undefined;
  fileType: string | undefined;
}

const RenderPerson: FC<Props1> = (props) => {
  const { recipients } = props;
  const remainingCount: any = recipients && recipients?.length - 1;

  if (props.fileType === "received" && recipients?.length === 0) {
    return <Text style={styles.Info}>Me</Text>;
  } else {
    return recipients && recipients.length ? (
      <div style={{ ...styles.recepientList, wordWrap: "break-word" }}>
        <HorizontalStack style={{ width: "130%", flexWrap: "wrap" }}>
          {props.fileType === "received" ? (
            <Text style={styles.Info}>Me</Text>
          ) : (
            <Text style={styles.Info}>{getFullName(recipients[0])}</Text>
          )}

          {props.fileType === "received" ? (
            <RecipientHoverCard recipients={recipients} />
          ) : (
            remainingCount > 0 && (
              <RecipientHoverCard recipients={recipients.slice(1)} />
            )
          )}
        </HorizontalStack>
      </div>
    ) : (
      <></>
    );
  }
};

const RecipientHoverCard: React.FC<{ recipients: UserModel[] }> = ({
  recipients,
}) => {
  const { t } = useTranslation();
  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: () => (
      <div style={{ padding: "10px", backgroundColor: colorPallete.white }}>
        <ul style={{ listStyleType: "none" }}>
          {recipients.map((recipient, index) => (
            <li key={getFullName(recipient)}>{getFullName(recipient)}</li>
          ))}
        </ul>
      </div>
    ),
    directionalHint: 1,
  };
  return (
    <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps}>
      <div style={styles.RecepientHoverCard}>
        ,&nbsp;{`+${recipients.length} `}
        {recipients.length === 1 ? t("other") : t("others")}
      </div>
    </HoverCard>
  );
};

export const DocumentVersionInCard: FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [refreshHover, setRefresHover] = useState<boolean>(false);
  const [pdfToolTip, setPdfToolTip] = useState("none");
  const [docToolTip, setDocToolTip] = useState("none");

  const replyAll =
    props.version &&
    props?.version?.shareDetails &&
    props?.version?.shareDetails?.replyAll;
  const reply =
    props.version &&
    props?.version?.shareDetails &&
    props?.version?.shareDetails?.reply;
  const receivedList = replyAll?.filter(
    (user) => user?.userId !== reply?.userId
  );
  const getSentVersionAccessState = useAppSelector((state) => {
    return state.getSentVersionAccess;
  });

  const [membersModal, setMembersModal] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const openFile = (
    wordLocalUrl: string | undefined,
    wordWebUrl: string | undefined
    // fileExtension: string | undefined
  ) => {
    hideDocModal();
    OpenFile(wordLocalUrl, wordWebUrl);
  };

  function showMembersModal() {
    setMembersModal(true);
  }

  function hideMembersModal() {
    setMembersModal(false);
  }

  function showDocModal() {
    setModalOpen(true);
  }

  function hideDocModal() {
    setModalOpen(false);
  }
  function handleCreateNewVersion() {
    if (props.version?.documentId) {
      setModal(true);
      dispatch(createNewVersion(props.version?.documentId)).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          setUpload(true);
          setMessage("create_new_version_success");
          setTimeout(() => {
            disable();
            openFile(res.payload.documentLink, res.payload.documentWebLink);
          }, 2000);
        } else if (res.meta.requestStatus === "rejected") {
          setMessage("something_went_wrong");
        }
        setTimeout(() => {
          dispatch(
            getDocumentversions({
              rootDocumentId: props.version.rootDocumentId,
              limit: 6,
              offset: 0,
            })
          );
          disable();
        }, 4000);
      });
    }
  }

  const Modal = (
    <FormModal
      isModalOpen={modalOpen}
      showModal={showDocModal}
      hideModal={hideDocModal}
      cancelButton={true}
      width="500px"
      height="200px"
      TitleBackgroundColor="white"
    >
      {modal ? (
        <Stack className={uploadModal}>
          {!upload ? (
            <>
              <Stack className={loading}>
                <Loader />
              </Stack>
              <TextForPageHeaders>
                {t("creating_new_version")}
              </TextForPageHeaders>
            </>
          ) : (
            <VerticalStack className={success}>
              <img
                src={require("../../../assets/tick.png")}
                height="50px"
                width="50px"
                alt="Error"
              />
              <TextForPageHeaders>{t(message)}</TextForPageHeaders>
            </VerticalStack>
          )}
        </Stack>
      ) : (
        <Stack className={mergeStyles(styles.modalContainer)}>
          <Stack style={styles.CreateVersionText}>
            <Text className={mergeStyles(styles.docText)}>
              {t("open_document_version")}
            </Text>
          </Stack>
          <HorizontalStack
            style={{
              ...styles.CreateVersionButtonsStack,
              position: "absolute",
            }}
          >
            <Stack
              onClick={() => {
                handleCreateNewVersion();
              }}
              style={styles.button}
            >
              {t("create_new_version")}
            </Stack>
            <Stack
              onClick={() => {
                openFile(
                  props.version.documentLink,
                  props.version.documentWebLink
                  // props.version.fileExtension
                );
              }}
              style={styles.button}
            >
              {t("open_read_only")}
            </Stack>
          </HorizontalStack>
        </Stack>
      )}
    </FormModal>
  );

  const disable = () => {
    setMessage("");
    setModal(false);
    setUpload(false);
    hideDocModal();
  };

  const getFileTypeText = (fileType: string | undefined) => {
    if (fileType === "draft") {
      return t("last_saved_on");
    } else if (fileType === "sent") {
      return t("sent_on");
    } else {
      return t("received_on");
    }
  };

  const getFileIcon = (props: Document) => {
    if (props.fileType === "received" || "sent") {
      return (
        <EmailNotesHoverCard
          notes={props.shareDetails?.emailNotes ?? ""}
          fileType={props.fileType}
          sharedBy={reply}
          sharedTo={replyAll}
          receivedList={receivedList}
          projectName={props.projectName}
          subject={props.shareDetails?.subject}
        />
      );
    } else if (props.fileType === "draft" && props.isRedlined === true) {
      return <RedLineDocumentIcon />;
    } else if (props.fileType === "draft" && props.isRedlined === false) {
      return <DocumentIcon />;
    }
  };

  return (
    <div style={{ ...styles.VersionCard, position: "relative" }}>
      <VerticalStack styles={stackStyles}>
        <Thumbnails driveItemId={props.version.driveItemId} height={550} />
      </VerticalStack>
      <div style={{ ...styles.DocCover, position: "absolute" }}>
        {Modal}
        <VerticalStack
          className={mergeStyles(styles.container)}
          onClick={() => {
            if (
              props.version.fileType === "sent" &&
              !props.delete &&
              !refreshHover
            ) {
              showDocModal();
            } else if (!props.delete && !refreshHover) {
              openFile(
                props.version.documentLink,
                props.version.documentWebLink
              );
            }
          }}
        >
          <HorizontalStack style={{ width: "100%", height: "75%" }}>
            <VerticalStack style={{ height: "100%", gap: "10%", width: "88%" }}>
              {props.version.fileType !== "draft" ? (
                <VerticalStack style={styles.FileNameStackStyle}>
                  <Text style={styles.headers}>{t("from")}:</Text>
                  {props.version.fileType === "sent" ? (
                    <Text style={styles.Info}>{t("me")}</Text>
                  ) : props.version.fileType === "received" ? (
                    <Text style={styles.Info}>
                      {(reply && getFullName(reply)) ?? ""}
                    </Text>
                  ) : null}
                </VerticalStack>
              ) : (
                <Text style={styles.myDraft}>{t("my_draft")}</Text>
              )}

              {props.version.fileType !== "draft" ? (
                <VerticalStack style={styles.InfoStack}>
                  <VerticalStack style={{ width: "100%" }}>
                    <Text style={styles.headers}>{t("to")}:</Text>
                    {replyAll?.length === 0 &&
                    props.version.fileType === "sent" ? (
                      <VerticalStack
                        style={{
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Text style={{ ...styles.Info, color: "#717070" }}>
                          {t("sharing_progress")}
                        </Text>
                        <Text
                          style={{
                            ...styles.Info,
                            color: colorPallete.btBlue,
                            textDecoration: "underline",
                            cursor: "pointer",
                            zIndex: 10,
                          }}
                          onMouseEnter={() => {
                            setRefresHover(true);
                          }}
                          onMouseLeave={() => {
                            setRefresHover(false);
                          }}
                          onClick={() => {
                            window.location.reload();
                          }}
                        >
                          {t("click_to_refresh")}
                        </Text>
                      </VerticalStack>
                    ) : null}
                    {props.version.fileType === "sent" &&
                    replyAll?.length !== 0 ? (
                      <Text style={styles.Info}>
                        <RenderPerson
                          recipients={replyAll}
                          fileType={props.version.fileType}
                        />
                      </Text>
                    ) : null}
                    {props.version.fileType === "received" && (
                      <Text style={styles.Info}>
                        <RenderPerson
                          recipients={receivedList}
                          fileType={props.version.fileType}
                        />
                      </Text>
                    )}
                  </VerticalStack>

                  {props.version.shareDetails?.subject &&
                    props.version.shareDetails?.subject !== "" && (
                      <VerticalStack
                        style={{
                          ...styles.notesStyle,
                          width: "88%",
                          backgroundColor: "",
                          marginTop: "5%",
                        }}
                        title={props.version.shareDetails?.subject ?? ""}
                      >
                        <Text style={styles.headers}>{t("Subject")}:</Text>
                        <Text
                          className={mergeStyles(styles.emailNotes)}
                          style={{
                            WebkitLineClamp: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontWeight: 600,
                          }}
                        >
                          {props.version.shareDetails?.subject ?? ""}
                        </Text>
                      </VerticalStack>
                    )}
                </VerticalStack>
              ) : (
                <VerticalStack
                  style={{ ...styles.InfoStack, justifyContent: "flex-end" }}
                >
                  <VerticalStack style={{ ...styles.DocumentNameStack }}>
                    <Text style={styles.headers}>Document :</Text>
                    <Text
                      style={{
                        ...styles.Info,
                        width: "99%",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                      }}
                      title={props.version.rootDocumentName}
                    >
                      {getSplitDocumentName(props.version.rootDocumentName) ??
                        ""}
                    </Text>
                  </VerticalStack>
                </VerticalStack>
              )}
            </VerticalStack>
            <VerticalStack
              style={{
                width: "12%",
                height: "40%",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                gap: "12%",
              }}
            >
              {getFileIcon(props.version)}
              {props.version.fileType === "draft" &&
              props.version.isRedlined === true ? (
                <RedLineDocumentIcon />
              ) : (
                props.version.fileType === "draft" &&
                props.version.isRedlined === false && <DocumentIcon />
              )}
              {props.version?.pdfDetails && (
                <>
                  <HorizontalStack style={{ alignItems: "flex-start" }}>
                    <img
                      alt=""
                      src={require("../../../assets/Icons/ViewPDF.png")}
                      style={{ display: pdfToolTip }}
                    />
                    <div
                      id="pdf"
                      onClick={(event) => {
                        window.open(props.version.pdfDetails?.url);
                        event.stopPropagation();
                      }}
                      onMouseEnter={() => {
                        setPdfToolTip("flex");
                      }}
                      onMouseLeave={() => {
                        setPdfToolTip("none");
                      }}
                    >
                      <PdfIcon />
                    </div>
                  </HorizontalStack>
                </>
              )}
            </VerticalStack>
          </HorizontalStack>
        </VerticalStack>
        <HorizontalStack
          style={{ ...styles.fileTypeTextStack, position: "absolute" }}
          horizontalAlign="space-between"
          verticalAlign="end"
        >
          <VerticalStack style={{ width: "100%" }}>
            {props.version.lastUpdatedDate !== null ? (
              <HorizontalStack
                style={{ width: "90%", justifyContent: "space-between" }}
              >
                <Text style={styles.fileTypeText}>
                  {getFileTypeText(props.version.fileType)}
                </Text>
                <Text style={styles.versionName}>
                  V{props.version.documentVersion}
                </Text>
              </HorizontalStack>
            ) : null}
            <HorizontalStack style={styles.dateAndIconStack}>
              {props.version.lastUpdatedDate !== null ? (
                <VerticalStack>
                  <Text style={styles.dateAndTime}>
                    {getFormattedDateTime(props.version.lastUpdatedDate!)[0]}
                  </Text>
                  <Text style={styles.dateAndTime}>
                    {getFormattedDateTime(props.version.lastUpdatedDate!)[1]}
                  </Text>
                </VerticalStack>
              ) : null}
              <HorizontalStack
                style={{
                  width:
                    props.length === 1
                      ? ""
                      : props.version.fileType !== "sent"
                      ? "25%"
                      : "",
                  justifyContent: "space-between",
                  backgroundColor: "",
                }}
              >
                {props.version.fileType === "sent" ? (
                  <HorizontalStack style={{ alignItems: "center" }}>
                    <img
                      alt=""
                      src={require("../../../assets/Icons/ViewDoc.png")}
                      style={{ display: docToolTip }}
                    />
                    <div
                      onClick={() => {
                        showDocModal();
                      }}
                      onMouseEnter={() => {
                        setDocToolTip("flex");
                      }}
                      onMouseLeave={() => {
                        setDocToolTip("none");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <LockIcon />
                    </div>
                  </HorizontalStack>
                ) : (
                  <HorizontalStack>
                    <img
                      alt=""
                      src={require("../../../assets/Icons/EditDoc.png")}
                      style={{
                        display: docToolTip,
                        position: "absolute",
                        right: props.length !== 1 ? "66px" : "41px",
                        zIndex: 2,
                      }}
                    />
                    <div
                      id="edit"
                      style={{ cursor: "pointer", position: "relative" }}
                      onClick={() => {
                        if (!props.delete) {
                          openFile(
                            props.version.documentLink,
                            props.version.documentWebLink
                          );
                        }
                      }}
                      onMouseEnter={() => {
                        setDocToolTip("flex");
                      }}
                      onMouseLeave={() => {
                        setDocToolTip("none");
                      }}
                    >
                      <EditIcon />
                    </div>
                  </HorizontalStack>
                )}
                {props.version.fileType !== "sent" && props.length !== 1 ? (
                  <Stack
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (!props.delete) {
                        props.setDeleteversion(props.version);
                        props.showModal();
                      }
                    }}
                  >
                    <TrashIcon />
                  </Stack>
                ) : null}
              </HorizontalStack>
            </HorizontalStack>
          </VerticalStack>
        </HorizontalStack>
      </div>
      <VersionMembersModal
        isLoading={getSentVersionAccessState.isLoading}
        isModalOpen={membersModal}
        showModal={showMembersModal}
        hideModal={hideMembersModal}
        members={getSentVersionAccessState.data}
      />
    </div>
  );
};
