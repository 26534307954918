import React from "react";

function CopyWhiteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle opacity="0.2" cx="15" cy="15" r="15" fill="white" />
      <circle
        opacity="0.2"
        cx="14.9992"
        cy="14.9972"
        r="13.3019"
        fill="white"
      />
      <circle cx="14.6038" cy="14.6038" r="11.6038" fill="white" />
      <path
        d="M10.8 16.8H10.2C9.88174 16.8 9.57652 16.6736 9.35147 16.4485C9.12643 16.2235 9 15.9183 9 15.6V10.2C9 9.88174 9.12643 9.57652 9.35147 9.35147C9.57652 9.12643 9.88174 9 10.2 9H15.6C15.9183 9 16.2235 9.12643 16.4485 9.35147C16.6736 9.57652 16.8 9.88174 16.8 10.2V10.8M14.4 13.2H19.8C20.4627 13.2 21 13.7373 21 14.4V19.8C21 20.4627 20.4627 21 19.8 21H14.4C13.7373 21 13.2 20.4627 13.2 19.8V14.4C13.2 13.7373 13.7373 13.2 14.4 13.2Z"
        stroke="#18468F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default CopyWhiteIcon;
