import React, { FC, useEffect, useState } from "react";
import { Stack, Text } from "@fluentui/react";
import "./progress.css";
import { t } from "i18next";

type ProgressBarProps = {
  progress: number;
  total: number;
  isSuccess: boolean;
};

const ProgressBar: FC<ProgressBarProps> = (props) => {
  const [progress, setProgress] = useState<number>(0);
  const [displayedProgress, setDisplayedProgress] = useState<number>(0);

  useEffect(() => {
    // Smoothly increment progress
    const incrementProgress = () => {
      if (displayedProgress < props.progress) {
        setDisplayedProgress((prev) => Math.min(prev + 1, props.progress));
      }
    };

    const interval = setInterval(incrementProgress, 200); // Adjust interval for smoothness

    return () => clearInterval(interval);
  }, [props.progress, displayedProgress]);

  // Update progress when props.progress changes
  useEffect(() => {
    setProgress(props.progress);
  }, [props.progress]);

  return (
    <Stack className="progressBarContainer">
      <Text className="progressBarText">
        {displayedProgress < 60 ? t("importing_docs") : t("processing_docs")}
      </Text>
      <Stack className="progressBarWrapper">
        <Stack
          className="progressBar"
          style={{
            width: `${displayedProgress}%`,
            transition: "width 0.5s ease-in-out",
          }}
        >
          <Stack className="progressText">{`${displayedProgress}%`}</Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProgressBar;
