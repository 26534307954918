import React, { FormEvent, useCallback, useState } from "react";
import { VerticalStack } from "../../components/Stack";
import { FontIcon, Stack, Text, TextField } from "@fluentui/react";
import { colorPallete } from "../../assets/color";
import axios from "axios";
import Loader from "../../components/Loader";

export const FormModal = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [subjectError, setSubjectError] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onChangeNameTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue?.length === 0) {
        setError(true);
      } else {
        setError(false);
      }
      setName(newValue || "");
    },
    []
  );

  const onChangeEmailTextField = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue?.length === 0) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmail(newValue ?? "");
  };

  const onChangeSubjectTextField = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue?.length === 0) {
      setSubjectError(true);
    } else {
      setSubjectError(false);
    }
    setSubject(newValue ?? "");
  };

  const handleSubmit = () => {
    if (name.length === 0) {
      setError(true);
      return;
    } else if (
      email.length === 0 ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      setEmailError(true);
      return;
    } else if (subject.length === 0) {
      setSubjectError(true);
      return;
    } else if (name.length > 0 && email.length > 0 && subject.length > 0) {
      let obj = {
        email: email,
        fullName: name,
        question: subject,
      };
      setModal(true);
      setLoading(true);
      axios
        .post(
          "https://btable-9leaps-api-dev.azurewebsites.net/api/v1/support/ticket",
          {
            obj,
          }
        )
        .then((res: any) => {
            disable()
        }).catch(()=>{
            disable()
        });
    }
  };

  const disable = ()=>{
    setLoading(false);
    setName("");
    setEmail("");
    setSubject("");
    setTimeout(() => {
      setModal(false);
    }, 2000); 
  }

  const LoaderJSX = () => {
    return (
      <VerticalStack className="Modal">
        {loading ? (
          <Stack
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: "90%",
            }}
          >
            <Loader />
            <Text style={{ fontSize: "24px", padding: "20px" }}>
              Submitting your request
            </Text>
          </Stack>
        ) : (
          <Stack
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: "90%",
            }}
          >
            <FontIcon
              iconName="SkypeCircleCheck"
              style={{
                fontSize: "50px",
                backgroundColor: "white",
                color: "green",
              }}
            />
            <Text style={{ fontSize: "24px", padding: "20px" }}>
              Submitted successfully
            </Text>
          </Stack>
        )}
      </VerticalStack>
    );
  };
  return (
    <>
      {modal ? (
        <LoaderJSX />
      ) : (
        <VerticalStack className="Modal">
          <VerticalStack>
            <Text
              style={{
                fontSize: "20px",
                fontWeight: "400",
                color: "black",
                paddingBottom: "5px",
              }}
            >
              Name
            </Text>
            <TextField
              placeholder="Enter your name"
              id="name"
              value={name}
              onChange={onChangeNameTextField}
              styles={{
                fieldGroup: {
                  borderRadius: "8px",
                  backgroundColor: "#f2f2f2",
                  padding: "2px",
                  minHeight: "40px",
                  fontSize: "20px",
                  borderColor: "",
                },
              }}
              borderless
            />
            {error && (
              <Text style={{ color: colorPallete.btRed, fontSize: "16px" }}>
                Please enter a valid name
              </Text>
            )}
          </VerticalStack>
          <VerticalStack>
            <Text
              style={{
                fontSize: "20px",
                fontWeight: "400",
                color: "black",
                paddingBottom: "5px",
                paddingTop: "13px",
              }}
            >
              Email
            </Text>
            <TextField
              id="email"
              placeholder="Enter a valid email address"
              value={email}
              onChange={onChangeEmailTextField}
              borderless
              styles={{
                root: {
                  backgroundColor: "",
                },
                wrapper: {},
                fieldGroup: {
                  borderRadius: "8px",
                  backgroundColor: "#f2f2f2",
                  padding: "2px",
                  minHeight: "40px",
                  border: "none",
                  fontSize: "20px",
                },
                description: {
                  backgroundColor: "red",
                },
              }}
            />
            {emailError && (
              <Text style={{ color: colorPallete.btRed, fontSize: "16px" }}>
                Please enter a valid email address
              </Text>
            )}
          </VerticalStack>
          <VerticalStack>
            <Text
              style={{
                fontSize: "20px",
                fontWeight: "400",
                color: "black",
                paddingBottom: "5px",
                paddingTop: "13px",
              }}
            >
              Your Question
            </Text>
            <TextField
              id="subject"
              placeholder="Write your question"
              value={subject}
              onChange={onChangeSubjectTextField}
              styles={{
                root: {
                  backgroundColor: "",
                },
                wrapper: {},
                fieldGroup: {
                  borderRadius: "8px",
                  backgroundColor: "#f2f2f2",
                  padding: "2px",
                  minHeight: "170px",
                  border: "none",
                  fontSize: "20px",
                },
              }}
              multiline={true}
              resizable={false}
              borderless
            />
            {subjectError && (
              <Text style={{ color: colorPallete.btRed, fontSize: "16px" }}>
                Please enter a subject
              </Text>
            )}
          </VerticalStack>
          <Stack className="submit" onClick={handleSubmit}>
            <Text id="text" style={{ color: "#fff" }}>
              Submit
            </Text>
          </Stack>
        </VerticalStack>
      )}
    </>
  );
};
