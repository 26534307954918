import React, { useState, MouseEvent, useRef, useEffect } from "react";
import { Text } from "@fluentui/react";
import { HorizontalStack, VerticalStack } from "../Stack";
import "./dropdown.css";
import ArrowUpIcon from "../../assets/Icons/svg/arrowUpIcon";
import ArrowDownIcon from "../../assets/Icons/svg/arrowDown";
import { getSplitDocumentName } from "../../utils";
import { ProjectNameCard } from "./Cards/ProjectNameCard";
import { DocumentNameCard } from "./Cards/DocumentNameCard";

interface DropdownProps {
  options: string[] | any[];
  title: string;
  data: any;
  error?: any;
  isDocumentDropdown?: boolean;
  folderName?: string;
  documentId?: any;
  documentItem?: any;
  setDocumentItem?: any;
  rootDocumentId?: any;
}

const CustomDropdown: React.FC<DropdownProps> = ({
  options,
  title,
  data,
  error,
  isDocumentDropdown,
  folderName,
  documentId,
  setDocumentItem,
  documentItem,
  rootDocumentId,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownItem, setDropdownItem] = useState<string | any>("");
  const ref = useRef<HTMLDivElement | null>(null);
  const handleDropdownIconClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  useEffect(() => {
    if (isDocumentDropdown && folderName && folderName?.length > 0) {
      setDropdownItem(null);
    }
  }, [folderName]);

  useEffect(() => {
    if (isDocumentDropdown) {
      setFilteredOptions(options);
      if (rootDocumentId && filteredOptions.length) {
        const defaultSelected = filteredOptions.filter(
          (d) => d.rootDocumentId === rootDocumentId
        )[0];
        if (defaultSelected != null) selectDocument(defaultSelected);
      } else if (rootDocumentId === 0) {
        clearSelectedDocument();
      }
    }
  }, [filteredOptions, rootDocumentId]);

  function selectDocument(item: any) {
    filteredOptions.length > 0 &&
      setDropdownItem(
        filteredOptions.filter(function (i) {
          return i === item;
        })[0]
      );
    filteredOptions.length > 0 &&
      data(
        filteredOptions
          .filter(function (i) {
            return i.rootDocumentName === item.rootDocumentName;
          })[0]
          .rootDocumentName?.split(".docx")[0]
      );
    filteredOptions.length > 0 &&
      documentId(
        filteredOptions.filter(function (i) {
          return i === item;
        })[0].rootDocumentId
      );
    filteredOptions.length > 0 &&
      setDocumentItem(
        filteredOptions.filter(function (i) {
          return i === item;
        })[0]
      );
  }
  function clearSelectedDocument() {
    setDropdownItem("");
    data("");
    documentId(0);
    setDocumentItem(null);
  }

  function handleClick(item: any) {
    if (isDocumentDropdown) {
      selectDocument(item);
    } else {
      filteredOptions.length > 0 &&
        setDropdownItem(
          filteredOptions.filter(function (i) {
            return i === item;
          })[0]
        );
      filteredOptions.length > 0 &&
        data(
          filteredOptions.filter(function (i) {
            return i === item;
          })[0]
        );
    }
    error && error(false);
    setIsOpen(false);
  }

  return (
    <div className="dropDownContainer" ref={ref}>
      {isOpen ? (
        <HorizontalStack
          style={{
            paddingRight: "14px",
          }}
          className="textContainer"
          onClick={handleDropdownIconClick}
        >
          <input
            type="text"
            placeholder={title}
            autoFocus
            onChange={(e) => {
              if (isDocumentDropdown) {
                setFilteredOptions(
                  options.filter(function (i) {
                    return i.rootDocumentName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  })
                );
              } else {
                setFilteredOptions(
                  options.filter(function (i) {
                    return i
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  })
                );
              }
            }}
            style={{
              marginLeft: "14px",
              width: "60%",
              fontFamily: "Poppins",
            }}
            className="dropDownText"
          />
          <HorizontalStack style={{ alignItems: "center" }}>
            {isDocumentDropdown ? (
              <Text className="documentFamily">{folderName}</Text>
            ) : null}
            <ArrowUpIcon />
          </HorizontalStack>
        </HorizontalStack>
      ) : (
        <HorizontalStack
          onClick={() => {
            setIsOpen(!isOpen);
            setFilteredOptions(options);
          }}
          style={{ padding: "10px 14px" }}
          className="textContainer"
          title={
            isDocumentDropdown && String(dropdownItem).length > 0
              ? getSplitDocumentName(dropdownItem?.rootDocumentName)
              : dropdownItem
          }
        >
          {isDocumentDropdown && documentItem !== null ? (
            <DocumentNameCard
              item={documentItem}
              fileType={documentItem?.fileType}
              versionStyle={"versionTextSelect"}
            />
          ) : isDocumentDropdown &&
            !documentItem &&
            dropdownItem !== null &&
            String(dropdownItem).length > 0 ? (
            <DocumentNameCard
              item={dropdownItem}
              fileType={dropdownItem.fileType}
              versionStyle={"versionTextSelect"}
            />
          ) : (
            <>
              {folderName && !isDocumentDropdown ? (
                <Text className="dropDownText">{folderName}</Text>
              ) : dropdownItem ? (
                <Text className="dropDownText">{dropdownItem}</Text>
              ) : folderName && isDocumentDropdown ? (
                <HorizontalStack className="dropDownTextWrapper">
                  <Text className="dropDownText">{title}</Text>
                  <Text className="documentFamily">{folderName}</Text>
                </HorizontalStack>
              ) : (
                <Text className="dropDownText">{title}</Text>
              )}
            </>
          )}
          <ArrowDownIcon />
        </HorizontalStack>
      )}
      {isOpen && (
        <VerticalStack className="itemContainer">
          <VerticalStack className="itemCard">
            {filteredOptions.map((item: any) => {
              if (isDocumentDropdown) {
                return (
                  <HorizontalStack
                    key={item.driveItemId}
                    onClick={() => {
                      handleClick(item);
                    }}
                    className="dropdownCard"
                  >
                    <DocumentNameCard
                      item={item}
                      fileType={item?.fileType}
                      versionStyle={"versionText"}
                    />
                  </HorizontalStack>
                );
              } else {
                return (
                  <ProjectNameCard
                    item={item}
                    handleClick={(item) => {
                      handleClick(item);
                    }}
                  />
                );
              }
            })}
          </VerticalStack>
        </VerticalStack>
      )}
    </div>
  );
};

export default CustomDropdown;
