import { Stack, Text } from "@fluentui/react";
import { HorizontalStack } from "../../Stack";
import { FC } from "react";

type ProjectNameCardProps = {
    item:string;
    handleClick:(item:string)=>void
}

export const ProjectNameCard :FC<ProjectNameCardProps>= ({ item, handleClick }) => {
  return (
    <HorizontalStack
      key={item}
      onClick={(e) => {
        handleClick(item);
      }}
      className="dropdownCard"
    >
      <Text
        style={{
          lineHeight: "24px",
        }}
        className="dropDownText"
      >
        {item}
      </Text>
      <Stack className="tick">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M16.6673 5L7.50065 14.1667L3.33398 10"
            stroke="#18468F"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Stack>
    </HorizontalStack>
  );
};
