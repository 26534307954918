import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "../../../models";
import { ApiClient } from "../../../network/ApiClient";
import { ApiEndpoints } from "../../../network/ApiEndpoints";

type InitialState = {
  isLoading: boolean;
  data: UserModel;
  error: string;
};

const initialState: InitialState = {
  isLoading: false,
  data: {
    id: -1,
    firstName: "",
    lastName: "",
    email: "",
    type: "",
    isOrgUser: false,
    preferDesktopWord: false,
    isFirstTimeLogin: false,
    company: "",
    title: "",
    userId: "",
  },
  error: "",
};

export const getUser = createAsyncThunk("getUser", async () => {
  let response = await ApiClient.get<any>(ApiEndpoints.userDetails(), {}, {});
  return response;
});

const getUserSlice = createSlice({
  name: "getUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getUser.fulfilled,
      (state, action: PayloadAction<UserModel>) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getUser.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {
        id: -1,
        firstName: "",
        lastName: "",
        email: "",
        type: "",
        isOrgUser: false,
        preferDesktopWord: false,
        isFirstTimeLogin: false,
        company: "",
        title: "",
        userId: "",
      };
      state.error = action.error.message ?? "Something went wrong";
    });
  },
});

export const updateUserDetails = createAsyncThunk(
  "updateUserDetails",
  async (payload: any) => {
    let response: any = await ApiClient.put(
      ApiEndpoints.UpdateUserDetails(),
      {
        FirstName: payload.firstName,
        LastName: payload.lastName,
        Company: payload.Company,
        Title: payload.Title,
      },
      {},
      {
        "Content-Type": "application/json",
      }
    );
    return response;
  }
);

const updateUserDetailsSlice = createSlice({
  name: "updateUserDetailsSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateUserDetails.fulfilled,
      (state, action: PayloadAction<UserModel>) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(updateUserDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {
        id: -1,
        firstName: "",
        lastName: "",
        email: "",
        type: "",
        isOrgUser: false,
        preferDesktopWord: false,
        isFirstTimeLogin: false,
        company: "",
        title: "",
        userId:""
      };
      state.error = action.error.message ?? "Something went wrong";
    });
  },
});

export const getUserReducer = getUserSlice.reducer;
export const UpdateUserDetailsReducer = updateUserDetailsSlice.reducer;
