import { useTranslation } from "react-i18next";
import { HorizontalStack } from "../../Stack";
import { Text } from "@fluentui/react";
import {
  getFormattedDateTime,
  getIcon,
  getSplitDocumentName,
} from "../../../utils";
import { Document } from "../../../models";
import { FC } from "react";

type DocumentNameCardProps = {
  item: Document;
  fileType: any;
  versionStyle?: string;
};

export const DocumentNameCard: FC<DocumentNameCardProps> = ({
  item,
  fileType,
  versionStyle,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <HorizontalStack className="documentName">
        {getIcon(fileType)}
        <Text className="dropDownText">
          {getSplitDocumentName(item.rootDocumentName)}
        </Text>
        <Text className={versionStyle}>
          V{item.documentVersion}&nbsp;({t("most_recent")})
        </Text>
      </HorizontalStack>
      <Text className="dateTime">
        {getFormattedDateTime(String(item.lastUpdatedDate))[0]}
        &nbsp;|&nbsp;
        {getFormattedDateTime(String(item.lastUpdatedDate))[1]}
      </Text>
    </>
  );
};
