import { createSlice } from '@reduxjs/toolkit'
import { Document } from '../../../models'

export interface SearchFilesState {
  searchData: Document[]
}

const initialState: SearchFilesState = {
    searchData: [],
}

export const SearchFilesSlice = createSlice({
  name: 'searchFiles',
  initialState,
  reducers: {
     
  },
})
const isSearchReducer =SearchFilesSlice.reducer;
export default isSearchReducer  