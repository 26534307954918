import { FC } from "react";
import { IIconProps, IContextualMenuProps, Stack } from "@fluentui/react";
import { IconButton } from "@fluentui/react/lib/Button";
import i18next from "i18next";
import { useTranslation } from "react-i18next";



const ButtonStyles = {
  root: {
    height: "3rem",
    color:"black",
   
  },

};

const languageprops: IContextualMenuProps = {
  items: [
    {
      key: "en",
      text: "English",
      onClick: (ev) => {
        i18next.changeLanguage("en");
      },
    },
    {
      key: "es",
      text: "Español",
      onClick: (ev) => {
        i18next.changeLanguage("es");
      },
    },

    {
      key: "fr",
      text: "Français",
      onClick: (ev) => {
        i18next.changeLanguage("fr");
      },
    },
  ],
};

const change = (ev?: React.SyntheticEvent<any>) => {
};

const LanguageDropdown: FC = () => {
  const { t } = useTranslation();

const iconPath = require("../../assets/Icons/Language.png")
const globeIcon: IIconProps = {

  imageProps:{
    src:iconPath,
    style: {
      width: '28px',
      height: '28px',
      color:"black"
    }
  },
 style:{
  height:'30px',width:'28px',
 }
};
  return (
    <Stack>
      <IconButton
       title={String(t("localization"))}
        styles={ButtonStyles}
        menuProps={languageprops}
        iconProps={globeIcon}
        onClick={change}
      />
    </Stack>
  );
};
export default LanguageDropdown;
