import customProtocolCheck from "custom-protocol-check";

const OpenFile = (
  localWordUrl: string | undefined,
  webWordUrl: string | undefined,
) => {
  const user = JSON.parse(localStorage.getItem("user")!);
  if (user?.dataDictionary?.preferDesktopWord === "true" || user?.dataDictionary?.preferDesktopWord === true) {
    customProtocolCheck(
      `ms-word:ofe|u|${localWordUrl}`,
      () => {
        if (webWordUrl?.includes("login_hint")) {
          window.open(`${webWordUrl}`, "_self");
        } else {
          window.open(
            `${webWordUrl}&login_hint=${user.email}`,
            "_self"
          );
        }
      },
      () => {
        // window.open();
      },
      5000,
      () => {
        if (webWordUrl?.includes("login_hint")) {
          window.open(`${webWordUrl}`, "_self");
        } else {
          window.open(
            `${webWordUrl}&login_hint=${user.email}`,
            "_self"
          );
        }
      }
    );
  } else {
    if (webWordUrl?.includes("login_hint")) {
      window.open(`${webWordUrl}`, "_self");
    } else {
      window.open(
        `${webWordUrl}&login_hint=${user.email}`,
        "_self"
      );
    }
  }
};
export default OpenFile;
