import React from "react";

function RedLineDocumentIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8749 1H5.3749C4.90615 1 4.4374 1.2 4.0624 1.6C3.6874 1.9 3.4999 2.4 3.4999 3V19C3.4999 19.5 3.6874 20 4.0624 20.4C4.4374 20.8 4.90615 21 5.3749 21H16.6249C17.0937 21 17.5624 20.8 17.9374 20.4C18.3124 20 18.4999 19.5 18.4999 19V7M12.8749 1L18.4999 7L12.8749 1ZM12.8749 1V7H18.4999"
        fill="white"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M3.78987 1.30579C4.2323 0.843956 4.79647 0.599609 5.375 0.599609H12.875C12.9856 0.599609 13.0912 0.645373 13.1668 0.726033L18.7918 6.72603C18.8613 6.80017 18.9 6.89798 18.9 6.99961V18.9996C18.9 19.6009 18.6741 20.1988 18.2293 20.6732C17.7841 21.1481 17.2119 21.3996 16.625 21.3996H5.375C4.78807 21.3996 4.21593 21.1481 3.77068 20.6732C3.32594 20.1988 3.1 19.6009 3.1 18.9996V2.99961C3.1 2.33423 3.30553 1.70724 3.78987 1.30579ZM5.375 1.39961C5.02442 1.39961 4.65906 1.54812 4.35431 1.87319C4.34128 1.88709 4.32726 1.90005 4.31238 1.91196C4.06304 2.11143 3.9 2.47532 3.9 2.99961V18.9996C3.9 19.3983 4.04906 19.8004 4.35431 20.126C4.65906 20.4511 5.02442 20.5996 5.375 20.5996H16.625C16.9756 20.5996 17.3409 20.4511 17.6457 20.126C17.9509 19.8004 18.1 19.3983 18.1 18.9996V7.39961H12.875C12.6541 7.39961 12.475 7.22052 12.475 6.99961V1.39961H5.375ZM13.275 2.01112L17.5767 6.59961H13.275V2.01112Z"
        fill="#18468F"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M7.1 12.9996C7.1 12.7787 7.27908 12.5996 7.5 12.5996H14.5C14.7209 12.5996 14.9 12.7787 14.9 12.9996C14.9 13.2205 14.7209 13.3996 14.5 13.3996H7.5C7.27908 13.3996 7.1 13.2205 7.1 12.9996Z"
        fill="#ED4B4B"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M7.1 15.9996C7.1 15.7787 7.27908 15.5996 7.5 15.5996H14.5C14.7209 15.5996 14.9 15.7787 14.9 15.9996C14.9 16.2205 14.7209 16.3996 14.5 16.3996H7.5C7.27908 16.3996 7.1 16.2205 7.1 15.9996Z"
        fill="#ED4B4B"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M7.1 9.99961C7.1 9.7787 7.27908 9.59961 7.5 9.59961H9.5C9.72091 9.59961 9.9 9.7787 9.9 9.99961C9.9 10.2205 9.72091 10.3996 9.5 10.3996H7.5C7.27908 10.3996 7.1 10.2205 7.1 9.99961Z"
        fill="#ED4B4B"
      />
    </svg>
  );
}

export default RedLineDocumentIcon;
