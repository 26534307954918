import * as React from "react";
import {
  HoverCard,
  IHoverCard,
  IPlainCardProps,
  HoverCardType,
  ThemeProvider,
  Text,
  mergeStyles,
  Link,
} from "@fluentui/react";
import { HorizontalStack } from "../../Stack";
import { colorPallete } from "../../../assets/color";
import { UserModel } from "../../../models";
import { useTranslation } from "react-i18next";
import useModal from "../../../hooks/useModal";
import { EmailTemplateModal } from "../../modals/EmailTemplateModal";
import SendIcon from "../../../assets/Icons/svg/sendIcon";
import ReceivedIcon from "../../../assets/Icons/svg/receivedIcon";

interface HoverCardProps {
  notes: string | undefined;
  fileType: string | undefined;
  sharedBy: UserModel | undefined;
  sharedTo: UserModel[] | undefined;
  projectName: string | undefined;
  viewType?: string;
  receivedList?: UserModel[] | undefined;
  subject?: string | undefined;
}

const onCardHide = (): void => {};

const styles = {
  Others: {
    fontSize: "12px",
    borderRadius: "8px",
    minWidth: "1px",
    maxWidth: "fit-content",
    color: "rgb(6 42 108)",
    cursor: "pointer",
    fontWeight: 500,
  },
  sentToList: {
    marginTop: "1%",
    display: "inline-block",
    height: "100%",
    width: "90%",
  },
  firstEmailInCard: {
    textDecorationLine: "underline",
    fontWeight: 500,
    color: "#18468F",
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Poppins",
  },
  card: {
    width: "328px",
    height: "max-content",
    // padding: "10px",
    // bottom: "70%",
    borderRadius: "8px",
  },
  sharedBy: {
    width: "324px",
    height: "18px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  sharedToStack: {
    gap: "2%",
    justifyContent: "flex-start",
    width: "98%",
    paddingTop: "2px",
    alignItems: "center",
  },
  sharedTo: {
    alignSelf: "flex-end",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: colorPallete.black,
    width: "fit-content",
  },

  sharedToList: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: colorPallete.black,
    width: "80%",
  },
};
const notesStyles = mergeStyles({
  fontWeight: 400,
  fontSize: "12px",
  fontFamily: "Poppins",
  color: colorPallete.grey,
  lineHeight: "18px",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const EmailNotesHoverCard: React.FunctionComponent<HoverCardProps> = (
  props
) => {
  const hoverCard = React.useRef<IHoverCard>(null);
  const { t } = useTranslation();
  const { isModalOpen, showModal, hideModal } = useModal();
  const onRenderPlainCard = (): JSX.Element => {
    return (
      <div style={styles.card}>
        <HorizontalStack
          style={{
            gap: "2px",
            paddingBottom: "8px",
            width: "304px",
          }}
          title={props.subject}
        >
          <Text
            style={{
              width: "90%",
              fontSize: "12px",
              lineHeight: "18px",
              fontFamily: "Poppins",
              fontWeight: 600,
              color: "#323232",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
            }}
          >
            {t("Subject")}: {props.subject}
          </Text>
        </HorizontalStack>
        <HorizontalStack
          style={{
            width: "86%",
            height: "max-content",
            backgroundColor: "",
            gap: "8px",
            position: "relative",
          }}
        >
          <Text className={notesStyles} style={{ WebkitLineClamp: 2 }}>
            {props.notes!}
          </Text>
          <Link
            style={{
              fontSize: "12px",
              fontFamily: "Poppins",
              lineHeight: "18px",
              fontWeight: 400,
              height: "18px",
              zIndex: 5,
              position: "absolute",
              right: 0,
              bottom: 0,
              backgroundColor: "white",
              paddingLeft: "5px",
            }}
            onClick={showModal}
          >
            {t("read_more")}
          </Link>
        </HorizontalStack>
      </div>
    );
  };

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: onRenderPlainCard,
  };

  return (
    <>
      {props.fileType === "received" && (
        <ThemeProvider>
          <HoverCard
            type={HoverCardType.plain}
            plainCardProps={plainCardProps}
            componentRef={hoverCard}
            onCardHide={onCardHide}
            style={{
              borderRadius: "8px",
              width: "300px",
              padding: "12px",
              gap: "5px",
            }}
          >
            <div
              style={{
                backgroundColor:
                  props.viewType === "list" ? "" : "rgba(223, 230, 239, 0.55)",
              }}
            >
              <ReceivedIcon />
            </div>
          </HoverCard>
        </ThemeProvider>
      )}

      {props.fileType === "sent" && (
        <ThemeProvider>
          <HoverCard
            type={HoverCardType.plain}
            plainCardProps={plainCardProps}
            componentRef={hoverCard}
            onCardHide={onCardHide}
            style={{
              borderRadius: "8px",
              width: "300px",
              padding: "12px",
              gap: "5px",
            }}
          >
            <div
              style={{
                backgroundColor:
                  props.viewType === "list" ? "" : "rgba(223, 230, 239, 0.55)",
              }}
            >
              <SendIcon />
            </div>
          </HoverCard>
        </ThemeProvider>
      )}
      <EmailTemplateModal
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
        sharedBy={props.sharedBy}
        sharedTo={props.sharedTo}
        projectName={props.projectName}
        emailNotes={props.notes}
        fileType={props.fileType}
        receivedList={props.receivedList}
        subject={props.subject}
      />
    </>
  );
};
