import React from "react";

function WhiteListIcon() {
  return (
    <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.44444 1H17M5.44444 6.33333H17M5.44444 11.6667H17M1 1H1.00889M1 6.33333H1.00889M1 11.6667H1.00889"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  );
};

export default WhiteListIcon;
