import { FC, useEffect, useRef, useState } from "react";
import { HorizontalStack } from "../../../components/Stack";
import { FontSizes, Stack, Text, mergeStyles } from "@fluentui/react";
import { ViewAll } from "../../../components/ViewAll";
import { ProjectDocumentCard } from "../../../components/Cards/ProjectDocumentCard";
import { colorPallete } from "../../../assets/color";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";
import { Document } from "../../../models";
import { getDocumentversions } from "../../../modules/redux/Documents/document";
import { useParams } from "react-router-dom";
import { VersionsInDoc } from "../VersionsInDoc";
import SearchIcon from "../../../assets/Icons/svg/searchIcon";
import ListIcon from "../../../assets/Icons/svg/listIcon";
import BoxIcon from "../../../assets/Icons/svg/boxIcon";
import DocumentListView from "./docsListView";
import useModal from "../../../hooks/useModal";
import DeleteDocumentModal from "../../../components/modals/DeleteDocument";
import ReactPaginate from "react-paginate";
import "./index.css";
import { getSortedArrayMRU } from "../../../utils";
const textStyles = mergeStyles({
  fontSize: FontSizes.size24,
});
const styles = {
  documentContainer: {
    width: "97%",
    height: "100%",
    alignItems: "center",
    overflowX: "hidden",
    backgroundColor: "",
    marginTop: "1.8%",
    alignSelf: "center",
  },
  expandedDocumentContainer: {
    width: "95%",
    height: "auto",
    alignItems: "flex-start",
    marginRight: "1%",
    paddingBottom: "1%",
    marginLeft: "1.5%",
    marginTop: "1%",
    flexWrap: "wrap",
    display: "flex",
    backgroundColor: "",
  },
  loaderStyles: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  headerStyle: {
    fontSize: "18px",
    color: colorPallete.black,
    fontWeight: 500,
    paddingLeft: "0.6%",
    fontFamily: "Poppins",
  },
  errorStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "18rem",
    alignItems: "center",
  },
  HeaderStack: {
    width: "99%",
    alignItems: "center",
    backgroundColor: "",
    paddingTop: "2%",
    paddingRight: "1%",
    paddingLeft: "1%",
    justifyContent: "space-between",
  },
  ScrollStyles: {
    height: "100%",
    alignItems: "center",
    backgroundColor: "",
    justifyContent: "center",
    zIndex: 2,
    marginLeft: "5px",
  },
  outerStack: {
    display: "flex",
    width: "99%",
    alignItems: "center",
    backgroundColor: "",
  },
  searchContainer: {
    height: "36px",
    backgroundColor: "white",
    borderRadius: "4px",
    width: "350px",
    alignItems: "center",
    border: "1px solid #EAEAEA",
    paddingLeft: "12px",
  },
  listView: {
    border: `2px solid ${colorPallete.btLightBlue}`,
    borderRadius: "0.3rem",
    cursor: "pointer",
    padding: "10px 8px 10px 8px",
    alignItems: "center",
    height: "36px",
    width: "36.049px",
    justifyContent: "center",
  },
  boxView: {
    border: `2px solid ${colorPallete.btLightBlue}`,
    borderRadius: "0.3rem",
    cursor: "pointer",
    padding: "7px",
    alignItems: "center",
    height: "36px",
    width: "36.049px",
    justifyContent: "center",
  },
};

const SearchComp = (props: any) => {
  const { t } = useTranslation();
  return (
    <HorizontalStack style={{ width: "36%", justifyContent: "space-between" }}>
      <HorizontalStack style={styles.searchContainer}>
        <SearchIcon />
        <input
          style={{
            width: "300px",
            height: "18px",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
          }}
          onChange={(e) => props.setSearchText(e.target.value)}
          value={props.searchText}
          // onChange={(e)=>setSearchText(e.target.value)}
          placeholder={String(t("search_project_docs"))}
          // disabled
        />
      </HorizontalStack>
      <Stack
        style={{
          backgroundColor: props.listView ? colorPallete.btLightBlue : "white",
          ...styles.listView,
        }}
        title={String(t("list_view"))}
        onClick={() => {
          props.setListView(!props.listView);
        }}
      >
        <ListIcon />
      </Stack>
      <Stack
        style={{
          backgroundColor: !props.listView ? colorPallete.btLightBlue : "white",
          ...styles.boxView,
        }}
        onClick={() => {
          props.setListView(!props.listView);
        }}
        title={String(t("box"))}
      >
        <BoxIcon />
      </Stack>
    </HorizontalStack>
  );
};
const EmptyDataJSX = () => {
  const { t } = useTranslation();
  return (
    <Stack className={mergeStyles(styles.errorStyles)}>
      <Text className={textStyles}>{t("no_doument_found")}</Text>
    </Stack>
  );
};
const LoaderJSX = () => {
  return (
    <Stack className={mergeStyles(styles.loaderStyles)}>
      <Loader />
    </Stack>
  );
};
const Buttons = ({ documents }: any) => {
  const [left, setLeft] = useState<number>(0);
  let distance = left;
  const element = document.getElementById("myStack");
  useEffect(() => {
    if (left && element) {
      element.scrollLeft += left;
    }
  }, [left,element]);
  return (
    <>
      <Stack
        style={{
          ...styles.ScrollStyles,
          alignSelf: "flex-start",
          position: "absolute",
        }}
        onClick={() => {
          if (left > 0) {
            let a = 0;
            setLeft((a -= 1200));
          } else {
            setLeft((distance -= 400));
          }
        }}
      >
        {documents && documents.length !== 0 && (
          <img
            src={require("../../../assets/Icons/LeftScroll.png")}
            style={{ cursor: "pointer" }}
            alt=""
          />
        )}
      </Stack>
      <Stack
        style={{
          ...styles.ScrollStyles,
          alignSelf: "flex-end",
          position: "absolute",
          right: 8,
        }}
        onClick={() => {
          if (left < 0) {
            let a = 0;
            setLeft((a += 1200));
          } else {
            setLeft((distance += 400));
          }
        }}
      >
        {documents && documents.length !== 0 && (
          <img
            src={require("../../../assets/Icons/RightScroll.png")}
            style={{ cursor: "pointer" }}
            alt=""
          />
        )}
      </Stack>
    </>
  );
};

const DocBox = ({
  doc,
  showImportModal,
  setRootDocumentId,
  selectedId,
  handleFilePath,
  setSelectedId,
  setSelectedDocument,
  showModal,
  setDeleteDocName,
  setDeleteRootDocId,
}: {
  doc: Document;
  showImportModal?: () => void;
  setRootDocumentId?: React.Dispatch<React.SetStateAction<number>>;
  selectedId: number | undefined;
  handleFilePath: (arg0: number) => void;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedDocument: React.Dispatch<React.SetStateAction<string>>;
  showModal: () => void;
  setDeleteDocName: React.Dispatch<React.SetStateAction<string>>;
  setDeleteRootDocId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [hoverId, setHoverId] = useState<number | undefined>(0);
  return (
    <motion.div
      animate={{
        opacity: 1,
        scale:
          hoverId === doc.rootDocumentId || selectedId === doc.rootDocumentId
            ? 1.05
            : 1,
      }}
      initial={{
        opacity: 0,
      }}
      style={{
        marginTop: "0.5rem",
        border: selectedId === doc.rootDocumentId ? "4px solid #007CFF" : "",
        backgroundColor: "",
        marginLeft: "0.5rem",
        marginRight: "8px",
        marginBottom: "15px",
      }}
      onMouseEnter={() => setHoverId(doc.rootDocumentId)}
      onMouseLeave={() => {
        setHoverId(0);
      }}
      onClick={() => {
        handleFilePath(doc.rootDocumentId);
        setSelectedId(doc.rootDocumentId);
        setSelectedDocument(doc.rootDocumentName);
      }}
      key={doc.documentId}
    >
      <ProjectDocumentCard
        document={doc}
        showModal={showModal}
        deleteDocName={setDeleteDocName}
        deleteRootDocId={setDeleteRootDocId}
        showImportModal={showImportModal}
        selectedId={selectedId}
        setRootDocumentId={setRootDocumentId}
      />
    </motion.div>
  );
};

export const DocsInProject: FC<any> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [view, setView] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | undefined>(0);
  const [selectedDocument, setSelectedDocument] = useState("");

  const [rootDocumentId, setRootDocumentId] = useState<number>(0);
  const [versionError, setVersionError] = useState<boolean>(false);
  const [listView, setListView] = useState<boolean>(false);
  const { isModalOpen, showModal, hideModal } = useModal();
  const [deleteRootDocId, setDeleteRootDocId] = useState<number>(0);
  const [deleteDocName, setDeleteDocName] = useState<string>("");
  const [searchText, setSearchText] = useState("");
  const [arrowButtons, setArrowButtons] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [documents, setDocuments] = useState<any>([]);
  const [documentWithVersions, setDocumentWithVersions] = useState<any>([]);
  const pageSize = 15;
  const [currentPage, setCurrentPage] = useState(0);
  const documentVersions = useAppSelector(
    (state) => state.fetchDocumentWithVersions.documents
  );
  const projectState = useAppSelector(
    (state: { fetchProjectDetails: any }) => state.fetchProjectDetails
  );

  const bottomRef = useRef<HTMLDivElement>(null);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  useEffect(() => {
    if (scrollToBottom && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollToBottom(false); // Reset state after scrolling
    }
  }, [scrollToBottom]);

  useEffect(() => {
    if (documentVersions.length > 0) {
      setDocumentWithVersions(documentVersions);
    }
  }, [documentVersions]);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    let width = screenWidth <= 1356 ? screenWidth : 1356;
    if (documents && documents.length * 265 > width) {
      setArrowButtons(true);
    } else {
      setArrowButtons(false);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth, documents]);

  useEffect(() => {
    if (versionError) {
      handleFilePath(rootDocumentId);
    }
  }, [versionError]);

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  useEffect(() => {
    if (searchText?.length) {
      let searchedDocument = projectState.data.documents.filter((doc: any) =>
        doc?.rootDocumentName
          .split(".docx")[0]
          .split(".doc")[0]
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      setDocuments(searchedDocument);
    } else {
      if (projectState.data.documents.length > 0) {
        const sortedArray = getSortedArrayMRU(projectState.data.documents);
        setDocuments(sortedArray);
      } else setDocuments([]);
    }
  }, [searchText, projectState.data]);

  useEffect(() => {
    if (documents.length > 0) {
      setSelectedId(documents[0]?.rootDocumentId);
      setSelectedDocument(documents[0]?.rootDocumentName);
      handleFilePath(documents[0]?.rootDocumentId);
    }
  }, [documents]);

  function handleFilePath(rootDocumentId: number) {
    setRootDocumentId(rootDocumentId);
    if (rootDocumentId && rootDocumentId !== 0)
      dispatch(
        getDocumentversions({
          rootDocumentId: rootDocumentId,
        })
      );
  }

  return (
    <>
      <div style={{ backgroundColor: "" }}>
        <HorizontalStack style={styles.HeaderStack}>
          <HorizontalStack
            style={{
              height: "100%",
              width: "50%",
              alignItems: "center",
            }}
          >
            <Text style={styles.headerStyle}>{t("existing_proj_docs")}</Text>
            <ViewAll
              onClickFunction={() => {
                setView(!view);
              }}
              collapsed={view}
            />
          </HorizontalStack>
          <SearchComp
            searchText={searchText}
            setSearchText={setSearchText}
            listView={listView}
            setListView={setListView}
          />
        </HorizontalStack>
        <Stack
          style={{
            ...styles.outerStack,
            position: "relative",
            justifyContent: "flex-start",
            minHeight: "20rem",
            alignItems: "center",
          }}
        >
          {!view ? (
            <>
              {projectState.isLoading ? (
                <LoaderJSX />
              ) : documents.length === 0 ? (
                <EmptyDataJSX />
              ) : documents && !listView ? (
                <>
                  {arrowButtons && <Buttons documents={documents}/>}
                  <HorizontalStack
                    className={mergeStyles(styles.documentContainer)}
                    id="myStack"
                  >
                    {Array.isArray(documents) &&
                      documents.length !== 0 &&
                      documents.map((doc: Document, index: number) => {
                        return (
                          <DocBox
                            key={index}
                            doc={doc}
                            showImportModal={props?.showImportModal}
                            setRootDocumentId={props?.setRootDocumentId}
                            selectedId={selectedId}
                            handleFilePath={handleFilePath}
                            setDeleteDocName={setDeleteDocName}
                            setDeleteRootDocId={setDeleteRootDocId}
                            setSelectedDocument={setSelectedDocument}
                            setSelectedId={setSelectedId}
                            showModal={showModal}
                          />
                        );
                      })}
                  </HorizontalStack>
                </>
              ) : (
                <DocumentListView
                  data={documentWithVersions}
                  listView={view}
                  handleFilePath={handleFilePath}
                  setSelectedDocument={setSelectedDocument}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  showModal={showModal}
                  deleteDocName={setDeleteDocName}
                  deleteRootDocId={setDeleteRootDocId}
                />
              )}
            </>
          ) : (
            <>
              {projectState.isLoading ? (
                <LoaderJSX />
              ) : documents.length === 0 ? (
                <EmptyDataJSX />
              ) : documents && documents.length !== 0 && !listView ? (
                <div
                  className="container"
                  style={{ backgroundColor: "inherit" }}
                >
                  <div className="stack">
                    {documents
                      .slice(
                        currentPage * pageSize,
                        (currentPage + 1) * pageSize
                      )
                      .map((doc: Document, index: number) => {
                        return (
                          <DocBox
                            key={index}
                            doc={doc}
                            showImportModal={props?.showImportModal}
                            setRootDocumentId={props?.setRootDocumentId}
                            selectedId={selectedId}
                            handleFilePath={handleFilePath}
                            setDeleteDocName={setDeleteDocName}
                            setDeleteRootDocId={setDeleteRootDocId}
                            setSelectedDocument={setSelectedDocument}
                            setSelectedId={setSelectedId}
                            showModal={showModal}
                          />
                        );
                      })}
                  </div>
                  {documents.length > pageSize && (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={Math.ceil(documents.length / pageSize)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              ) : (
                <DocumentListView
                  data={documentWithVersions}
                  listView={view}
                  handleFilePath={handleFilePath}
                  setSelectedDocument={setSelectedDocument}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  showModal={showModal}
                  deleteDocName={setDeleteDocName}
                  deleteRootDocId={setDeleteRootDocId}
                />
              )}
            </>
          )}
        </Stack>
      </div>
      <div ref={bottomRef}>
        <VersionsInDoc
          projectData={props.projectData}
          documents={documents}
          selectedDocument={selectedDocument}
          selectedId={selectedId}
          versionError={versionError}
          setVersionError={setVersionError}
          refresh={props.refresh}
          setScrollToBottom={setScrollToBottom}
        />
      </div>
      <DeleteDocumentModal
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
        documentName={deleteDocName}
        rootDocumentId={deleteRootDocId}
        refresh={props.refresh}
        projectId={props.projectData.projectId}
      />
    </>
  );
};
