import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface WelcomeUser {
  value: string;
}

const initialState: WelcomeUser = {
  value: "newUser",
};

export const WelcomeUserSlice = createSlice({
  name: "welcomeUser",
  initialState,
  reducers: {
    welcomeUser: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    setWelcomeUser: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});
export const { welcomeUser, setWelcomeUser } = WelcomeUserSlice.actions;
const WelcomeUserReducer = WelcomeUserSlice.reducer;
export default WelcomeUserReducer;