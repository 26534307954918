import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface functionalityState {
    value :string,
}
const initialState: functionalityState = {
  value: "draft",
};



export const DocumentStackSlice = createSlice({
  name: "DocumentStack",
  initialState,
  reducers: {
    functionalityState :(state,action: PayloadAction<string>)=>{
        state.value =action.payload;
    }
  },
});
export const { functionalityState} = DocumentStackSlice.actions;
export const DocumentStackReducer = DocumentStackSlice.reducer;

