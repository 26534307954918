import { Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { HorizontalStack } from "../../components/Stack";
import ShareBlackIcon from "../../assets/Icons/svg/shareBlackIcon";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import { colorPallete } from "../../assets/color";
import SelectDocumentModal from "../../components/modals/SelectDocumentModal";
import { Document } from "../../models";
import NewShareModal from "../../components/modals/NewShareModal";
import useModal from "../../hooks/useModal";
import CrossIcon from "../../assets/Icons/svg/crossIcon";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getSortedArrayMRU } from "../../utils";
import { getDocumentversions } from "../../modules/redux/Documents/document";
import { setDocumentWithVersions } from "../../modules/redux/DocumentVersionSlice";

const DragAndDropComp = (props: any) => {
  const dispatch = useAppDispatch();
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [shareDetails, setShareDetails] = useState({});
  const [docsData, setDocsData] = useState<Document[]>([]);
  const documentVersions = useAppSelector(
    (state) => state.fetchDocumentWithVersions.documents
  );
  const projectState = useAppSelector(
    (state: { fetchProjectDetails: any }) => state.fetchProjectDetails
  );
  async function updateDocumentsAsync(documents: Document[]) {
    let updatedDocuments: Document[] = [];
    await Promise.all(
      documents.map(async (doc: Document) => {
        const res = await dispatch(
          getDocumentversions({
            rootDocumentId: doc.rootDocumentId,
            limit: 10,
            offset: 0,
          })
        );
        // Create a new object with the updated properties
        let updatedDocument: Document = {
          ...doc,
          versions: res.payload.data,
        };
        updatedDocuments.push(updatedDocument);
      })
    );
    dispatch(setDocumentWithVersions(updatedDocuments));
  }
  useEffect(()=>{
  },[projectState])

  useEffect(() => {
    if (
      projectState.data.documents &&
      projectState.data.documents.length !== 0
    ) {
      const sortedArray = getSortedArrayMRU(projectState.data.documents);
      setDocsData(sortedArray);
      updateDocumentsAsync(sortedArray);
    } else {
      setDocsData([]);
      dispatch(setDocumentWithVersions([]));
    }
  }, [projectState.isLoading]);

  useEffect(() => {
    var arr: Document[] = [];
    selectedDocuments.forEach((document) => {
      var filteredData = documentVersions.filter(
        (docVerions: any) =>
          docVerions?.rootDocumentName === document?.rootDocumentName
      );
      var len = filteredData[0]?.versions?.length;
      for (var i = 0; i < len; i += 1) {
        if (filteredData[0]?.versions[i]?.fileType === "received") {
          arr.push(filteredData[0]?.versions[i]);
          break;
        }
      }
    });

    arr.sort((doc1, doc2) => {
      const date1 = new Date(doc1?.lastUpdatedDate!);
      const date2 = new Date(doc2?.lastUpdatedDate!);
      return date2.getTime() - date1.getTime();
    });
    if (arr.length) setShareDetails(arr[0]?.shareDetails!);
  }, [selectedDocuments]);

  const Images = () => {
    let array = Array.from(selectedDocuments);
    return (
      <HorizontalStack
        style={{
          position: "relative",
          backgroundColor: "",
          width: array.length > 3 ? "40%" : "35%",
          marginRight: "5%",
          height: "100%",
          alignItems: "center",
        }}
      >
        {array.slice(0, 5).map((str, index) => {
          return (
            <Stack
              key={index}
              style={{ position: "absolute", left: index * 10 }}
            >
              <img src={require("../../assets/doc.png")} key={index} />
            </Stack>
          );
        })}
      </HorizontalStack>
    );
  };
  const { isModalOpen, showModal, hideModal } = useModal();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const showShareModal = () => setIsShareModalOpen(true);
  const hideShareModal = () => setIsShareModalOpen(false);

  const ShareComp = () => {
    return (
      <div>
        <HorizontalStack style={styles.shareContainer}>
          <HorizontalStack style={styles.imageWrapper}>
            <Images />
            <Text style={styles.circle}>{selectedDocuments.length}</Text>
          </HorizontalStack>
          <Text style={styles.shareButton} onClick={() => showModal()}>
            {t("continue")}
          </Text>
          <div onClick={() => setSelectedDocuments([])}>
            <CrossIcon />
          </div>
        </HorizontalStack>
      </div>
    );
  };

  return (
    <Stack
      style={{
        height: docsData.length !== 0 ? "70px" : "12rem",
        justifyContent: "space-between",
      }}
      className="ProjectDragAndDrop"
    >
      <Stack
        style={{
          width: docsData.length > 0 ? "49%" : "100%",
          height: "100%",
        }}
        className="DragWrapper"
      >
        <DragAndDrop
          direction={props?.created}
          folderName={props?.folderName}
          refresh={props?.refresh}
          data={docsData}
          projectId={props?.projectId}
          showModal={props?.showImportModal}
          hideModal={props?.hideImportModal}
          isModalOpen={props?.isImportModalOpen}
          rootDocumentId={props?.rootDocumentId}
          setRootDocumentId={props?.setRootDocumentId}
        />
      </Stack>
      {docsData.length > 0 && (
        <HorizontalStack
          style={{ ...styles.filesDragAndDrop }}
          onClick={() => {
            if (selectedDocuments.length === 0) {
              showModal();
            }
          }}
        >
          <HorizontalStack
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent:
                selectedDocuments.length > 0 ? "space-between" : "center",
              height: "100%",
            }}
          >
            <HorizontalStack
              style={{
                width: selectedDocuments.length > 0 ? "40%" : "100%",
                justifyContent:
                  selectedDocuments.length > 0 ? "space-evenly" : "center",
                backgroundColor: "",
                height: "100%",
                alignItems: "center",
              }}
            >
              <ShareBlackIcon />
              <HorizontalStack
                style={{
                  width: selectedDocuments.length > 0 ? "80%" : "10%",
                }}
              >
                <Text style={styles.drag}>{t("share")}</Text>
              </HorizontalStack>
            </HorizontalStack>
            {selectedDocuments.length > 0 && <ShareComp />}
          </HorizontalStack>
          <SelectDocumentModal
            isModalOpen={isModalOpen}
            showModal={showModal}
            hideModal={hideModal}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            showShareModal={showShareModal}
          />
          <NewShareModal
            isModalOpen={isShareModalOpen}
            showModal={showShareModal}
            hideModal={hideShareModal}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            shareDetails={shareDetails}
          />
        </HorizontalStack>
      )}
    </Stack>
  );
};

export default DragAndDropComp;
const styles = {
  filesDragAndDrop: {
    backgroundColor: "#E8ECF4",
    borderRadius: "4px",
    cursor: "pointer",
    height: "100%",
    padding: "10px",
    alignItems: "center",
    width: "49%",
    justifyContent: "space-between",
    border: `1px solid ${colorPallete.btBlue}`,
    boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.10)",
  },
  drag: {
    color: "#717070",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "18px",
    marginLeft: "12px",
    fontFamily: "Poppins",
  },
  browse: {
    color: "#007CFF",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "18px",
    textDecorationLine: "underline",
    marginLeft: "1%",
  },
  shareContainer: {
    // width: "55%",
    // justifyContent: "space-between",
    gap: "15px",
    backgroundColor: "",
    alignItems: "center",
    height: "100%",
  },
  imageWrapper: {
    height: "100%",
    alignItems: "center",
    width: "50%",
    backgroundColor: "",
    gap: "49px",
  },
  shareButton: {
    display: "flex",
    width: "106px",
    height: "90%",
    padding: "12px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#18468F",
    fontWeight: "500",
    fonSize: "16px",
    lineHeight: "18px",
    color: "#ffffff",
    fontFamily: "Poppins",
  },
  circle: {
    height: "24px",
    width: "48px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #18468F",
    marginLeft: "5%",
    backgroundColor: "#ffffff",
    color: "#18468F",
  },
};
