import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LoginHint {
  value: string;
}

const initialState: LoginHint = {
  value: "",
};

export const loginHintSlice = createSlice({
  name: "UploadDoc",
  initialState,
  reducers: {
    loginHint: (state,action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    setLoginHint: (state,action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});
export const { loginHint, setLoginHint } = loginHintSlice.actions;
const LoginHintReducer = loginHintSlice.reducer;
export default LoginHintReducer;