/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { IconButton, Stack, Text } from "@fluentui/react";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

function validateInput(input: any) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(input)) {
    return true;
  } else {
    // error(true);
    // message("Invalid Email");
    return false;
  }
}
type EmailTextBoxProps = {
  emails: Set<string>;
  setEmails: Dispatch<SetStateAction<Set<string>>>;
  error: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  setSuggestions: Dispatch<SetStateAction<UserEmail[]>>;
  userList: UserEmail[];
  isSuggestionFocused: boolean;
  setIsSuggestionFocused: Dispatch<SetStateAction<boolean>>;
  selectedEmail: string;
};

interface UserEmail {
  userName: string;
  email: string;
}

const Email = (props: {
  email: string;
  emails: Set<string>;
  setEmails: Dispatch<SetStateAction<Set<string>>>;
}) => {
  function handleRemove(email: string) {
    const updatedEmails = new Set(props?.emails);
    updatedEmails.delete(email);
    props?.setEmails(updatedEmails);
  }
  return (
    <Stack
      horizontal
      key={props?.email}
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      <Stack
        style={{
          paddingLeft: "2px",
          paddingRight: "4px",
          marginTop: "5px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleRemove(props?.email);
        }}
      >
        <IconButton
          style={{ width: "14px", height: "14px" }}
          iconProps={{
            iconName: "StatusCircleErrorX",
            style: {
              width: "16px",
              height: "16px",
              fontSize: "17px",
              color: "#18468F",
            },
          }}
        />
      </Stack>
      <Text
        style={{
          textDecorationLine: "underline",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "28px",
          textUnderlinePosition: "under",
        }}
      >
        {props?.email}
        {";"}
      </Text>
    </Stack>
  );
};

const EmailTextBox: FC<EmailTextBoxProps> = ({
  emails,
  setEmails,
  error,
  setError,
  message,
  setMessage,
  setSuggestions,
  userList,
  isSuggestionFocused,
  setIsSuggestionFocused,
  selectedEmail,
}) => {
  // export default function EmailTextBox({ emails, setEmails, error, setError, message, setMessage }) {
  const [input, setInput] = useState("");
  let typingTimeout: NodeJS.Timeout;

  const processEmails = (enteredEmails: string[]) => {
    const validEmails: string[] = [];
    const invalidEmails: string[] = [];

    enteredEmails.forEach((email) => {
      if (email.trim() !== "") {
        if (validateInput(email)) {
          setError(false);
          setEmails((prevEmails) => new Set([...prevEmails, email]));
          validEmails.push(email);
        } else {
          // Assuming setError and setMessage are part of your component state
          setError(true);
          setMessage("Enter Correct Email");
          invalidEmails.push(email);
        }
      }
    });

    const updatedInput = [...invalidEmails].join(" ");
    // Assuming setError is part of your component state
    invalidEmails.length > 0 && setError(true);
    // Assuming setInput is part of your component state
    setInput(updatedInput);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && input === "" && emails.size > 0) {
      const updatedEmails = new Set(emails);
      updatedEmails.delete(Array.from(updatedEmails).pop() || "");
      setEmails(updatedEmails);
      setInput("");
      setError(false);
    }

    if (e.key === "Enter" || e.key === " " || e.key === "Tab") {
      e.preventDefault();
      const enteredEmails = input.split(" ");
      processEmails(enteredEmails);
      setInput("");
      setError(false);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.ClipboardItem;
    const pastedData = clipboardData.getData("Text");
    const enteredEmails = pastedData.split(/[ \n\r]+/);
    processEmails(enteredEmails);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInput(value);
    setError(false);
    // Clear previous timeout
    clearTimeout(typingTimeout);

    // Set a new timeout to filter suggestions after 300ms (adjust as needed)
    typingTimeout = setTimeout(() => {
      const filteredSuggestions = userList.filter(
        (user) =>
          user.email.toLowerCase().includes(value.toLowerCase()) ||
          user.userName.toLowerCase().includes(value.toLowerCase())
      );

      setSuggestions(filteredSuggestions);
    }, 300);
  };

  const handleSuggestionClick = (selectedEmail: string) => {
    setInput(selectedEmail);
    processEmails([selectedEmail]);
    setSuggestions([]);
    setInput("");
    setError(false);
    setIsSuggestionFocused(true);
  };

  useEffect(() => {
    if (selectedEmail.length > 0) {
      handleSuggestionClick(selectedEmail);
    }
  }, [selectedEmail]);

  const handleInputBlur = () => {
    // Clear suggestions when the input loses focus
    if (!isSuggestionFocused) {
      setSuggestions([]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.25rem",
        width: "100%",
        height: "fit-content",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          borderBottom: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
          maxHeight: "60px",
          overflowY: "auto",
        }}
      >
        {Array.from(emails)?.map((email) => (
          <Email key={email} email={email} emails={emails} setEmails={setEmails}/>
        ))}
        <input
          style={{
            outline: "none",
            border: "none",
            flexGrow: 1,
            height: "32px",
            minWidth: "120px",
          }}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onPaste={handlePaste}
          onBlur={handleInputBlur}
        />
      </div>
      {error === true && (
        <Text
          style={{
            color: "#A82C0C",
            marginLeft: "1%",
            fontSize: "10px",
            fontFamily: "Poppins",
          }}
        >
          {message}
        </Text>
      )}
    </div>
  );
};

export default EmailTextBox;
