import { FC, useEffect, useState } from "react";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { HorizontalStack, VerticalStack } from "../../components/Stack";
import { useLocation } from "react-router-dom";
import {
  FontSizes,
  IButtonStyles,
  IIconProps,
  IconButton,
  PrimaryButton,
  Stack,
  Text,
  mergeStyles,
} from "@fluentui/react";
import { DocumentVersionInCard } from "../../components/Cards/DocumentVersionInCard";
import { Document } from "../../models";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  getDocumentversions,
  getSearchedVersions,
} from "../../modules/redux/Documents/document";
import Loader from "../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { setBoxView, setGridView } from "../../modules/redux/DocumentViewSlice";
import SearchBar from "../../components/SearchBox";
import { colorPallete } from "../../assets/color";
import FadeTransition from "../../components/FadeTransition";

const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
  },
};

const DocumentVersions: FC<any> = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSearchEnabled = useAppSelector(
    (state) => state.isSearchEnabled.value
  );
  const searchParam = useAppSelector((state) => state.isSearchEnabled.text);
  const { isLoading, data, error, metadata } = useAppSelector((state) =>
    isSearchEnabled ? state.fetchSearchedVersions : state.fetchDocumentVersion
  );
  const view = useAppSelector((state) => state.DocumentView.value);
  const [listView, setListView] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [allVersionData, setAllVersionData] = useState<Document[]>([]);
  const versionData = location.state && location.state.versionData;
  const documentName = location.state && location.state.documentName;

  const gridIcon: IIconProps = {
    iconName: "BulletedList2",
    style: { fontSize: "20px", color: colorPallete.darkGrey },
  };
  const boxIcon: IIconProps = {
    iconName: "GridViewMedium",
    style: { fontSize: "20px", color: colorPallete.darkGrey },
  };


  useEffect(() => {
    if (data && data.length > 0) {
      setAllVersionData(data);
    } else {
      setAllVersionData([]);
    }
  }, [data]);

  // useEffect(() => {
  //   if (searchParam !== "") {
  //     let obj = { rootDocumentId: versionData[0].rootDocumentId, searchParam  };
  //     dispatch(getSearchedVersions(obj));
  //   }
  // }, [searchParam]);

  useEffect(() => {
    dispatch(
      getDocumentversions({
        rootDocumentId: versionData[0].rootDocumentId,
        // limit: 8,
        // offset: 0,
      })
    );
  }, [isError]);

  const fetchMoreData = () => {
    let newOffSet = metadata.offset + metadata.limit;
    let payload = {
      rootDocumentId: versionData[0].rootDocumentId,
      limit: 6,
      offset: newOffSet,
    };
    if (metadata.offset < metadata.count) {
      dispatch(getDocumentversions(payload)).then((res: any) => {
        if (res.payload.data && res.payload.data.length > 0) {
          setAllVersionData([...allVersionData, ...res.payload.data]);
        }
      });
    }
  };

  const loaderJSX =
    isLoading && allVersionData.length === 0 ? (
      <Stack className={mergeStyles(styles.loaderStyles)}>
        <Loader />
      </Stack>
    ) : null;

  const errorJSX =
    error === "Network Error" ? (
      <Stack className={mergeStyles(styles.errorStyles)}>
        <Text className={mergeStyles(styles.textStyles)}>{t("loader")}</Text>
        <PrimaryButton
          styles={retryButtonStyles}
          onClick={() => setIsError(!isError)}
        >
          {t("retry")}
        </PrimaryButton>
      </Stack>
    ) : (
      allVersionData.length === 0 && (
        <Stack className={mergeStyles(styles.errorStyles)}>
          <Text className={mergeStyles(styles.textStyles)}>
            {t("no_versions_found")}
          </Text>
        </Stack>
      )
    );

  return (
    <FadeTransition>
    <VerticalStack>
      <PageTitle
        pageTitle={documentName.split(".docx")[0]}
        search={t("search_by_v")}
        project={false}
      />
      <HorizontalStack style={styles.top} horizontalAlign="space-between">
        <Text style={styles.header}>All Versions</Text>
        <HorizontalStack style={styles.allDocuments}>
          <Stack style={styles.searchBar}>
            <SearchBar placeholder={t("search_document")} />
          </Stack>

        </HorizontalStack>
      </HorizontalStack>
      {loaderJSX
        ? loaderJSX
        : !isLoading && errorJSX
        ? errorJSX
        : allVersionData.length > 0 &&
          (view ? (
            <>
              <InfiniteScroll
                dataLength={allVersionData.length}
                next={fetchMoreData}
                hasMore={allVersionData.length < metadata.count}
                loader={
                  <Stack
                    style={{
                      padding: "5%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loader />
                  </Stack>
                }
                style={{
                  minWidth: "100%",
                  height: "100%",
                  display: "flex",
                  backgroundColor: "",
                  flexWrap: "wrap",
                }}
                scrollThreshold={0.2}
              >
                {allVersionData.map(
                  (version: Document) =>
                    version.documentVersion && (
                      <Stack
                        className={mergeStyles(styles.versionCard)}
                        key={version.documentId}
                      >
                        <DocumentVersionInCard
                          key={version.documentId}
                          version={version}
                        />
                      </Stack>
                    )
                )}
              </InfiniteScroll>
            </>
          ) : (
            <VerticalStack>
              {/* {allVersionData.map((version: Document) => {
              return (
                version.fileType !== "pdf" && (
                  <DocumentVersionInGrid
                     key={version.documentId}
                    version={version}
                  />
                )
              );
            })} */}
            </VerticalStack>
          ))}
    </VerticalStack>
    </FadeTransition>
  );
};

export default DocumentVersions;

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "1.5%",
    backgroundColor: "",
    paddingBottom: "1%",
    width: "100%",
    minHeight: "20rem",
  },
  versionCard: {
    margin: "1%",
    paddingBottom: "1%",
  },
  loaderStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "19rem",
    alignItems: "center",
    marginTop: "1.5%",
  },
  errorStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "19rem",
    alignItems: "center",
  },
  textStyles: {
    fontSize: FontSizes.size18,
  },
  top: { marginTop: "2%", padding: 10 },
  allDocuments: {
    marginRight: "2rem",
    width: "40%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "",
  },
  header: {
    fontSize: "24px",
    paddingLeft: "1%",
    paddingBottom: "1%",
  },
  searchBar: {
    backgroundColor: "",
    alignItems: "center",
    width: "80%",
    height: "100%",
    justifyContent: "center",
  },
};
