import React from "react";

function CrossOutlinedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="9.5" fill="white" stroke="#18468F" />
      <rect
        x="12.7988"
        y="6.31445"
        width="1.25"
        height="9.16667"
        rx="0.625"
        transform="rotate(45 12.7988 6.31445)"
        fill="#18468F"
      />
      <rect
        x="13.6836"
        y="12.7988"
        width="1.25"
        height="9.16667"
        rx="0.625"
        transform="rotate(135 13.6836 12.7988)"
        fill="#18468F"
      />
    </svg>
  );
}
export default CrossOutlinedIcon;
