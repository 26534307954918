import { Text } from "@fluentui/react";
import ReceivedIcon from "../../../../assets/Icons/svg/receivedIcon";
import { HorizontalStack, VerticalStack } from "../../../Stack";
import { getFormattedDateTime } from "../../../../utils";
import { colorPallete } from "../../../../assets/color";
import "../index.css";
import SendIcon from "../../../../assets/Icons/svg/sendIcon";
import DocumentIcon from "../../../../assets/Icons/svg/documentIcon";
import { t } from "i18next";

const VersionsCard = (props: any) => {

  function getIcon(fileType: string) {
    if (fileType === "sent") {
      return <SendIcon />;
    } else if (fileType === "received") {
      return <ReceivedIcon />;
    } else {
      return <DocumentIcon />;
    }
  }
  return (
    <VerticalStack
      style={styles.versionCard}
      title={props?.version?.rootDocumentName}
    >
      <HorizontalStack style={{ gap: "7px" }}>
        {getIcon(props?.version.fileType)}
        <VerticalStack style={{}}>
          <HorizontalStack style={{ alignItems: "center", gap: "8px",width:"100%" }}>
            <Text
              style={{
                ...styles.mediumText,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "230px",
                backgroundColor: "",
              }}
            >
              {props?.version?.rootDocumentName?.split(".docx")}
            </Text>
            {props?.index === 0 ? (
              <Text className="version" >
                V{props?.version.documentVersion}&nbsp;{t("most_recent")}
              </Text>
            ) : (
              <Text className="version">V{props?.version.documentVersion}</Text>
            )}
          </HorizontalStack>
          <Text style={styles.dateText}>
            {getFormattedDateTime(String(props?.version.lastUpdatedDate))[0]}
            &nbsp;|&nbsp;
            {getFormattedDateTime(String(props?.version.lastUpdatedDate))[1]}
          </Text>
        </VerticalStack>
      </HorizontalStack>
    </VerticalStack>
  );
};

export default VersionsCard;

const styles = {
  mediumText: {
    color: "#101828",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    fontStyle: "normal",
  },
  versionCard: {
    padding: "8px 16px",
    borderRadius: "4px",
    border: `1px solid ${colorPallete.btLightBlue}`,
    backgroundColor: "white",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "10px",
    width: "98%",
    minHeight: "60px",
  },
  dateText: {
    color: "#717070",
    fontFamily: "Poppins",
    fontSize: "11px",
    fontWeight: "400",
    lineHeight: "16px",
    fontStyle: "normal",
  },
};
