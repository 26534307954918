import React from "react";

function ShareDisabledIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.616725 10.171C-0.266676 10.6752 -0.18459 12.0199 0.757443 12.4107L6.24156 14.6974V18.7353C6.24156 19.4428 6.81225 20.0135 7.51975 20.0135C7.89891 20.0135 8.25853 19.8454 8.50088 19.5522L10.9244 16.6479L15.7674 18.6649C16.5062 18.9737 17.3622 18.489 17.4834 17.6994L19.9851 1.43861C20.0593 0.965635 19.8522 0.488756 19.4574 0.219045C19.0626 -0.0506656 18.5466 -0.0741187 18.1284 0.164321L0.616725 10.171ZM2.65324 11.1677L16.002 3.54157L7.41812 13.1339L7.46503 13.173L2.65324 11.1677ZM15.7518 16.6284L9.23965 13.9117L17.6085 4.55787L15.7518 16.6284Z"
        fill="#717070"
      />
    </svg>
  );
}
export default ShareDisabledIcon;
