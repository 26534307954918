import React from "react";

export default function Draft() {
  return (
    <div>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.875 1H5.375C4.90625 1 4.4375 1.2 4.0625 1.6C3.6875 1.9 3.5 2.4 3.5 3V19C3.5 19.5 3.6875 20 4.0625 20.4C4.4375 20.8 4.90625 21 5.375 21H16.625C17.0938 21 17.5625 20.8 17.9375 20.4C18.3125 20 18.5 19.5 18.5 19V7M12.875 1L18.5 7L12.875 1ZM12.875 1V7H18.5"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.78948 1.30774C4.23191 0.84591 4.79608 0.601562 5.37461 0.601562H12.8746C12.9852 0.601562 13.0908 0.647326 13.1664 0.727986L18.7914 6.72799C18.8609 6.80212 18.8996 6.89994 18.8996 7.00156V19.0016C18.8996 19.6028 18.6737 20.2007 18.2289 20.6751C17.7837 21.1501 17.2115 21.4016 16.6246 21.4016H5.37461C4.78768 21.4016 4.21554 21.1501 3.77029 20.6751C3.32555 20.2007 3.09961 19.6028 3.09961 19.0016V3.00156C3.09961 2.33618 3.30514 1.7092 3.78948 1.30774ZM5.37461 1.40156C5.02404 1.40156 4.65867 1.55007 4.35392 1.87514C4.34089 1.88905 4.32687 1.902 4.31199 1.91391C4.06265 2.11338 3.89961 2.47727 3.89961 3.00156V19.0016C3.89961 19.4003 4.04867 19.8024 4.35392 20.128C4.65867 20.4531 5.02404 20.6016 5.37461 20.6016H16.6246C16.9752 20.6016 17.3405 20.4531 17.6453 20.128C17.9505 19.8024 18.0996 19.4003 18.0996 19.0016V7.40156H12.8746C12.6537 7.40156 12.4746 7.22248 12.4746 7.00156V1.40156H5.37461ZM13.2746 2.01307L17.5763 6.60156H13.2746V2.01307Z"
          fill="#18468F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.09961 13.0016C7.09961 12.7806 7.2787 12.6016 7.49961 12.6016H14.4996C14.7205 12.6016 14.8996 12.7806 14.8996 13.0016C14.8996 13.2225 14.7205 13.4016 14.4996 13.4016H7.49961C7.2787 13.4016 7.09961 13.2225 7.09961 13.0016Z"
          fill="#18468F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.09961 16.0016C7.09961 15.7806 7.2787 15.6016 7.49961 15.6016H14.4996C14.7205 15.6016 14.8996 15.7806 14.8996 16.0016C14.8996 16.2225 14.7205 16.4016 14.4996 16.4016H7.49961C7.2787 16.4016 7.09961 16.2225 7.09961 16.0016Z"
          fill="#18468F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.09961 10.0016C7.09961 9.78065 7.2787 9.60156 7.49961 9.60156H9.49961C9.72052 9.60156 9.89961 9.78065 9.89961 10.0016C9.89961 10.2225 9.72052 10.4016 9.49961 10.4016H7.49961C7.2787 10.4016 7.09961 10.2225 7.09961 10.0016Z"
          fill="#18468F"
        />
      </svg>
    </div>
  );
}
