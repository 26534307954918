import {
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Stack,
  Text,
  mergeStyles,
} from "@fluentui/react";
import DocumentIcon from "../../../assets/Icons/svg/documentIcon";
import EditIcon from "../../../assets/Icons/svg/editIcon";
import LockIcon from "../../../assets/Icons/svg/lockIcon";
import RedLineDocumentIcon from "../../../assets/Icons/svg/redLineDocument";
import TrashIcon from "../../../assets/Icons/svg/trashIcon";
import { HorizontalStack, VerticalStack } from "../../../components/Stack";
import { getFormattedDateTime, getFullName } from "../../../utils";
import { colorPallete } from "../../../assets/color";
import { Document, UserModel } from "../../../models";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import OpenFile from "../../../navigation/NoobOrPro";
import { FormModal } from "../../../components/modals/Modal";
import Loader from "../../../components/Loader";
import { TextForPageHeaders } from "../../../components/Text";
import {
  createNewVersion,
  getDocumentversions,
} from "../../../modules/redux/Documents/document";
import { useAppDispatch } from "../../../hooks/hooks";
import { EmailNotesHoverCard } from "../../../components/Cards/HoverCard";
import PdfIcon from "../../../assets/Icons/svg/pdfIcon";

const VersionsListView = (props: any) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const dispatch = useAppDispatch();
  const replyAll =
    props.version &&
    props?.version?.shareDetails &&
    props?.version?.shareDetails?.replyAll;
  const reply =
    props.version &&
    props?.version?.shareDetails &&
    props?.version?.shareDetails?.reply;
  const receivedList = replyAll?.filter(
    (user: any) => user?.userId !== reply?.userId
  );
  const uploadModal = mergeStyles({
    display: "flex",
    justifyContent: "flex-start",
    width: "98%",
    minHeight: "8rem",
    alignItems: "center",
    padding: "1%",
  });

  const success = mergeStyles({
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "30px",
  });

  const loading = mergeStyles({
    margin: "2%",
    paddingTop: "30px",
  });

  function showDocModal() {
    setModalOpen(true);
  }

  function hideDocModal() {
    setModalOpen(false);
  }

  function handleCreateNewVersion() {
    if (props.version?.documentId) {
      setModal(true);
      dispatch(createNewVersion(props.version?.documentId)).then((res: any) => {
        if (res.meta.requestStatus === "fulfilled") {
          setUpload(true);
          setMessage("create_new_version_success");
          setTimeout(() => {
            disable();
            openFile(res.payload.documentLink, res.payload.documentWebLink);
          }, 2000);
        } else if (res.meta.requestStatus === "rejected") {
          setMessage("something_went_wrong");
        }
        setTimeout(() => {
          dispatch(
            getDocumentversions({
              rootDocumentId: props.version.rootDocumentId,
              limit: 6,
              offset: 0,
            })
          );
          disable();
        }, 4000);
      });
    }
  }
  const openFile = (
    wordLocalUrl: string | undefined,
    wordWebUrl: string | undefined
  ) => {
    hideDocModal();
    OpenFile(wordLocalUrl, wordWebUrl);
  };

  const getFileTypeIcon = (doc: Document) => {
    if (doc.fileType === "draft" && doc.isRedlined === false) {
      return <DocumentIcon />;
    } else if (doc.fileType === "draft" && doc.isRedlined === true) {
      return <RedLineDocumentIcon />;
    } else if (doc.fileType === "sent" || "received") {
      return (
        <EmailNotesHoverCard
          notes={doc.shareDetails?.emailNotes ?? ""}
          fileType={doc.fileType}
          sharedBy={doc.shareDetails?.reply}
          sharedTo={doc.shareDetails?.replyAll}
          projectName={doc.projectName}
          viewType={"list"}
          subject={doc.shareDetails?.subject}
          receivedList={receivedList}
        />
      );
    }
  };

  function getFileTypeDate(fileType?: string) {
    if (fileType === "sent") {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#717070",
            textAlign: "end",
          }}
        >
          {t("Sent_on")}
        </Text>
      );
    } else if (fileType === "received") {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#717070",
            textAlign: "end",
          }}
        >
          {t("Received_on")}
        </Text>
      );
    } else {
      return (
        <Text
          style={{
            fontFamily: "Poppins",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#717070",
            textAlign: "end",
          }}
        >
          {t("Saved_on")}
        </Text>
      );
    }
  }
  function getIcons(fileType?: string) {
    if (fileType !== "sent") {
      return (
        <HorizontalStack
          style={{
            width: "82px",
            height: "22px",
            gap: "8px",
            justifyContent: "flex-end",
          }}
        >
          {props.version.fileType !== "sent" && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!props.delete) {
                  openFile(
                    props.version.documentLink,
                    props.version.documentWebLink
                  );
                }
              }}
            >
              <EditIcon />
            </div>
          )}

          {props.version.fileType !== "sent" && props.length !== 1 ? (
            <Stack
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!props.delete) {
                  props.setDeleteversion(props.version);
                  props.showModal();
                }
              }}
            >
              <TrashIcon />
            </Stack>
          ) : null}
        </HorizontalStack>
      );
    } else if (props.version.fileType === "sent") {
      return (
        <HorizontalStack
          style={{
            width: "82px",
            height: "22px",
            gap: "8px",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            showDocModal();
          }}
        >
          <LockIcon />
        </HorizontalStack>
      );
    }
  }

  function getVersionFromData(fileType?: any, version?: any) {
    if (fileType === "draft") {
      return (
        <div style={{ width: "50%" }}>
          <Text
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#323232",
            }}
          >
            {t("my_draft")}
          </Text>
        </div>
      );
    } else if (fileType === "sent") {
      return (
        <VerticalStack style={{ width: "50%" }}>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#717070",
            }}
          >
            {t("from")}
          </Text>
          <Text
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#323232",
            }}
          >
            {t("me")}
          </Text>
        </VerticalStack>
      );
    } else if (fileType === "received") {
      return (
        <VerticalStack style={{ width: "50%" }}>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#717070",
            }}
          >
            {t("from")}
          </Text>
          <Text
            style={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#323232",
            }}
          >
            {version?.shareDetails
              ? getFullName(version?.shareDetails.reply)
              : ""}
          </Text>
        </VerticalStack>
      );
    } else {
      return null;
    }
  }
  interface Props1 {
    recipients: UserModel[] | undefined;
    fileType: string | undefined;
  }
  const RenderPerson: FC<Props1> = (props: any) => {
    const { recipients } = props;
    const remainingCount = recipients && recipients.length - 1;

    if (props.fileType === "received" && recipients?.length === 0) {
      return <Text style={styles.Info}>Me</Text>;
    } else {
      return recipients && recipients.length ? (
        <div style={{ ...styles.recepientList, wordWrap: "break-word" }}>
          <HorizontalStack style={{ width: "100%", flexWrap: "wrap" }}>
            {props.fileType === "received" ? (
              <Text style={styles.Info}>Me</Text>
            ) : (
              <Text style={styles.Info}>{getFullName(recipients[0])}</Text>
            )}

            {props.fileType === "received" ? (
              <RecipientHoverCard recipients={recipients} />
            ) : (
              remainingCount > 0 && (
                <RecipientHoverCard recipients={recipients.slice(1)} />
              )
            )}
          </HorizontalStack>
        </div>
      ) : (
        <></>
      );
    }
  };

  const RecipientHoverCard: React.FC<{
    recipients: UserModel[] | undefined;
  }> = ({ recipients }) => {
    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () => (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#FFFFFF",
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Poppins",
            color: "#323232",
          }}
        >
          <ul style={{ listStyleType: "none" }}>
            {recipients?.map((recipient, index) => (
              <li key={getFullName(recipient)}>{getFullName(recipient)}</li>
            ))}
          </ul>
        </div>
      ),
      directionalHint: 1,
    };
    return (
      <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps}>
        <div style={styles.RecepientHoverCard}>
          ,&nbsp;{`+${recipients?.length} `}
          {recipients?.length === 1 ? t("other") : t("others")}
        </div>
      </HoverCard>
    );
  };

  function getVersionToData(version: Document) {
    if (version.fileType !== "draft") {
      return (
        <VerticalStack style={{ width: "50%" }}>
          <Text
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#717070",
            }}
          >
            {t("to")}
          </Text>
          {version.fileType === "sent" ? (
            <RenderPerson
              recipients={version.shareDetails?.replyAll}
              fileType={version.fileType}
            />
          ) : (
            <RenderPerson
              recipients={receivedList}
              fileType={version.fileType}
            />
          )}
        </VerticalStack>
      );
    } else {
      return <VerticalStack style={{ width: "50%" }}></VerticalStack>;
    }
  }

  const Modal = (
    <FormModal
      // title={t("open_document")}ll
      isModalOpen={modalOpen}
      showModal={showDocModal}
      hideModal={hideDocModal}
      cancelButton={true}
      width="500px"
      height="200px"
      TitleBackgroundColor="white"
    >
      {modal ? (
        <Stack className={uploadModal}>
          {!upload ? (
            <>
              <Stack className={loading}>
                <Loader />
              </Stack>
              <TextForPageHeaders>
                {t("creating_new_version")}
              </TextForPageHeaders>
            </>
          ) : (
            <VerticalStack className={success}>
              <img
                src={require("../../../assets/tick.png")}
                height="50px"
                width="50px"
                alt="Error"
              />
              <TextForPageHeaders>{t(message)}</TextForPageHeaders>
            </VerticalStack>
          )}
        </Stack>
      ) : (
        <Stack className={mergeStyles(styles.modalContainer)}>
          <Stack style={styles.CreateVersionText}>
            <Text className={mergeStyles(styles.docText)}>
              {t("open_document_version")}
            </Text>
          </Stack>
          <HorizontalStack
            style={{
              ...styles.CreateVersionButtonsStack,
              position: "absolute",
            }}
          >
            <Stack
              onClick={() => {
                handleCreateNewVersion();
              }}
              style={styles.button}
            >
              {t("create_new_version")}
            </Stack>
            <Stack
              onClick={() => {
                openFile(
                  props.version.documentLink,
                  props.version.documentWebLink
                );
              }}
              style={styles.button}
            >
              {t("open_read_only")}
            </Stack>
          </HorizontalStack>
        </Stack>
      )}
    </FormModal>
  );

  const disable = () => {
    setMessage("");
    setModal(false);
    setUpload(false);
    hideDocModal();
  };

  return (
    <>
      {Modal}
      <HorizontalStack className="listCardContainer">
        <HorizontalStack style={{ width: "53px" }} className="listPdfWrapper">
          {getFileTypeIcon(props.version)}
          {props.version?.pdfDetails && (
            <Stack
              id="pdf"
              className="listPdfIcon"
              onClick={(event) => {
                window.open(props.version.pdfDetails?.url);
                event.stopPropagation();
              }}
            >
              <PdfIcon />
            </Stack>
          )}
        </HorizontalStack>
        <HorizontalStack
          className="listWrapper"
          onClick={() => {
            if (props.version.fileType === "sent" && !props.delete) {
              showDocModal();
            } else if (!props.delete) {
              openFile(
                props.version.documentLink,
                props.version.documentWebLink
              );
            }
          }}
        >
          {getVersionFromData(props.version?.fileType, props.version)}
          {getVersionToData(props.version)}
        </HorizontalStack>
        {getIcons(props.version?.fileType)}
        <HorizontalStack className="listVersionDateWrapper">
          <Text className="listVersionNumber">
            V{props.version.documentVersion}
          </Text>
          <VerticalStack>
            {getFileTypeDate(props.version?.fileType)}
            <HorizontalStack style={styles.dateTime}>
              {getFormattedDateTime(props.version.lastUpdatedDate)[0]}
              &nbsp;|&nbsp;
              {getFormattedDateTime(props.version.lastUpdatedDate)[1]}
            </HorizontalStack>
          </VerticalStack>
        </HorizontalStack>
      </HorizontalStack>
    </>
  );
};

export default VersionsListView;
const styles = {
  fileName: {
    color: `${colorPallete.black1}`,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
  },
  dateTime: {
    color: "#323232",
    fontWeight: "400",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    justifyContent: "flex-end",
  },
  versions: {
    fontFamily: "Poppins",
    color: "#323232",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    width: "66px",
    height: "16px",
    paddingRight: "2px",
  },
  recepientList: {
    display: "inline-block",
    height: "100%",
    width: "90%",
  },
  Info: {
    fontSize: "14px",
    lineHeight: "20px",
    color: colorPallete.black,
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  RecepientHoverCard: {
    fontSize: "14px",
    borderRadius: "1rem",
    minWidth: "1px",
    maxWidth: "fit-content",
    color: "#18468F",
    cursor: "pointer",
    lineHeight: "20px",
  },
  CreateVersionButtonsStack: {
    width: "100%",
    justifyContent: "space-around",
    bottom: 0,
    height: "40%",
    alignItems: "center",
  },
  CreateVersionText: {
    width: "94%",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: "4%",
    marginTop: "1%",
  },
  docText: { fontSize: "18px", paddingBottom: "4%", fontFamily: "Poppins" },
  modalContainer: { backgroundColor: "" },
  button: {
    backgroundColor: colorPallete.btBlue,
    color: "white",
    borderRadius: "4px",
    border: "0px ",
    height: "40px",
    width: "180px",
    fontFamily: "Poppins",
    fontweight: "500",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};
