import React from "react";

function SendIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49991 9.45455L8.87855 13.2727L17.9174 18L20.4999 2L1.49991 9.45455Z"
        fill="white"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M20.7949 1.72932C20.9441 1.89217 20.9331 2.1452 20.7703 2.29447L8.7703 13.2945C8.60745 13.4438 8.35442 13.4327 8.20515 13.2699C8.05587 13.1071 8.06687 12.854 8.22972 12.7048L20.2297 1.70475C20.3926 1.55547 20.6456 1.56647 20.7949 1.72932Z"
        fill="#13540C"
      />
      <path d="M12.4999 15.2L8.49991 13V20L12.4999 15.2Z" fill="white" />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M8.29642 12.6553C8.41816 12.5833 8.56886 12.581 8.69277 12.6491L12.6928 14.8491C12.7974 14.9067 12.8709 15.008 12.8931 15.1254C12.9152 15.2428 12.8838 15.3639 12.8073 15.4557L8.80729 20.2557C8.69948 20.3851 8.52219 20.433 8.36384 20.3757C8.20549 20.3184 8.10001 20.168 8.10001 19.9996V12.9996C8.10001 12.8582 8.17469 12.7273 8.29642 12.6553ZM8.90001 13.6761V18.8948L11.8821 15.3163L8.90001 13.6761Z"
        fill="#13540C"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M20.751 1.6882C20.8629 1.77835 20.9178 1.92154 20.8949 2.06335L18.3124 18.0634C18.2919 18.1903 18.2116 18.2996 18.0967 18.3572C17.9817 18.4148 17.8461 18.4137 17.7321 18.3541L8.69482 13.6276L8.69327 13.6268L1.31618 9.80941C1.17675 9.73726 1.09241 9.59022 1.10055 9.43344C1.10868 9.27666 1.20777 9.13913 1.35391 9.08179L20.3539 1.62725C20.4876 1.57478 20.6392 1.59805 20.751 1.6882ZM2.46732 9.50433L9.06403 12.9179L17.611 17.3879L19.9934 2.62807L2.46732 9.50433Z"
        fill="#13540C"
      />
    </svg>
  );
}

export default SendIcon;
