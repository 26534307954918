import React, { FC } from "react";
import { ITextProps } from "@fluentui/react";
import { FontSizes } from "@fluentui/react/lib/Styling";

export const TextForTitles: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size42,
        lineHeight: "52px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForFavourite: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size24,
        lineHeight: "40px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForPageTitles: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size24,
        lineHeight: "36px",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForEmail: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size24,
        lineHeight: "32px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForPageHeaders: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size18,
        lineHeight: "28px",
        wordBreak: "break-word",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForTeamChannelName: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size18,
        lineHeight: "24px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForBody: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size16,
        lineHeight: "22px",
        fontFamily: "Poppins",
        // textOverflow: "ellipsis",
        // whiteSpace: "nowrap",
        // overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
};

export const TextForControls: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size14,
        lineHeight: "20px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextNotWrap: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size14,
        lineHeight: "20px",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForVersionName: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size16,
        lineHeight: "20px",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForMetadata: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size12,
        lineHeight: "16px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};

export const TextForMandatoryText: FC<ITextProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        fontSize: FontSizes.size10,
        lineHeight: "14px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontFamily: "Poppins",
      }}
    >
      {children}
    </div>
  );
};
