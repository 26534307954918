import { MessageBarType } from "@fluentui/react";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MessageBarState {
  message: string;
  type: MessageBarType;
  isVisible: boolean;
}

const initialState: MessageBarState = {
  message: "",
  type: MessageBarType.info,
  isVisible: false,
};
export const MessageBarSlice = createSlice({
  name: "MessageBarView",
  initialState,
  reducers: {
    disableMessageBar: (state) => {
      state.isVisible = false;
    },
    setMessage: (
      state,
      action: PayloadAction<{
        message: string;
        type: MessageBarType;
      }>
    ) => {
      state.isVisible = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
  },
});
export const { disableMessageBar, setMessage } =
  MessageBarSlice.actions;
const MessageBarReducer = MessageBarSlice.reducer;
export default MessageBarReducer;
