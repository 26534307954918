import React from "react";

function DocumentIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8749 1H5.37488C4.90613 1 4.43738 1.2 4.06238 1.6C3.68738 1.9 3.49988 2.4 3.49988 3V19C3.49988 19.5 3.68738 20 4.06238 20.4C4.43738 20.8 4.90613 21 5.37488 21H16.6249C17.0936 21 17.5624 20.8 17.9374 20.4C18.3124 20 18.4999 19.5 18.4999 19V7M12.8749 1L18.4999 7L12.8749 1ZM12.8749 1V7H18.4999"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78984 1.30579C4.23228 0.843956 4.79644 0.599609 5.37498 0.599609H12.875C12.9855 0.599609 13.0912 0.645373 13.1668 0.726033L18.7918 6.72603C18.8613 6.80017 18.9 6.89798 18.9 6.99961V18.9996C18.9 19.6009 18.674 20.1988 18.2293 20.6732C17.784 21.1481 17.2119 21.3996 16.625 21.3996H5.37498C4.78805 21.3996 4.21591 21.1481 3.77066 20.6732C3.32591 20.1988 3.09998 19.6009 3.09998 18.9996V2.99961C3.09998 2.33423 3.30551 1.70724 3.78984 1.30579ZM5.37498 1.39961C5.0244 1.39961 4.65904 1.54812 4.35429 1.87319C4.34125 1.88709 4.32724 1.90005 4.31235 1.91196C4.06302 2.11143 3.89998 2.47532 3.89998 2.99961V18.9996C3.89998 19.3983 4.04904 19.8004 4.35429 20.126C4.65904 20.4511 5.0244 20.5996 5.37498 20.5996H16.625C16.9755 20.5996 17.3409 20.4511 17.6457 20.126C17.9509 19.8004 18.1 19.3983 18.1 18.9996V7.39961H12.875C12.6541 7.39961 12.475 7.22052 12.475 6.99961V1.39961H5.37498ZM13.275 2.01112L17.5767 6.59961H13.275V2.01112Z"
        fill="#18468F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09998 12.9996C7.09998 12.7787 7.27906 12.5996 7.49998 12.5996H14.5C14.7209 12.5996 14.9 12.7787 14.9 12.9996C14.9 13.2205 14.7209 13.3996 14.5 13.3996H7.49998C7.27906 13.3996 7.09998 13.2205 7.09998 12.9996Z"
        fill="#18468F"
      />
      <path
      fillRule="evenodd"
      clipRule="evenodd"
        d="M7.09998 15.9996C7.09998 15.7787 7.27906 15.5996 7.49998 15.5996H14.5C14.7209 15.5996 14.9 15.7787 14.9 15.9996C14.9 16.2205 14.7209 16.3996 14.5 16.3996H7.49998C7.27906 16.3996 7.09998 16.2205 7.09998 15.9996Z"
        fill="#18468F"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
        d="M7.09998 9.99961C7.09998 9.7787 7.27906 9.59961 7.49998 9.59961H9.49998C9.72089 9.59961 9.89998 9.7787 9.89998 9.99961C9.89998 10.2205 9.72089 10.3996 9.49998 10.3996H7.49998C7.27906 10.3996 7.09998 10.2205 7.09998 9.99961Z"
        fill="#18468F"
      />
    </svg>
  );
}

export default DocumentIcon;
