import React from "react";

export default function EyesOnly() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
      >
        <path
          d="M3.28571 6.40166V4.00227C3.285 3.25848 3.54759 2.54095 4.0225 1.98897C4.49741 1.43699 5.15076 1.08994 5.85572 1.01519C6.56067 0.940442 7.26694 1.14333 7.83741 1.58446C8.40789 2.0256 9 2.80255 9 3.70232V6.40166M9 6.40166H2.14286C1.51167 6.40166 1 6.93878 1 7.60136V11.8003C1 12.4629 1.51167 13 2.14286 13H10.1429C10.774 13 11.2857 12.4629 11.2857 11.8003V7.60136C11.2857 6.93878 10.774 6.40166 10.1429 6.40166H9Z"
          stroke="#717070"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
