import { FontIcon, Label, Stack, Text, TextField } from "@fluentui/react";
import { FC, FormEvent, ReactElement, useCallback, useState } from "react";
import HelpIcon from "../../assets/Icons/svg/helpIcon";
import axios from "axios";
import { HorizontalStack, VerticalStack } from "../Stack";
import BlueCrossIcon from "../../assets/Icons/svg/blueCrossicon";
import { useTranslation } from "react-i18next";
import { colorPallete } from "../../assets/color";
import Loader from "../Loader";
type HelpBoxProps = {
  getHelp: any;
  setGetHelp: any;
};
const HelpBox: FC<HelpBoxProps> = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState(false);

  const onChangeNameTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue?.length === 0) {
        setNameError(true);
      } else {
        setNameError(false);
      }
      setName(newValue || "");
    },
    []
  );

  const onChangeEmailTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue?.length === 0) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
      setEmail(newValue || "");
    },
    []
  );

  const onChangeMessageTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue?.length === 0) {
        setMessageError(true);
      } else {
        setMessageError(false);
      }
      setMessage(newValue || "");
    },
    []
  );

  const clearHelpForm = () => {
    setEmail("");
    setName("");
    setMessage("");
    setEmailError(false);
    setNameError(false);
    setMessageError(false);
    setLoading(false);
    setSuccess(false);
  };
  const submitHelp = () => {
    if (name.length === 0) {
      setNameError(true);
    }
    if (email.length === 0) {
      setEmailError(true);
    }
    if (message.length === 0) {
      setMessageError(true);
    }
    if (name.length !== 0 && email.length !== 0 && message.length !== 0) {
      const data = {
        request: {
          requester: { name: name, email: email },
          subject: "anonymous User Help!",
          comment: { body: message },
        },
      };
      setLoading(true);
      axios
        .post("https://bargainingtable.zendesk.com//api/v2/requests", data, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => {
          setSuccess(true);
          setTimeout(() => {
            clearHelpForm();
            props?.setGetHelp(false);
          }, 3000);
        })
        .catch((err) => {
          console.log("zendesk error", err);
          setError(true);
        });
    }
  };

  return (
    <Stack>
      {loading ? (
        <Stack className="helpBox">
          <div
            style={{
              display: "flex",
              width: "320px",
              justifyContent: "flex-end",
              alignContent: "flex-end",
              position: "fixed",
            }}
          >
            <div
              onClick={() => {
                props?.setGetHelp(!props?.getHelp);
              }}
              style={{ cursor: "pointer" }}
            >
              <BlueCrossIcon />
            </div>
          </div>
          <VerticalStack
            style={{
              height: "155px",
              width: "291px",
              marginTop: "45%",
              gap: "24px",
              alignSelf: "center",
              justifySelf: "center",
            }}
          >
            {!success ? (
              <Stack style={styles.loadingWrapper}>
                <Loader />
                <Text style={styles.helpBodyText}>{t("loading")}</Text>
              </Stack>
            ) : (
              <>
                <FontIcon
                  iconName="SkypeCircleCheck"
                  style={{
                    fontSize: "48px",
                    backgroundColor: "white",
                    color: colorPallete.btGreen,
                    alignSelf: "center",
                  }}
                />
                <Text style={styles.helpBodyText}>{t("thank_you")}</Text>
                <Text
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    alignSelf: "center",
                    color: "#717070",
                    textAlign: "center",
                  }}
                >
                  {t("help_msg")}
                </Text>
              </>
            )}
          </VerticalStack>
        </Stack>
      ) : (
        <Stack className="helpBox">
          <VerticalStack className="helpHeadContainer">
            <HorizontalStack className="helpHeading">
              <Text className="helpHeadText">{t("need_help")}</Text>
              <Stack
                onClick={() => {
                  props?.setGetHelp(!props?.getHelp);
                }}
                style={{ cursor: "pointer" }}
              >
                <BlueCrossIcon />
              </Stack>
            </HorizontalStack>
            <Text className="helpDescription">{t("help_desc")}</Text>
          </VerticalStack>
          <VerticalStack className="fieldWrapper">
            <Label className="helpLabel" required>
              {t("full_name")}
            </Label>
            <TextField
              styles={{
                root: {
                  height: "40px",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "5px",
                  width: "100%",
                  padding: "4px",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins",
                  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
                },
                field: {
                  backgroundColor: "#F8F8F8",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins",
                  selectors: {
                    "::placeholder": {
                      color: "#717070",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      fontFamily: "Poppins",
                    },
                  },
                },
              }}
              borderless
              placeholder={String(t("enter_full_name"))}
              value={name}
              onChange={onChangeNameTextField}
            />
            {nameError && (
              <Text style={styles.errorMessage}>{t("name_required")}</Text>
            )}
          </VerticalStack>
          <VerticalStack className="fieldWrapper">
            <Label className="helpLabel" required>
              {t("email")}
            </Label>
            <TextField
              styles={{
                root: {
                  height: "40px",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "5px",
                  width: "100%",
                  padding: "4px",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins",
                  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
                },
                field: {
                  backgroundColor: "#F8F8F8",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins",
                  selectors: {
                    "::placeholder": {
                      color: "#717070",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      fontFamily: "Poppins",
                    },
                  },
                },
              }}
              borderless
              placeholder={String(t("enter_email"))}
              value={email}
              onChange={onChangeEmailTextField}
            />
            {emailError && (
              <Text style={styles.errorMessage}>{t("email_required")}</Text>
            )}
          </VerticalStack>
          <VerticalStack className="fieldWrapper">
            <Label className="helpLabel" required>
              {t("message")}
            </Label>
            <TextField
              styles={{
                root: {
                  minHeight: "90px",
                  backgroundColor: "#F8F8F8",
                  borderRadius: "5px",
                  width: "100%",
                  padding: "4px",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins",
                  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
                },
                field: {
                  backgroundColor: "#F8F8F8",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins",
                  height: "90px",
                  selectors: {
                    "::placeholder": {
                      color: "#717070",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      fontFamily: "Poppins",
                    },
                  },
                },
              }}
              borderless
              multiline={true}
              resizable={false}
              placeholder={String(t("problem"))}
              value={message}
              onChange={onChangeMessageTextField}
            />
            {messageError && (
              <Text style={styles.errorMessage}>{t("message_required")}</Text>
            )}
          </VerticalStack>
          <Text className="getHelpButton" onClick={submitHelp}>
            {t("get_help")}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

const Help: FC = (): ReactElement => {
  const [getHelp, setGetHelp] = useState<boolean>(false);
  return (
    <Stack
      styles={{
        root: {
          position: "fixed",
          bottom: 0,
          right: "3px",
          zIndex: 6,
          alignItems: "flex-end",
        },
      }}
    >
      {getHelp ? <HelpBox getHelp={getHelp} setGetHelp={setGetHelp} /> : null}
      <Stack
        onClick={() => {
          setGetHelp(!getHelp);
        }}
        style={{ cursor: "pointer" }}
      >
        <HelpIcon />
      </Stack>
    </Stack>
  );
};

export default Help;

const styles = {
  errorMessage: {
    color: colorPallete.btRed,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    fontFamily: "Poppins",
    marginTop: "6px",
  },
  loadingWrapper: {
    gap: "15px",
    marginTop: "20px",
  },
  helpBodyText: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "20px",
    alignSelf: "center",
    color: "#323232",
  },
};
