import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IsSeachEnabledState {
  value: boolean;
  text: string;
}

const initialState: IsSeachEnabledState = {
  value: false,
  text: "",
};

export const isSeachEnabledSlice = createSlice({
  name: "isSearchEnabled",
  initialState,
  reducers: {
    enableSearch: (state) => {
      state.value = true;
    },
    disableSearch: (state) => {
      state.value = false;
    },
    setText: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
  },
});
export const { enableSearch, disableSearch, setText } =
  isSeachEnabledSlice.actions;
const isSearchEnabledReducer = isSeachEnabledSlice.reducer;
export default isSearchEnabledReducer;
