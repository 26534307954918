import React from "react";

function ZoomWhiteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle opacity="0.2" cx="15" cy="15" r="15" fill="white" />
      <circle
        opacity="0.2"
        cx="14.9992"
        cy="14.9972"
        r="13.3019"
        fill="white"
      />
      <circle cx="15.0003" cy="14.9983" r="11.6038" fill="white" />
      <path
        d="M9 15.3636C9 15.3636 11.1818 11 15 11C18.8182 11 21 15.3636 21 15.3636C21 15.3636 18.8182 19.7273 15 19.7273C11.1818 19.7273 9 15.3636 9 15.3636Z"
        stroke="#18468F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 17C15.9037 17 16.6364 16.2674 16.6364 15.3636C16.6364 14.4599 15.9037 13.7273 15 13.7273C14.0963 13.7273 13.3636 14.4599 13.3636 15.3636C13.3636 16.2674 14.0963 17 15 17Z"
        stroke="#18468F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ZoomWhiteIcon;
