import React from "react";

export default function Sent() {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 8.45455L7.87864 12.2727L16.9175 17L19.5 1L0.5 8.45455Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7945 0.731275C19.9438 0.894123 19.9327 1.14715 19.7699 1.29643L7.7699 12.2964C7.60705 12.4457 7.35403 12.4347 7.20475 12.2719C7.05547 12.109 7.06647 11.856 7.22932 11.7067L19.2293 0.706703C19.3922 0.557426 19.6452 0.568427 19.7945 0.731275Z"
          fill="#13540C"
        />
        <path d="M11.5 14.2L7.5 12V19L11.5 14.2Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.29603 11.6572C7.41776 11.5853 7.56846 11.5829 7.69238 11.6511L11.6924 13.8511C11.797 13.9086 11.8705 14.01 11.8927 14.1274C11.9148 14.2447 11.8834 14.3659 11.8069 14.4576L7.8069 19.2576C7.69909 19.387 7.52179 19.435 7.36344 19.3777C7.20509 19.3203 7.09961 19.17 7.09961 19.0016V12.0016C7.09961 11.8601 7.17429 11.7292 7.29603 11.6572ZM7.89961 12.6781V17.8967L10.8817 14.3182L7.89961 12.6781Z"
          fill="#13540C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7507 0.690153C19.8625 0.780304 19.9174 0.923497 19.8945 1.06531L17.312 17.0653C17.2915 17.1922 17.2112 17.3016 17.0963 17.3592C16.9813 17.4168 16.8457 17.4156 16.7317 17.356L7.69442 12.6296L7.69288 12.6287L0.31578 8.81137C0.176351 8.73922 0.0920179 8.59218 0.100149 8.4354C0.10828 8.27862 0.207371 8.14109 0.353516 8.08375L19.3535 0.629202C19.4872 0.576737 19.6388 0.600002 19.7507 0.690153ZM1.46692 8.50628L8.06363 11.9198L16.6106 16.3899L18.993 1.63002L1.46692 8.50628Z"
          fill="#13540C"
        />
      </svg>
    </div>
  );
}
