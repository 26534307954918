import { Stack, Text } from "@fluentui/react";
import ProjectIcon from "../../../../assets/Icons/svg/projectIcon";
import { HorizontalStack, VerticalStack } from "../../../Stack";
import ConnectIcon from "../../../../assets/Icons/svg/connectIcon";
import LibraryIcon from "../../../../assets/Icons/svg/libraryIcon";
import DocumentIcon from "../../../../assets/Icons/svg/documentIcon";
import VersionsCard from "./versionCard";
import { colorPallete } from "../../../../assets/color";
import { styles } from "..";
import { BtFileObject } from "../../../../models";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { getDocumentversions } from "../../../../modules/redux/Documents/document";
import "../index.css";
import Loader from "../../../Loader";
import { t } from "i18next";
interface CustomProps {
  documentName: string;
  folderName: string;
  addDocument: boolean;
  setAddDocument: any;
  multipleFiles: BtFileObject[];
  rootDocumentId: number;
  handleCLick: any;
  setDocumentName: Dispatch<SetStateAction<string>>;
}
const AddNewVersion: React.FC<CustomProps> = ({
  documentName,
  folderName,
  addDocument,
  setAddDocument,
  multipleFiles,
  rootDocumentId,
  handleCLick,
  setDocumentName
}) => {
  const dispatch = useAppDispatch();
  const [allVersionData, setAllVersionData] = useState<Document[]>([]);
  const { isLoading, data, error, metadata } = useAppSelector(
    (state) => state.fetchDocumentVersion
  );
  useEffect(() => {
    dispatch(
      getDocumentversions({
        rootDocumentId: rootDocumentId,
        limit: 21,
        offset: 0,
      })
    );
  }, [rootDocumentId]);

  useEffect(() => {
    if (data && data.length > 0) {
      setAllVersionData(data);
    } else {
      setAllVersionData([]);
    }
  }, [data]);

  return (
    <>
      <VerticalStack style={styles.versionHistory}>
        <HorizontalStack style={styles.projectDetails}>
          <ProjectIcon />
          <Text style={styles.projectText}>{folderName}</Text>
        </HorizontalStack>
        <HorizontalStack
          style={{
            marginLeft: "13px",
            alignItems: "flex-end",
            paddingBottom: "6px",
            position: "relative",
            marginBottom: "6px"
          }}
        >
          <ConnectIcon />
          <HorizontalStack
            style={{
              alignItems: "center",
              gap: "6px",
              position: "absolute",
              bottom: -2.5,
              left: 38,
            }}
          >
            <LibraryIcon height={14} width={14} />
            <Text style={{ ...styles.mediumText, lineHeight: "20px" }}>
              {t("document_family")}:
            </Text>
            <Text
              style={{
                ...styles.mediumText,
                lineHeight: "20px",
                fontWeight: "500",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "50%",
                whiteSpace: "nowrap",
              }}
              title={documentName}
            >
              {documentName}
            </Text>
          </HorizontalStack>
        </HorizontalStack>
        {allVersionData && allVersionData.length > 0 ? (
            <VerticalStack style={{ ...styles.versionContainer }}>
              <HorizontalStack
                style={{
                  ...styles.versionCard,
                  border: "1px solid #007CFF",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  position: "relative",
                  zIndex: 3,
                }}
                title={multipleFiles[0]?.fileName}
              >
                <DocumentIcon />
                <Text
                  style={{
                    ...styles.mediumText,
                    lineHeight: "20px",
                    fontWeight: "500",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "50%",
                    whiteSpace: "nowrap",
                  }}
                >
                  {multipleFiles[0]?.fileName}
                </Text>
                <Text className="newVersion">(NEW Version)</Text>
                <Stack className="dot" style={{ left: "5%" }}>
                  <svg
                    width="2"
                    height="22"
                    viewBox="0 0 2 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.999999 0L1 16"
                      stroke="#18468F"
                      stroke-dasharray="4 4"
                    />
                  </svg>
                </Stack>
              </HorizontalStack>
              {allVersionData.slice(0, 3).map((doc: Document, index) => {
                return <VersionsCard version={doc} index={index} />;
              })}
            </VerticalStack>
        ) : (
          <Stack className="loader">
            <Loader />
          </Stack>
        )}
      </VerticalStack>
      <Stack className="horizontalLine"></Stack>
      <HorizontalStack
        style={{
          ...styles.bottom,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Text
          style={{
            ...styles.addButton,
            ...styles.mediumText,
            fontWeight: "500",
            background: "#E8ECF4",
            textAlign: "center",
          }}
          onClick={() => {
            setAddDocument(!addDocument);
            setDocumentName("");
          }}
        >
          {t("go_back")}
        </Text>
        <Text
          style={{
            ...styles.cancelButton,
            ...styles.mediumText,
            background:
              folderName && multipleFiles.length > 0
                ? `${colorPallete.btBlue}`
                : "#E8ECF4",
            color:
              folderName && multipleFiles.length > 0
                ? `${colorPallete.white}`
                : "#323232",
            textAlign: "center",
          }}
          onClick={() => {
            handleCLick("confirmed");
            setAddDocument(!addDocument);
          }}
        >
          {t("confirm")}
        </Text>
      </HorizontalStack>
    </>
  );
};

export default AddNewVersion;
