import { FC, ReactElement } from "react";
import LanguageDropdown from "../LanguageDropdown";
import Signin from "../Login";
import { Logo } from "../Logo/index.";
import { HorizontalStack } from "../Stack";
import useIsSignedIn from "../../hooks/useIsSignedIn";
import { Text } from "@fluentui/react";
import { colorPallete } from "../../assets/color";
import { useTranslation } from "react-i18next";

const headerStackStyles = {
  root: {
    backgroundColor: "",
    padding: "0.8%",
  },
};

const Header: FC = (): ReactElement => {
  const isSignedIn = useIsSignedIn();
  const { t } = useTranslation();
  return (
    <HorizontalStack styles={headerStackStyles} horizontalAlign="space-between">
      <Logo />
      <HorizontalStack style={styles.container}>
        {!isSignedIn[0] ? (
          <Text
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_REDIRECT_URI}privacy.html`;
            }}
            style={styles.textStyles}
          >
            {t("privacy_policy")}
          </Text>
        ) : null}
        <LanguageDropdown />
        <Signin />
      </HorizontalStack>
    </HorizontalStack>
  );
};

export default Header;

const styles = {
  container: {
    alignItems: "center",
  },
  textStyles: {
    fontFamily: "Poppins",
    cursor: "Pointer",
    alignSelf: "center",
    color: colorPallete.btBlue,
    marginRight: "10px",
    fontSize: "14px",
    border: `1px solid ${colorPallete.btBlue}`,
    borderRadius: "8px",
    padding: "2px 6px",
    fontWeight: "600",
    lineHeight: "20px",
  },
};
