import { FC } from "react";
import { HorizontalStack } from "../../Stack";
import DocumentTrackCard from "./DocumentTrackCard";
import PdfTrackCard from "./PdfTrackCard";

const DocumentWithPDF: FC<any> = (props) => {
  return (
    <HorizontalStack>
      <DocumentTrackCard
        modal="share"
        setSelectedDocuments={props.setSelectedDocuments}
        documentWithTrack={props.doc}
        setDocumentWithTrack={props.setDocumentWithTrackChanges}
        length={props.size}
      />
      {props.doc.includePdf && (
        <PdfTrackCard
          modal="share"
          setSelectedDocuments={props.setSelectedDocuments}
          documentWithTrack={props.doc}
          setDocumentWithTrack={props.setDocumentWithTrackChanges}
          length={props.size}
        />
      )}
    </HorizontalStack>
  );
};

export default DocumentWithPDF;
