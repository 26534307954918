import { FC, useEffect, useState } from "react";
import { Document } from "../../../models";
import "./SelectedDocument.css";
import { Text } from "@fluentui/react";
import { HorizontalStack } from "../../Stack";
import { t } from "i18next";
import { DocumentWithTrack } from "../NewShareModal";
import DocumentWithPDF from "./DocumentWithPDFCard";

type SelectedDocumentsProps = {
  documentCount: number;
  setSelectedDocuments: React.Dispatch<React.SetStateAction<Document[]>>;
  documentWithTracks: DocumentWithTrack[];
  setDocumentWithTrackChanges: React.Dispatch<
    React.SetStateAction<DocumentWithTrack[]>
  >;
};

const moreSelectedStyles = {
  height: "32px",
  padding: "6px 8px",
  alignItems: "center",
  gap: "5px",
  borderRadius: "6px",
  border: "1px solid var(--LIGHT-BLUE, #E8ECF4)",
  backgroundColor: "#F3F5F9",
  color: "#18468F",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 500,
  cursor: "pointer",
};

const MoreSelectedDocuments: FC<any> = (props) => {
  return (
    <HorizontalStack
      style={moreSelectedStyles}
      onClick={() => {
        props.setIsExpanded(!props.isExpanded);
      }}
    >
      {props?.isExpanded && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
        >
          <path
            d="M5 9L1 5L5 1"
            stroke="#18468F"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {props?.isExpanded ? (
        t("less")
      ) : (
        <Text
          style={{
            color: "#18468F",
            fontFamily: "Poppins",
            fontSize: "9px",
            fontWeight: 500,
          }}
        >
          {"+"}
          {`${props?.extraCount} `}&nbsp;{t("more")}
        </Text>
      )}
      {!props?.isExpanded && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
        >
          <path
            d="M1 1L5 5L1 9"
            stroke="#18468F"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </HorizontalStack>
  );
};

const SelectedDocuments: FC<SelectedDocumentsProps> = (props) => {
  const {
    documentCount,
    setSelectedDocuments,
    documentWithTracks,
    setDocumentWithTrackChanges,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [count, setCount] = useState(0);

  function getCardCount() {
    if (documentWithTracks.length === 1) {
      return 1;
    } else if (documentWithTracks.length === 2) {
      if (documentCount === 0 || documentCount === 1) {
        return 2;
      } else if (documentCount === 2) {
        return 1;
      } else {
        return 2;
      }
    } else if (documentWithTracks.length >= 3) {
      if (documentCount === 0) {
        return 3;
      } else if (documentCount === 1) {
        return 2;
      } else {
        return 2;
      }
    } else {
      return 0;
    }
  }

  useEffect(() => {
    if (documentWithTracks.length > 0) {
      let a = documentWithTracks.length;
      documentWithTracks.forEach((item) => {
        if (item.includePdf) {
          a++;
        }
      });
      setCount(a);
    }
  }, [documentWithTracks]);

  return (
    <HorizontalStack
      style={{
        gap: "8px",
        flexWrap: "wrap",
        paddingTop: "4px",
        alignItems: "center",
      }}
    >
      {documentWithTracks.map((doc, index) => {
        if (!isExpanded && index < getCardCount()) {
          return (
            <DocumentWithPDF
              key={doc.document.rootDocumentId}
              doc={doc}
              size={documentWithTracks.length}
              setSelectedDocuments={setSelectedDocuments}
              setDocumentWithTrackChanges={setDocumentWithTrackChanges}
            />
          );
        } else if (isExpanded) {
          return (
            <DocumentWithPDF
              key={doc.document.rootDocumentId}
              doc={doc}
              size={documentWithTracks.length}
              setSelectedDocuments={setSelectedDocuments}
              setDocumentWithTrackChanges={setDocumentWithTrackChanges}
            />
          );
        }
      })}
      {count >= 3 && documentWithTracks.length - getCardCount()!==0 && (
        <MoreSelectedDocuments
          extraCount={documentWithTracks.length - getCardCount()}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
      )}
    </HorizontalStack>
  );
};

export default SelectedDocuments;
