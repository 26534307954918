import React from "react";

export default function Forward() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M7.46154 1.46094L13 6.9994L7.46154 12.5379M12.2308 6.9994H1"
          stroke="#18468F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
