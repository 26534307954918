import { FC } from "react";
import { PageTitle } from "../../components/PageTitle";
import { useTranslation } from "react-i18next";
import { AllPrecedentDoc } from "../../modules/PrecedentDocuments/AllPrecedentDoc";
import { List, Stack } from "@fluentui/react";
import { setBoxView, setGridView } from "../../modules/redux/DocumentViewSlice";
import { HorizontalStack, VerticalStack } from "../../components/Stack";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import SearchBar from "../../components/SearchBox";
import { colorPallete } from "../../assets/color";
import BoxIcon from "../../assets/Icons/svg/boxIcon";
import ListIcon from "../../assets/Icons/svg/listIcon";
import FadeTransition from "../../components/FadeTransition";
import Help from "../../components/Help";
import DragAndDrop from "../../components/DragAndDrop/DragAndDrop";
import WhiteBoxIcon from "../../assets/Icons/svg/whiteBoxIcon";
import WhiteListIcon from "../../assets/Icons/svg/whiteListIcon";
import useModal from "../../hooks/useModal";

const MyPrecedentFiles: FC<any> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const view = useAppSelector((state) => state.DocumentView.value);
  const { isModalOpen, showModal, hideModal } = useModal();
  return (
    <FadeTransition>
      <Stack style={{height:"100%",overflowX:"hidden",width:"104%",overflowY:"auto"}}>
        <VerticalStack
          style={{
            width: "100%",
            justifyContent: "space-between",
            height: "200px",
            alignItems: "flex-end",
            marginBottom:"3%"
     }}
        >
          <HorizontalStack style={{width: "100%",
            justifyContent: "space-between",
            alignItems: "flex-end",
            paddingLeft:"5px",}}>
          <Stack style={{ width: "90%", height: "100%", justifyContent:"center" }}>
            <PageTitle
              pageTitle={t("my_precedent_files")}
              search={t("search_precedent")}
              project={false}
            />
          </Stack>
          <Stack
            style={{
              backgroundColor: "",
              alignItems: "flex-end",
              width: "32%",
              justifyContent: "center",
              marginRight: "2%",
              height: "90%",
            }}
          >
            <HorizontalStack style={styles.allDocuments}>
              <Stack style={styles.searchBar}>
                <SearchBar placeholder={t("search_precedent")} />
              </Stack>
              <div
                style={{
                  backgroundColor: !view ? colorPallete.btBlue : "white",
                  borderRadius: "0.3rem",
                  cursor: "pointer",
                  padding: "10px 8px 10px 8px",
                  alignItems: "center",
                  display: "flex",
                }}
                title={String(t("list_view"))}
                onClick={() => {
                  dispatch(setGridView());
                }}
              >
                 {
                  !view ? <WhiteListIcon/> : <ListIcon/>
                }
              </div>
              <div
                style={{
                  backgroundColor: view ? colorPallete.btBlue : "white",
                  borderRadius: "0.3rem",
                  cursor: "pointer",
                  padding: "7px",
                  alignItems: "center",
                  display: "flex",
                }}
                onClick={() => {
                  dispatch(setBoxView());
                }}
                title={String(t("box"))}
              >
                {
                  view ? <WhiteBoxIcon/> : <BoxIcon/>
                }
              </div>
            </HorizontalStack>
          </Stack>
          </HorizontalStack>
         
          <Stack
         style={{
          width: "98%",
          height: "30%",
          justifyContent:"flex-start",
          alignSelf:"flex-start"
       
        }}
      >
        <DragAndDrop
         textStackDirection={"row"}
         isModalOpen={isModalOpen}
         showModal = {showModal}
         hideModal={hideModal}
         />
         </Stack>
        </VerticalStack>
       
        <AllPrecedentDoc />
        <Help />
      </Stack>
    </FadeTransition>
  );
};

export default MyPrecedentFiles;

const styles = {
  top: {
    marginTop: "2%",
    backgroundColor: "",
    padding: 10,
    justifyContent: "space-between",
    paddingRight: 20,
  },
  allDocuments: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "",
    gap:"6px"
  },
  header: {
    fontSize: "24px",
    paddingLeft: "1%",
    paddingBottom: "1%",
  },
  searchBar: {
    backgroundColor: "",
    alignItems: "center",
    width: "80%",
    height: "100%",
    justifyContent: "center",
  },
  button: {},
};
