import React from "react";

export default function Received() {
  return (
    <div>
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 10.8618V19.1567C19.5 20.1705 18.645 21 17.6 21H2.4C1.355 21 0.5 20.1705 0.5 19.1567V9.01843L3.54 7.17511V2.56682C3.54 1.64516 4.3 1 5.155 1H15.225C16.175 1 16.84 1.73733 16.84 2.56682V7.54378H16.935L19.5 9.01843C19.5 9.01843 19.5 9.84793 19.5 10.8618Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.86488 6.79155C3.9809 6.97955 3.92254 7.226 3.73455 7.34202L0.899609 9.09148V19.135C0.899609 19.9359 1.56646 20.6016 2.39016 20.6016H17.5145C17.9094 20.6016 18.3074 20.415 18.6121 20.1058C18.9171 19.7964 19.0996 19.3934 19.0996 19.0016V9.00435L16.7454 7.62686C16.5547 7.5153 16.4906 7.27029 16.6021 7.07961C16.7137 6.88894 16.9587 6.82481 17.1494 6.93637L19.7016 8.42971C19.8242 8.50146 19.8996 8.63287 19.8996 8.77495V19.0016C19.8996 19.6365 19.6095 20.2335 19.1819 20.6674C18.754 21.1016 18.1594 21.4016 17.5145 21.4016H2.39016C1.13425 21.4016 0.0996094 20.3873 0.0996094 19.135V8.86828C0.0996094 8.72956 0.171489 8.60074 0.289546 8.52788L3.31442 6.66122C3.50242 6.5452 3.74887 6.60356 3.86488 6.79155Z"
          fill="#A82C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.68365 1.18955C4.03082 0.840556 4.50401 0.601562 5.08954 0.601562H14.6291C15.8808 0.601562 16.8996 1.62878 16.8996 2.8819V11.6255C16.8996 11.8464 16.7205 12.0255 16.4996 12.0255C16.2787 12.0255 16.0996 11.8464 16.0996 11.6255V2.8819C16.0996 2.06665 15.435 1.40156 14.6291 1.40156H5.08954C4.73982 1.40156 4.4648 1.53864 4.25082 1.75375C4.04587 1.95978 3.89961 2.32799 3.89961 2.59985V11.6255C3.89961 11.8464 3.72052 12.0255 3.49961 12.0255C3.2787 12.0255 3.09961 11.8464 3.09961 11.6255V2.59985C3.09961 2.11958 3.32745 1.54762 3.68365 1.18955Z"
          fill="#A82C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.159235 9.7916C0.275224 9.60359 0.521668 9.5452 0.709682 9.66119L7.70302 13.9755C9.0876 14.8103 10.9117 14.8103 12.2963 13.9755C12.2969 13.9752 12.2974 13.9748 12.2979 13.9745L19.2896 9.66119C19.4777 9.5452 19.7241 9.60359 19.8401 9.7916C19.9561 9.97962 19.8977 10.2261 19.7097 10.342L12.7113 14.6594C11.0718 15.649 8.92755 15.649 7.28799 14.6594L7.28466 14.6574L0.289648 10.342C0.101633 10.2261 0.0432456 9.97962 0.159235 9.7916Z"
          fill="#A82C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.09961 10.0016C6.09961 9.78065 6.2787 9.60156 6.49961 9.60156H13.4996C13.7205 9.60156 13.8996 9.78065 13.8996 10.0016C13.8996 10.2225 13.7205 10.4016 13.4996 10.4016H6.49961C6.2787 10.4016 6.09961 10.2225 6.09961 10.0016Z"
          fill="#A82C0C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.09961 7.00156C6.09961 6.78065 6.2787 6.60156 6.49961 6.60156H13.4996C13.7205 6.60156 13.8996 6.78065 13.8996 7.00156C13.8996 7.22248 13.7205 7.40156 13.4996 7.40156H6.49961C6.2787 7.40156 6.09961 7.22248 6.09961 7.00156Z"
          fill="#A82C0C"
        />
      </svg>
    </div>
  );
}
