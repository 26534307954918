import React from "react";

export default function Unrestricted() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
      >
        <path
          d="M7 1H2.2C1.88174 1 1.57652 1.12643 1.35147 1.35147C1.12643 1.57652 1 1.88174 1 2.2V11.8C1 12.1183 1.12643 12.4235 1.35147 12.6485C1.57652 12.8736 1.88174 13 2.2 13H9.4C9.71826 13 10.0235 12.8736 10.2485 12.6485C10.4736 12.4235 10.6 12.1183 10.6 11.8V4.6M7 1L10.6 4.6M7 1V4.6H10.6M8.2 7.6H3.4M8.2 10H3.4M4.6 5.2H3.4"
          stroke="#717070"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
