import { FC, useState, useCallback, ChangeEvent, useEffect } from "react";
import { ISearchBoxStyles, SearchBox } from "@fluentui/react/lib/SearchBox";
import { useDispatch } from "react-redux";
import "./index.css";
import {
  enableSearch,
  disableSearch,
  setText,
} from "../../modules/redux/SearchEnabledSlice";
import { useTranslation } from "react-i18next";

import { IIconProps } from "@fluentui/react";
import { HorizontalStack } from "../Stack";

interface SearchProps {
  placeholder: string;
}
const searchBar: ISearchBoxStyles = {
  root: {
    width:"280px",
    height: "36px",
    alignItems: "center",
    paddingLeft:"3%",
    borderRadius: "4px",
    gap:"3px",
    border:"1px solid #E4E8EC",
    selectors:{
      "::after":{
       border:"none",
      },
  }
    },
    icon: {
      fontSize: "16px", 
    },
  
  iconContainer: {
    alignItems: "center",
    display: "flex",
    width:"22px",

  },
  field:{
   selectors: {
        "::placeholder": {
          color: "#717070",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px",
          fontFamily: "Poppins",
     
       
        },

    }
  },


}





const SearchBar: FC<SearchProps> = (props) => {
  const SearchIcon: IIconProps = { 
  imageProps:{
    src : require("../../assets/Icons/Search.png"),

 } 
  };
  
  const [input, setInput] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (!value) {
        dispatch(disableSearch());
      } else {
        dispatch(enableSearch());
      }
      dispatch(setText(value));
    }, 300), 
    []
  );

  const onChangeSearchField = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
    newValue?: string
  ) => {
  
    setInput(newValue!);
    debouncedSearch(newValue);
  };

  useEffect(() => {
    dispatch(disableSearch());
  }, []);

  function search() {
    dispatch(enableSearch());
  }

  return (

    <SearchBox
      styles={searchBar}
      placeholder={props.placeholder}
      onSearch={search}
      onChange={onChangeSearchField}
      iconProps={SearchIcon}
    
    />
  );
};
export default SearchBar;

function debounce(func: Function, delay: number) {
  let timeout: NodeJS.Timeout;
  return function (this: any, ...args: any) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}
