import { FC, ReactElement, useEffect, useState } from "react";
import { Login, MgtTemplateProps } from "@microsoft/mgt-react";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useNavigate, useSearchParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { ApiClient } from "../../network/ApiClient";
import { ApiEndpoints } from "../../network/ApiEndpoints";
import OpenFile from "../../navigation/NoobOrPro";
import "./index.css";
import { setWelcomeUser } from "../../modules/redux/WelcomeUserSlice";
import { Stack, Text } from "@fluentui/react";
import { getFullName } from "../../utils";
import { UserModel } from "../../models";
import MyProfileModal from "../modals/MyProfileModal";

mixpanel.init(`${process.env.REACT_APP_MIXPANEL_PROJECT_KEY}`, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

const Signin: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [redirectLinks, setRedirectLinks] = useState<any>();
  const dispatch = useAppDispatch();
  const user = JSON.parse(localStorage.getItem("user")!);
  const [isMyProfileModalOpen, setIsMyProfileModalOpen] = useState(false);
  const showMyProfileModal = () => setIsMyProfileModalOpen(true);
  const hideMyProfileModal = () => setIsMyProfileModalOpen(false);
  const welcomeUser = useAppSelector((state) => state.WelcomeUser.value);

  function trackUser(data: UserModel) {
    mixpanel.track("User", {
      name: getFullName(data),
      email: data?.email,
    });
  }

  function getParamValue(param: any) {
    let link = localStorage.getItem("LINK")!;
    let url = new URL(link);
    const urlParams = new URLSearchParams(url.search);
    return urlParams.get(param);
  }
  useEffect(() => {
    const updateFTL = async () => {
      let response = await ApiClient.put<any, any>(
        ApiEndpoints.UpdateFirstTimeLogin(),
        {}
      );
      localStorage.setItem("user", JSON.stringify(response?.data));
      !window.location.href.includes("/home/") &&
        OpenFile(redirectLinks?.data?.desktopUrl, redirectLinks?.data?.webUrl);
    };
    if (welcomeUser === "redirect" || welcomeUser === "close") {
      updateFTL();
    }
  }, [welcomeUser, redirectLinks]);

  async function handleLogin(redirect_id: any, ftl: any, projectId: any) {
    const links = await ApiClient.get<any>(
      ApiEndpoints.getDocumentLinks(),
      {
        encryptedRedirectId: redirect_id,
      },
      {}
    );
    setRedirectLinks(links);
    if (links?.data?.desktopUrl) {
      if ((ftl === "True" || ftl === true) && projectId) {
        navigate("home");
        localStorage.removeItem("LINK");
        dispatch(setWelcomeUser("start"));
      } else if (redirect_id) {
        navigate("home");
        localStorage.removeItem("LINK");
        OpenFile(links?.data?.desktopUrl, links?.data?.webUrl);
      }
    } else {
      localStorage.removeItem("LINK");
      navigate("pagenotfound");
    }
  }

  const loginCompleted = async (res: any) => {
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
      const response = await ApiClient.get<any>(ApiEndpoints.userDetails());
      trackUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("loginHint", "");
      const user = response.data;
      let ftl = false;
      ftl = user?.dataDictionary?.isFirstLogin;
      if (params.has("redirect_id")) {
        handleLogin(params.get("redirect_id"), ftl, params.get("projectId"));
      } else if (localStorage.getItem("LINK")) {
        console.log("Params cleared :: Checking local storage");
        if (getParamValue("redirect_id")) {
          handleLogin(
            getParamValue("redirect_id"),
            ftl,
            getParamValue("projectId")
          );
        } else if (!window.location.href.includes("home")) {
          let link =
            new URL(localStorage.getItem("LINK")!).origin +
            new URL(localStorage.getItem("LINK")!).pathname +
            "?ftl=" +
            ftl;
          localStorage.removeItem("LINK");
          link && window.location.replace(link);
        } else {
          localStorage.removeItem("LINK");
        }
      } else if (window.location.pathname === "/") {
        navigate("home");
      } else {
        localStorage.removeItem("LINK");
      }
    }
  };

  async function login() {
    await Providers.globalProvider.login!().catch((error: any) => {
      console.log("Error in login :: ", error);
    });
  }

  useEffect(() => {
    console.log(
      "LOGIN : Initial State",
      Providers.globalProvider.getAllAccounts!(),
      Providers.globalProvider.state,
      ProviderState.SignedIn
    );
    if (
      (params.has("redirect_id") || params.has("ftl")) &&
      params.has("loginHint")
    ) {
      if (Providers.globalProvider.getAllAccounts!().length > 0) {
        try {
          console.log(
            "LOGIN 1st Option: ",
            params.get("loginHint")?.toLowerCase(),
            Providers.globalProvider?.getActiveAccount!()?.mail?.toLowerCase()
          );
          Providers.globalProvider?.getActiveAccount!().mail?.toLowerCase() !==
            params.get("loginHint")?.toLowerCase() && login();
        } catch (ex) {
          console.log("Exception ", ex);
          login();
        }
      } else if (Providers.globalProvider.getAllAccounts!().length === 0) {
        console.log("LOGIN 2nd Option: No accounts found");
        login();
      }
    }
  }, []);

  const loginInitiated = () => {
    localStorage.setItem("loginHint", "");
  };
  const logoutCompleted = async () => {
    localStorage.setItem("loginHint", "");
    sessionStorage.removeItem("Access-Token");
  };

  const loginFailed = (err: any) => {
    console.log("Login Failed", err);
  };

  const CustomTemplate = (props: MgtTemplateProps) => {
    const capitalizeFirstLetter = (str: any) => {
      return str?.charAt(0).toUpperCase() + str?.slice(1);
    };

    const firstNameCapitalized =
      user?.firstName && capitalizeFirstLetter(user?.firstName);
    const emailCapitalized = user?.email && capitalizeFirstLetter(user?.email);

    return (
      <Stack
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <div
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "40px",
            backgroundColor: "#F8F8F8",
            padding: "23px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: "24px",
              lineHeight: "16px",
              fontWeight: 400,
              fontFamily: "Poppins",
              color: "#323232",
            }}
          >
            {firstNameCapitalized?.charAt(0)}
          </Text>
        </div>

        <Stack
          style={{
            fontSize: "15px",
            lineHeight: "16px",
            fontWeight: 500,
            fontFamily: "Poppins",
            color: "#18468F",
            cursor: "pointer",
          }}
          onClick={() => {
            showMyProfileModal();
          }}
        >
          {firstNameCapitalized}
        </Stack>

        <Text
          style={{
            fontSize: "14px",
            lineHeight: "16px",
            fontWeight: 400,
            fontFamily: "Poppins",
            color: "#717070",
            display: "block",
            whiteSpace: "nowrap",
          }}
        >
          {emailCapitalized}
        </Text>
      </Stack>
    );
  };

  return (
    <>
      <Login
        id="loginButton"
        loginCompleted={loginCompleted}
        logoutCompleted={logoutCompleted}
        loginInitiated={loginInitiated}
        loginFailed={loginFailed}
        className="login"
      >
        <CustomTemplate template="flyout-person-details" />
      </Login>

      <MyProfileModal
        isModalOpen={isMyProfileModalOpen}
        hideModal={hideMyProfileModal}
        showModal={showMyProfileModal}
      />
    </>
  );
};

export default Signin;
