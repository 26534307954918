import { Stack, Text } from "@fluentui/react";
import { FC, useEffect,useState } from "react";
import { DocumentStack } from "../../modules/PrecedentDocuments/DocumentStack";
import { FunctionalityButtonStack } from "../../modules/Functionality/FunctionalityButtonStack";
import { ProjectStack } from "../../modules/Projects/ProjectStack";
import FadeTransition from "../../components/FadeTransition";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import useModal from "../../hooks/useModal";
import { FormModal } from "../../components/modals/Modal";
import { colorPallete } from "../../assets/color";
import { VerticalStack } from "../../components/Stack";
import { deleteProjectReset } from "../../modules/redux/ProjectDeleteSlice";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import Help from "../../components/Help";
import WelcomeModal from "../../components/modals/WelcomeModal";


const Homepage: FC = () => {
  const { isModalOpen, showModal, hideModal } = useModal();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const showProfileModal = () => setIsProfileModalOpen(true);
  const hideProfileModal = () => setIsProfileModalOpen(false);
  const deleteProject = useAppSelector((state) => state.deleteProject.value);
  const grantPermission = useAppSelector(
    (state) => state.GrantPermission.value
  );
  const welcomeUser = useAppSelector((state) => state.WelcomeUser.value);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (String(deleteProject).length !== 0) {
      showModal();
      setTimeout(() => {
        dispatch(deleteProjectReset(""));
        hideModal();
        }, 2000)
   
    }
  }, [deleteProject]);

  useEffect(() => {
    if (welcomeUser === "start") {
      setIsProfileModalOpen(true);
    } else if (welcomeUser === "redirect") {
      setTimeout(() => {
        setIsProfileModalOpen(false);
      }, 1500);
    } else {
      setIsProfileModalOpen(false);
    }
  }, [welcomeUser]);

  const DeleteSuccessModal = () => {
    return (
      <FormModal
        title={"Success!"}
        TitleBackgroundColor={colorPallete.btGreen}
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
        width="480px"
        cancelButton
      >
        <VerticalStack style={{ height: "100px", padding: "20px 25px" }}>
          <Text
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
              color: "var(--black, #323232)",
            }}
          >
            {t("project")} {deleteProject && deleteProject} {t("deleted")}
          </Text>
        </VerticalStack>
      </FormModal>
    );
  };
  return (
    <FadeTransition>
      <Stack
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          backgroundColor: "",
          position: "relative",
        }}
      >
        {grantPermission ? (
          <Stack style={styles.container}>
            <Loader />
            <Text style={styles.text}>{t("loading")}</Text>
          </Stack>
        ) : (
          <>
            <FunctionalityButtonStack />
            <DocumentStack />
            <ProjectStack />
            <DeleteSuccessModal />
            <WelcomeModal
              isModalOpen={isProfileModalOpen}
              hideModal={hideProfileModal}
              showModal={showProfileModal}
            />
          </>
        )}
        <Help />
      </Stack>
    </FadeTransition>
  );
};

export default Homepage;

const styles = {
  container:{
    paddingTop:"70px"
  },
  text:{
    fontSize: "30px",
    fontFamily:"Poppins",
    paddingTop:"10px"
  }
}