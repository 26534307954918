import { FontIcon, Stack, Text } from "@fluentui/react";
import { FAQ } from "../../models";
import Data from "../Homepage/faqsData";
import { HorizontalStack, VerticalStack } from "../../components/Stack";
import { Logo } from "../../components/Logo/index.";
import { FormModal } from "./formModal";
import "./index.css"

const HelpSupport = () => {
  const data: FAQ[] = Data;

  const FAQs = (props: FAQ) => {
    return (
      <VerticalStack
        style={{
          width: "30%",
          borderRadius: "8px",
          margin: "30px 10px",
          minWidth: "300px",
        }}
      >
        <Text
          style={{ padding: "5px 12px", fontSize: "20px", fontWeight: "500" }}
        >
          {props.question}
        </Text>
        <Text
          style={{
            padding: "8px 12px",
            fontSize: "16px",
          }}
        >
          {props.answer}
        </Text>
        <Text
          style={{
            padding: "8px 12px",
            fontSize: "16px",
            cursor:"pointer"
          }}
        >
          <a href={props.link ?? props.link}>{props.link ?? props.link}</a>
        </Text>
      </VerticalStack>
    );
  };
  return (
    <Stack className="Body">
      <VerticalStack className="faqBox">
        <Text className="headText">FAQs</Text>
        <Text className="subHeading">
          frequently asked questions, get knowledge befere hand
        </Text>
      </VerticalStack>
      <HorizontalStack style={{ justifyContent: "center", flexWrap: "wrap" }}>
        {data.map((element: FAQ) => {
          return (
            <FAQs
              question={element.question}
              answer={element.answer}
              link={element.link}
            />
          );
        })}
      </HorizontalStack>
      <HorizontalStack className="middle">
        <VerticalStack className="modalTextWrapper">
          <Text id="midSizeText">Still have Questions?</Text>
          <Text id="normalSizeText">
            Can't find answers you are looking for?
          </Text>
        </VerticalStack>
        <FormModal />
      </HorizontalStack>
      <HorizontalStack
        style={{
          width: "90%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack className="line"></Stack>
        <Logo />
        <Stack className="line"></Stack>
      </HorizontalStack>
      <VerticalStack className="footer">
        <VerticalStack style={{ width: "100%", alignItems: "center" }}>
          <Text
            id="text"
            style={{ fontSize: "34px", fontWeight: "500", padding: "10px" }}
          >
            Contact Us
          </Text>
          <Text id="text" style={{ fontSize: "20px", padding: "5px" }}>
            Need help? Get in touch with us and we'll be more than happy to
            guide you through!
          </Text>
        </VerticalStack>
        <HorizontalStack className="contact" style={{ width: "100%" }}>
          <VerticalStack className="contactDetails">
            <FontIcon iconName="ContactInfo" className="icon" />
            <Text id="text">(704)905-4332</Text>
          </VerticalStack>
          <VerticalStack className="contactDetails">
            <FontIcon iconName="Mail" className="icon" />
            <Text style={{ fontSize: "20px" }}>
              <a href="mailto:Help@bargainingtable.io">
                Help@bargainingtable.io
              </a>
            </Text>
          </VerticalStack>
        </HorizontalStack>
      </VerticalStack>
    </Stack>
  );
};

export default HelpSupport;
