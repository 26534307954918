import type { RouteObject } from "react-router-dom";
import Layout from "../modules/Layout";
import AllProjects from "../pages/AllProjects";
import DocumentPage from "../pages/Document";
import DocumentVersions from "../pages/DocumentVersions";
import Docusign from "../pages/Docusign";
import Homepage from "../pages/Homepage";
import MyPrecedentFiles from "../pages/MyPrecedentFiles";
import HelpSupport from "../pages/HelpSupport";
import { PageNotFound } from "../pages/PageNotFound";
const PublicRoutes: RouteObject = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "home",
      element: <Homepage />,
    },
    {
      path: "support",
      element: <HelpSupport />,
    },
    {
      path: "home/project",
      element: <AllProjects />,
    },
    {
      path: "home/project/:projectId",
      element: <DocumentPage />,
    },
    {
      path: "home/:projectId",
      element: <DocumentPage />,
    },
    {
      path: "home/precedent",
      element: <MyPrecedentFiles />,
    },
    {
      path: "document",
      element: <DocumentPage />,
    },
    {
      path:"docusign",
      element:<Docusign/>
    },
    {
      path:"home/:folderName/versions",
      element:<DocumentVersions/>
    },
    {
      path:"home/project/:folderName/versions",
      element:<DocumentVersions/>
    },
    {
      path:"pagenotfound",
      element:<PageNotFound/>
    }
  ],
};
export default PublicRoutes;
