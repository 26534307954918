import { Modal, PrimaryButton, Stack, Text } from "@fluentui/react";
import React, { FC, useState } from "react";
import SelectDocumentGroup from "./SelectDocument";
import { HorizontalStack, VerticalStack } from "../../Stack";
import CrossIcon from "../../../assets/Icons/svg/crossIcon";
import { t } from "i18next";
import { colorPallete } from "../../../assets/color";
import { BlackDocumentIcon } from "../../../assets/Icons/svg/blackDocIcon";

const modalStyles = {
  root: {
    background: "rgba(0, 0, 0, 0.5)",
  },
  main: {
    borderRadius: "12px",
    width: "752px",
    padding: "18px 8px 8px 8px",
    alignItems: "center",
    background: "linear-gradient(90deg, #ECF0F9 0%, #EFECF9 100%)",
  },
};
const SelectedDocument: FC<any> = (props) => {
  return (
    <HorizontalStack style={styles.selectedDocContainer}>
      <BlackDocumentIcon />
      <Text style={styles.selectedTextStyle}>{`${props.num}`} </Text>
      <Text style={styles.selectedTextStyle}>
        {" "}
        {props.num > 1 ? t("documents_selected") : t("document_selected")}{" "}
      </Text>
    </HorizontalStack>
  );
};

const SelectDocumentModal: FC<any> = (props) => {
  const [error, setError] = useState(false);

  const handleClick = () => {
    if (props?.selectedDocuments?.length === 0) {
      setError(true);
    } else {
      props?.hideModal();
      props?.showShareModal();
    }
  };
  const Title = () => {
    return (
      <HorizontalStack style={styles.titleContainer}>
        <Text
          styles={{
            root: {
              color: "#323232",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "26px",
            },
          }}
        >
          {t("select_documents")}
        </Text>
        <Stack
          onClick={() => {
            props.hideModal();
          }}
          style={{ cursor: "pointer" }}
        >
          <CrossIcon />
        </Stack>
      </HorizontalStack>
    );
  };

  return (
    <Modal
      styles={modalStyles}
      isOpen={props.isModalOpen}
      onDismiss={props.hideModal}
      isBlocking={true}
    >
      <Title />
      <VerticalStack
        styles={{
          root: {
            border: "1px solid #D8E1EB",
            background: "#FFF",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
            alignItems: "center",
          },
        }}
      >
        <SelectDocumentGroup
          selectedDocuments={props?.selectedDocuments}
          setSelectedDocuments={props?.setSelectedDocuments}
          setError={setError}
        />
        {error && (
          <Text style={styles.errorText}>{t("please_select_documents")}</Text>
        )}
        <HorizontalStack style={styles.documentContainer}>
          <SelectedDocument num={props?.selectedDocuments?.length} />
          <HorizontalStack style={{ gap: "8px", alignItems: "center" }}>
            <PrimaryButton
              text={String(t("prepare_email"))}
              styles={{
                root: {
                  padding: "12px 24px",
                  backgroundColor: "#18468F",
                  borderRadius: "4px",
                  minWidth: "120px",
                  height: "40px",
                },
                label: {
                  color: "#F4F4F4",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  margin: 0,
                },
                rootHovered: {
                  backgroundColor: "#18468F",
                },
              }}
              onClick={handleClick}
            />
            <PrimaryButton
              text={String(t("cancel"))}
              styles={{
                root: {
                  padding: "12px 24px",
                  backgroundColor: "#E8ECF4",
                  borderRadius: "4px",
                  minWidth: "120px",
                  height: "40px",
                  border: "1px solid #E8ECF4",
                },
                label: {
                  color: "#323232",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  margin: 0,
                },
                rootHovered: {
                  backgroundColor: "#E8ECF4",
                  border: "1px solid #E8ECF4",
                },
              }}
              onClick={() => {
                props?.setSelectedDocuments([]);
                props.hideModal();
              }}
            />
          </HorizontalStack>
        </HorizontalStack>
      </VerticalStack>
    </Modal>
  );
};

export default SelectDocumentModal;

const styles = {
  loadingContainer: {
    minHeight: "112px",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
  },
  loadingText: {
    fontFamily: "Poppins",
    fontSize: "16px",
  },
  documentContainer: {
    justifyContent: "space-between",
    width: "100%",
    padding: "14px 20px",
    alignItems: "center",
  },
  errorText: {
    display: "flex",
    alignSelf: "flex-start",
    paddingLeft: "20px",
    color: colorPallete.btRed,
  },
  titleContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 14px 10px 16px",
  },
  selectedTextStyle: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "26px",
  },
  selectedDocContainer: {
    justifyContent: "flex-start",
    gap: "4px",
    alignItems: "center",
    marginLeft: "2px",
  },
};
