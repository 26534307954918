import React from "react";

export default function Classified() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
      >
        <path
          d="M5.8 13C5.8 13 10.6 10.6 10.6 7V2.8L5.8 1L1 2.8V7C1 10.6 5.8 13 5.8 13Z"
          stroke="#717070"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
