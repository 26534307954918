import React from "react";

function BlueCrossIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <rect
        x="11.5391"
        width="2.22539"
        height="16.3195"
        rx="1.1127"
        transform="rotate(45 11.5391 0)"
        fill="#18468F"
      />
      <rect
        x="13.1133"
        y="11.5391"
        width="2.22539"
        height="16.3195"
        rx="1.1127"
        transform="rotate(135 13.1133 11.5391)"
        fill="#18468F"
      />
    </svg>
  );
}

export default BlueCrossIcon;
