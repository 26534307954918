import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "../../../models";
import { ApiClient } from "../../../network/ApiClient";
import { ApiEndpoints } from "../../../network/ApiEndpoints";

type InitialState = {
  isLoading: boolean;
  data: UserModel[];
  error: string;
};

const initialState: InitialState = {
  isLoading: false,
  data: [],
  error: "",
};

export const getAllUsers = createAsyncThunk("getAllusers", async () => {
  let response = await ApiClient.get<any>(ApiEndpoints.users(), {}, {});
  const users: UserModel[] = [];
  response.forEach((users1: any) => {
    let user: UserModel = {
      id: users1.id,
      firstName: users1.firstName,
      lastName:users1.lastName,
      email: users1.mail,
      type: users1.type,
      isOrgUser: users1.isOrgUser,
      preferDesktopWord: users1.preferDesktopWord,
      isFirstTimeLogin:users1.isFirstTimeLogin,
      userId :users1.userId
    };
    users.push(user);
  });
  return users;
});

const getAllUserSlice = createSlice({
  name: "getAllUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllUsers.fulfilled,
      (state, action: PayloadAction<UserModel[]>) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message ?? "Something went wrong";
    });
  },
});

export const getAllUsersReducer = getAllUserSlice.reducer;
