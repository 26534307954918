import React, { FC } from "react";
import { FormModal } from "../Modal";
import { DefaultButton, Stack, Text } from "@fluentui/react";
import { HorizontalStack, VerticalStack } from "../../Stack";

const DocRejectModal: FC<any> = (props) => {
  function disable() {
    props.hideModal();
  }
  return (
    <FormModal
      title="Houston we have a problem!"
      TitleBackgroundColor="#A82C0C"
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      width="480px"
      cancelButton
      customCancel={disable}
    >
      <VerticalStack style={{ height: "250px" }}>
        <>
          <VerticalStack style={{ padding: "20px 25px", gap: "10px" }}>
            <Text
              style={{
                fontSize: "16px",
                fontFamily: "Poppins",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#323232",
              }}
            >
              To ensure document security you can only upload documents that are
              saved in the “.docx” file format to the Dexterity platform.
            </Text>
            <Text
              style={{
                fontSize: "16px",
                fontFamily: "Poppins",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#323232",
              }}
            >
              {" "}
              Please convert the document to the “.docx” file format and retry.
            </Text>
          </VerticalStack>
          <Stack className="horizontalLine"></Stack>
          <HorizontalStack
            style={{
              padding: "19px 25px",
              justifyContent: "end",
            }}
          >
            <DefaultButton
              style={{
                width: "120px",
                height: "40px",
                borderRadius: "4px",
                padding: "12px 24px",
                backgroundColor: "#A82C0C",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16px",
                color: "#F4F4F4",
                borderColor: "#A82C0C",
              }}
              onClick={props.hideModal}
            >
              Okay
            </DefaultButton>
          </HorizontalStack>
        </>
      </VerticalStack>
    </FormModal>
  );
};
export default DocRejectModal;
