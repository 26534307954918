import axios, {
  AxiosError,
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
  RawAxiosRequestHeaders,
} from "axios";
import { refreshTokenInterceptor } from "./RTInterceptor";

const handleSuccess = <T>(response: AxiosResponse): T => {
  const { data, status } = response;
  if (status >= 300) {
    const errorMessage = data ? data.message : status;
    throw new AxiosError(
      errorMessage,
      String(status),
      undefined,
      undefined,
      response
    );
  }
  return data as T;
};
const handleError = (e: AxiosError): void => {
  const { message, response } = e;
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const errorMessage = ""
  throw new Error(errorMessage);
};

const BASE_URL = process.env.REACT_APP_BASE_URL;


const getAxiosInstance = (): AxiosInstance => {
  let axiosInstance = axios.create({
    baseURL: `${BASE_URL}`,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  axiosInstance = refreshTokenInterceptor(axiosInstance);
  return axiosInstance;
};

class ApiClient {
  private readonly axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = getAxiosInstance();
  }
  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.axiosInstance.request(config);
      return await Promise.resolve(handleSuccess<T>(response));
    } catch (e) {
      return Promise.reject(handleError(e as AxiosError));
    }
  }
  public async get<T>(
    url: string,
    params?: unknown,
    headers?: RawAxiosRequestHeaders
  ) {
    return this.request<T>({ method: "GET", url, params, headers });
  }
  public async post<D, T>(
    url: string,
    data: D,
    params?: unknown,
    headers?: RawAxiosRequestHeaders,
    timeout?:number
  ) {
    return this.request<T>({
      method: "POST",
      url,
      data,
      params,
      headers,
      timeout
    });
  }
  public async put<D, T>(
    url: string,
    data: D,
    params?: unknown,
    headers?: RawAxiosRequestHeaders
  ) {
    return this.request<T>({ method: "PUT", url, data, params, headers });
  }
  public async patch<D, T>(
    url: string,
    data: D,
    params?: unknown,
    headers?: RawAxiosRequestHeaders
  ) {
    return this.request<T>({ method: "PATCH", url, data, params, headers });
  }
  public async delete<T>(
    url: string,
    params?: unknown,
    headers?: RawAxiosRequestHeaders
  ) {
    return this.request<T>({ method: "DELETE", url, params, headers });
  }
}
const apiClient = new ApiClient();
export { apiClient as ApiClient };
