import React from "react";

function PdfIcon() {
  return (

<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3608 1H5.86084C5.39209 1 4.92334 1.2 4.54834 1.6C4.17334 1.9 3.98584 2.4 3.98584 3V19C3.98584 19.5 4.17334 20 4.54834 20.4C4.92334 20.8 5.39209 21 5.86084 21H17.1108C17.5796 21 18.0483 20.8 18.4233 20.4C18.7983 20 18.9858 19.5 18.9858 19V7M13.3608 1L18.9858 7L13.3608 1ZM13.3608 1V7H18.9858" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M4.27581 1.30579C4.71824 0.843956 5.28241 0.599609 5.86094 0.599609H13.3609C13.4715 0.599609 13.5771 0.645373 13.6528 0.726033L19.2778 6.72603C19.3473 6.80017 19.3859 6.89798 19.3859 6.99961V18.9996C19.3859 19.6009 19.16 20.1988 18.7153 20.6732C18.27 21.1481 17.6979 21.3996 17.1109 21.3996H5.86094C5.27401 21.3996 4.70187 21.1481 4.25662 20.6732C3.81188 20.1988 3.58594 19.6009 3.58594 18.9996V2.99961C3.58594 2.33423 3.79147 1.70724 4.27581 1.30579ZM5.86094 1.39961C5.51036 1.39961 5.145 1.54812 4.84025 1.87319C4.82722 1.88709 4.8132 1.90005 4.79832 1.91196C4.54898 2.11143 4.38594 2.47532 4.38594 2.99961V18.9996C4.38594 19.3983 4.535 19.8004 4.84025 20.126C5.145 20.4511 5.51036 20.5996 5.86094 20.5996H17.1109C17.4615 20.5996 17.8269 20.4511 18.1316 20.126C18.4369 19.8004 18.5859 19.3983 18.5859 18.9996V7.39961H13.3609C13.14 7.39961 12.9609 7.22052 12.9609 6.99961V1.39961H5.86094ZM13.7609 2.01112L18.0626 6.59961H13.7609V2.01112Z" fill="#A82C0C"/>
<rect x="2.61377" y="15" width="9" height="4" rx="1" fill="#A82C0C"/>
<circle cx="4.69951" cy="16.9002" r="0.7" fill="white"/>
<circle cx="7.09941" cy="16.9002" r="0.7" fill="white"/>
<circle cx="9.49932" cy="16.9002" r="0.7" fill="white"/>
<path d="M9.78063 10.8494H10.9308C11.8235 10.8494 12.2012 10.4275 12.2012 9.7561C12.2012 9.05882 11.8235 8.64562 10.9308 8.64562H9.78063V10.8494ZM12.9995 9.7561C12.9995 10.6686 12.4158 11.495 10.9308 11.495H9.78063V14H8.99951V8H10.9308C12.3386 8 12.9995 8.77475 12.9995 9.7561Z" fill="#A82C0C"/>
</svg>

 );
}

export default PdfIcon;