import { Modal, Stack } from "@fluentui/react";
import { FC } from "react";

export const DocPreviewModal: FC<any> = (props) => {
  const ModalStyles = {
    root: {
      background: "rgba(0, 0, 0,0.7)",
    },
    main: {
      width: "470px",
      height: "586px",
      borderRadius: "12px",
      padding: "7px",
      background: "rgba(0,0,0, 0.1)",
    },
  };
  return (
    <Stack>
      <Modal
        styles={{ ...ModalStyles }}
        isOpen={props.isModalOpen}
        onDismiss={props.hideModal}
      >  
        <img
         style={{paddingLeft:"95%",position:"absolute",top:0,right:0,zIndex:2,cursor:"pointer"}}
         onClick={props.hideModal}
         src={require("../../../assets/Icons/Close.png")}
       />
        <Stack style={{ maxHeight: "572px", overflowY: "auto", width: "100%",borderRadius:'12px' }}>
          {props.fileImage}
        </Stack>
      </Modal>
    </Stack>
  );
};
