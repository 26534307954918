import DocumentIcon from "../assets/Icons/svg/documentIcon";
import ReceivedIcon from "../assets/Icons/svg/receivedIcon";
import SendIcon from "../assets/Icons/svg/sendIcon";
import { UserModel } from "../models";

export function getFormattedDate(dateString: Date | string) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(dateString);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  let daySuffix;
  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  } else {
    switch (day % 10) {
      case 1:
        daySuffix = "st";
        break;
      case 2:
        daySuffix = "nd";
        break;
      case 3:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
    }
  }

  const formattedDate = `${month} ${day}${daySuffix} ${year}`;
  return formattedDate;
}

export function getFormattedDateTime(dateString: Date | string) {
  const date = new Date(dateString);

  // Format the date
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;

  // Format the time
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const formattedTime = `${String(hours).padStart(2, "0")}:${minutes} ${ampm}`;
  return [formattedDate, formattedTime];
}

export function isValidEmail(email: string | null) {
  let regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (!email) {
    return false;
  }
  return regex.test(email);
}

export function getFullName(user: UserModel) {
  let fullName = `${user?.firstName} ${user?.lastName}`;
  return fullName;
}

const colors = [
  "#101828",
  "#007CFF",
  "#750b1c",
  "#18468F",
  "#e3008c",
  "#5c2e91",
  "#986f0b",
];

export const getColor = (name: string) => {
  return colors[name?.length % colors.length];
};

export function getSplitDocumentName(name: string) {
  if (name && name.includes(".doc")) {
    return name.split(".doc")[0];
  } else if (name && name.includes(".docx")) {
    return name.split(".docx")[0];
  } else {
    return name;
  }
}

export function getSortedArrayMRU(initialArray: any) {
  const sortedArray = [...initialArray].sort((a: any, b: any) => {
    const date1 = new Date(a?.lastUpdatedDate!);
    const date2 = new Date(b?.lastUpdatedDate!);
    return date2.getTime() - date1.getTime();
  });
  return sortedArray;
}

export function getIcon(fileType: string) {
  if (fileType === "sent") {
    return <SendIcon />;
  } else if (fileType === "received") {
    return <ReceivedIcon />;
  } else {
    return <DocumentIcon />;
  }
}

export function isNearOneMinute(dateTime: Date): boolean {
  const currentTime = new Date();
  const timeDifference = Math.abs(currentTime.getTime() - dateTime.getTime());
  const oneMinuteInMilliseconds = 2 * 60 * 1000;
  return timeDifference <= oneMinuteInMilliseconds;
}