import React from "react";

export default function Replyall() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
      >
        <path
          d="M4 4.80156H9.4C14.2 4.80156 14.2 11.4016 9.4 11.4016M4 4.80156L8.2 0.601562M4 4.80156L8.2 9.00156"
          stroke="#A82C0C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.2 0.601562L1 4.80156L5.2 9.00156"
          stroke="#A82C0C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
