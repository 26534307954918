import React from "react";

function WordDocIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
    >
      <path
        d="M16.9065 0.287109H5.42885C5.11325 0.287109 4.85742 0.542938 4.85742 0.858538V23.1443C4.85742 23.4599 5.11325 23.7157 5.42885 23.7157H22.0003C22.3159 23.7157 22.5717 23.4599 22.5717 23.1443V5.95237C22.5717 5.80082 22.5115 5.65548 22.4043 5.54831L17.3105 0.454481C17.2033 0.347309 17.058 0.287109 16.9065 0.287109Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.57227 0.857143C4.57227 0.383747 4.95601 0 5.42941 0H16.907C17.1343 0 17.3523 0.0903032 17.5131 0.251055L22.6069 5.34488C22.7677 5.50564 22.858 5.72365 22.858 5.95097V23.1429C22.858 23.6163 22.4742 24 22.0008 24H5.42941C4.95601 24 4.57227 23.6163 4.57227 23.1429V0.857143ZM5.42941 0.571429C5.2716 0.571429 5.14369 0.699339 5.14369 0.857143V23.1429C5.14369 23.3007 5.2716 23.4286 5.42941 23.4286H22.0008C22.1586 23.4286 22.2866 23.3007 22.2866 23.1429V5.95097C22.2866 5.87521 22.2565 5.80254 22.2029 5.74895L17.109 0.655116C17.0554 0.601525 16.9828 0.571429 16.907 0.571429H5.42941Z"
        fill="#979593"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5723 5.42997V0.287109H17.1437V5.42997C17.1437 5.58777 17.2716 5.71568 17.4294 5.71568H22.5723V6.28711H17.4294C16.956 6.28711 16.5723 5.90336 16.5723 5.42997Z"
        fill="#979593"
      />
      <path
        d="M20.2849 15.7143H11.4277V15.1429H20.2849C20.4427 15.1429 20.5706 15.2708 20.5706 15.4286C20.5706 15.5864 20.4427 15.7143 20.2849 15.7143ZM20.2849 14H11.4277V13.4286H20.2849C20.4427 13.4286 20.5706 13.5565 20.5706 13.7143C20.5706 13.8721 20.4427 14 20.2849 14ZM20.2849 12.2857H11.4277V11.7143H20.2849C20.4427 11.7143 20.5706 11.8422 20.5706 12C20.5706 12.1578 20.4427 12.2857 20.2849 12.2857ZM20.2849 10.5714H11.4277V10H20.2849C20.4427 10 20.5706 10.1279 20.5706 10.2857C20.5706 10.4435 20.4427 10.5714 20.2849 10.5714ZM20.2849 17.4286H11.4277V16.8571H20.2849C20.4427 16.8571 20.5706 16.9851 20.5706 17.1429C20.5706 17.3007 20.4427 17.4286 20.2849 17.4286Z"
        fill="#C8C6C4"
      />
      <path
        d="M1.14286 19.4286H10.2857C10.9169 19.4286 11.4286 18.9169 11.4286 18.2857V9.14286C11.4286 8.51169 10.9169 8 10.2857 8H1.14286C0.511686 8 0 8.51169 0 9.14286V18.2857C0 18.9169 0.511686 19.4286 1.14286 19.4286Z"
        fill="#185ABD"
      />
      <path
        d="M3.89327 15.6133C3.91216 15.7624 3.92442 15.8924 3.9303 16.0035H3.95207C3.96082 15.8982 3.97747 15.7712 4.0021 15.6221C4.02673 15.4732 4.05076 15.3475 4.07402 15.2452L5.08373 10.8574H6.3895L7.43413 15.1795C7.48642 15.3929 7.52993 15.6644 7.5647 15.9947H7.58207C7.59659 15.7667 7.63284 15.5037 7.6909 15.2057L8.52664 10.8574H9.71484L8.25247 17.1431H6.8639L5.86719 12.979C5.83816 12.8591 5.80553 12.7029 5.76924 12.51C5.73296 12.3173 5.71047 12.1769 5.70182 12.0893H5.68444C5.67282 12.1916 5.6503 12.3435 5.6169 12.545C5.5835 12.7468 5.55662 12.8957 5.53636 12.9921L4.60056 17.1431H3.19036L1.71484 10.8574H2.92482L3.83462 15.2541C3.85487 15.3445 3.87439 15.4644 3.89327 15.6133Z"
        fill="white"
      />
    </svg>
  );
}
export default WordDocIcon;
