import { FC } from "react";
import { HorizontalStack } from "../../Stack";
import { TextForPageHeaders } from "../../Text";
import {  IconButton, Modal } from "@fluentui/react";
import { colorPallete } from "../../../assets/color";

const headerStackStyles = {
  root: {
    backgroundColor: colorPallete.btBlue,
    padding: "12px 20px",
    color: colorPallete.white,
  },
};

export const FormModal: FC<any> = (props) => {
  const modalStyles = {
    root: {
      // background: "rgba(0, 0, 0, 0.5)",
      boxShadow: "0px 0px 60px 0px rgba(0, 0, 0, 0.40)"
    },
    main: {
      width: props.width,
      height: props.height,
      borderRadius: "8px",
      // backgroundColor: colorPallete.btBlue,
    },
  };

  const { children, ...rest } = props;

  return (
    <Modal
      {...rest}
      styles={modalStyles}
      isOpen={props.isModalOpen}
      onDismiss={props.hideModal}
      isBlocking={true}
    >
      <HorizontalStack
        styles={headerStackStyles}
        horizontalAlign="space-between"
        style={{
          backgroundColor: props?.TitleBackgroundColor
        }}
      >
        {props.title && <TextForPageHeaders>{props.title}</TextForPageHeaders>}
        {props.cancelButton && (
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={props?.customCancel ? props.customCancel: props.hideModal}
            style={{
              color: props.title ? colorPallete.white : colorPallete.black1,
              backgroundColor: "inherit",
              position: "absolute",
              right: 10,
            }}
          />
        )}
      </HorizontalStack>
      {children}
    </Modal>
  );
};
