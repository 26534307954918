import { FC, ReactElement, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useIsSignedIn from "../../hooks/useIsSignedIn";
import Header from "../../components/Header";
import { disableMessageBar } from "../redux/MessageBarSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import Homepage from "../../pages/Homepage";
import HelpSupport from "../../pages/HelpSupport";

const Layout: FC = (): ReactElement => {
  const location = useLocation();
  const isVisible = useAppSelector((state) => state.MessageBarView.isVisible);
  const dispatch = useAppDispatch();
  const url = window.location.search;
  const LINK = window.location.href;

  const desktopUrl = new URLSearchParams(url).get("desktopUrl");

  useEffect(() => {
    if (desktopUrl) {
      window.open(`ms-word:ofe|u|${desktopUrl}`,"_self")
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => dispatch(disableMessageBar()), 2000);
    }
  }, [isVisible]);

  const isSignedIn = useIsSignedIn();
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <Header />
      {!isSignedIn[0] && location.pathname === "/support" ? (
        <HelpSupport />
      ) : isSignedIn[0] || location.pathname === "/docusign" ? (
        <Outlet />
      ) : (
        <Homepage />
      )}
    </div>
  );
};

export default Layout;
