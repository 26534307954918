import React from "react";

export default function Redline() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
      >
        <path
          d="M9.875 1H2.375C1.90625 1 1.4375 1.2 1.0625 1.6C0.6875 1.9 0.5 2.4 0.5 3V19C0.5 19.5 0.6875 20 1.0625 20.4C1.4375 20.8 1.90625 21 2.375 21H13.625C14.0938 21 14.5625 20.8 14.9375 20.4C15.3125 20 15.5 19.5 15.5 19V7M9.875 1L15.5 7L9.875 1ZM9.875 1V7H15.5"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.789478 1.30774C1.23191 0.84591 1.79608 0.601562 2.37461 0.601562H9.87461C9.98517 0.601562 10.0908 0.647326 10.1664 0.727986L15.7914 6.72799C15.8609 6.80212 15.8996 6.89994 15.8996 7.00156V19.0016C15.8996 19.6028 15.6737 20.2007 15.2289 20.6751C14.7837 21.1501 14.2115 21.4016 13.6246 21.4016H2.37461C1.78768 21.4016 1.21554 21.1501 0.770294 20.6751C0.325549 20.2007 0.0996094 19.6028 0.0996094 19.0016V3.00156C0.0996094 2.33618 0.30514 1.7092 0.789478 1.30774ZM2.37461 1.40156C2.02404 1.40156 1.65867 1.55007 1.35392 1.87514C1.34089 1.88905 1.32687 1.902 1.31199 1.91391C1.06265 2.11338 0.899609 2.47727 0.899609 3.00156V19.0016C0.899609 19.4003 1.04867 19.8024 1.35392 20.128C1.65867 20.4531 2.02404 20.6016 2.37461 20.6016H13.6246C13.9752 20.6016 14.3405 20.4531 14.6453 20.128C14.9505 19.8024 15.0996 19.4003 15.0996 19.0016V7.40156H9.87461C9.6537 7.40156 9.47461 7.22248 9.47461 7.00156V1.40156H2.37461ZM10.2746 2.01307L14.5763 6.60156H10.2746V2.01307Z"
          fill="#18468F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.09961 13.0016C4.09961 12.7806 4.2787 12.6016 4.49961 12.6016H11.4996C11.7205 12.6016 11.8996 12.7806 11.8996 13.0016C11.8996 13.2225 11.7205 13.4016 11.4996 13.4016H4.49961C4.2787 13.4016 4.09961 13.2225 4.09961 13.0016Z"
          fill="#ED4B4B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.09961 16.0016C4.09961 15.7806 4.2787 15.6016 4.49961 15.6016H11.4996C11.7205 15.6016 11.8996 15.7806 11.8996 16.0016C11.8996 16.2225 11.7205 16.4016 11.4996 16.4016H4.49961C4.2787 16.4016 4.09961 16.2225 4.09961 16.0016Z"
          fill="#ED4B4B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.09961 10.0016C4.09961 9.78065 4.2787 9.60156 4.49961 9.60156H6.49961C6.72052 9.60156 6.89961 9.78065 6.89961 10.0016C6.89961 10.2225 6.72052 10.4016 6.49961 10.4016H4.49961C4.2787 10.4016 4.09961 10.2225 4.09961 10.0016Z"
          fill="#ED4B4B"
        />
      </svg>
    </div>
  );
}
