import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface DeleteProject {
  value: string;
}

const initialState: DeleteProject = {
  value: "",
};

export const deleteProjectSlice = createSlice({
  name: "UploadDoc",
  initialState,
  reducers: {
    deleteProjectReset: (state,action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    setDeleteProject: (state,action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});
export const { deleteProjectReset, setDeleteProject } = deleteProjectSlice.actions;
const DeleteProjectReducer = deleteProjectSlice.reducer;
export default DeleteProjectReducer;