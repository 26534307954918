import { Stack, Text } from "@fluentui/react";
import { t } from "i18next";
import { HorizontalStack } from "../../../Stack";
import { colorPallete } from "../../../../assets/color";

interface CustomProps {
  newVersion: boolean;
  multipleFilesError: boolean;
  setNewVersion?: any;
  files?: number;
  setMultipleFilesError?: any;
  copy?:any
}

const Buttons: React.FC<CustomProps> = ({
  newVersion,
  setNewVersion,
  files,
  setMultipleFilesError,
  multipleFilesError,
  copy
}) => {
  function handleFirstButtonClick() {
    if (multipleFilesError) {
      setNewVersion(!newVersion);
      setMultipleFilesError(false);
    } else {
      setNewVersion(!newVersion);
    }
  }

  function handleSecondButtonClick() {
    if (files && files > 1) {
      setMultipleFilesError(true);
      setNewVersion(!newVersion);
    } else {
      setNewVersion(!newVersion);
    }
  }

  return (
    <HorizontalStack
      style={{ justifyContent: "space-between", marginTop: "6px" }}
    >
      <HorizontalStack
        onClick={() => {
          handleFirstButtonClick();
        }}
        style={{
          ...styles.button,
          border: !newVersion ? `1px solid ${colorPallete.btBlue}` : "#E8ECF4",
        }}
      >
        <Stack
          style={{
            ...styles.greyCircle,
            border: !newVersion
              ? "1px solid var(--primary-red, #18468F)"
              : "1px solid var(--primary-red, #707070)",
            position: "relative",
          }}
        >
          {!newVersion ? (
            <Stack
              style={{ ...styles.blueCircle, position: "absolute" }}
            ></Stack>
          ) : null}
        </Stack>
        <Text style={styles.mediumText}>{t("new_document")}</Text>
      </HorizontalStack>
      <HorizontalStack
        onClick={() => {
          handleSecondButtonClick();
        }}
        style={{
          ...styles.button,
          border: newVersion ? `1px solid ${colorPallete.btBlue}` : "#E8ECF4",
        }}
      >
        <Stack
          style={{
            ...styles.greyCircle,
            border: newVersion
              ? "1px solid var(--primary-red, #18468F)"
              : "1px solid var(--primary-red, #707070)",
            position: "relative",
          }}
        >
          {newVersion ? (
            <Stack
              style={{ ...styles.blueCircle, position: "absolute" }}
            ></Stack>
          ) : null}
        </Stack>
        <Text style={styles.mediumText}>{t("new_version")}</Text>
      </HorizontalStack>
    </HorizontalStack>
  );
};

export default Buttons;

const styles = {
  greyCircle: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "15px",
    marginRight: "4px",
  },
  blueCircle: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "#18468F",
    alignSelf: "center",
    transform: "translate(-50%, -50%)",
    top: "9px",
    left: "9px",
  },
  button: {
    width: "48.5%",
    height: "40px",
    backgroundColor: "#F8F8F8",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  mediumText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    fontStyle: "normal",
  },
};
