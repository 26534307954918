import { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { Providers } from "@microsoft/mgt-react";

export const refreshTokenInterceptor = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(async function (
    config: InternalAxiosRequestConfig
  ) {
    try {
      const ac = await Providers.globalProvider.getAccessToken();
      if (ac) {
        config.headers = config.headers ?? ({} as Record<string, any>);
        config.headers.Authorization = `Bearer ${ac}`;
      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  });

  axiosInstance.interceptors.response.use((response) => {
    return response;
  });
  return axiosInstance;
};
