import React from "react";

function SearchIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8171 13.9004L13.6352 12.7169C13.2734 12.3527 12.906 11.9839 12.5498 11.6263L11.8712 10.9458L11.7466 10.8201L11.8516 10.6774C12.4406 9.89099 12.856 8.98816 13.07 8.02878C13.2579 7.16648 13.2687 6.27492 13.1019 5.4083C12.8356 3.88281 12.0368 2.50166 10.848 1.51115C9.65916 0.520645 8.15764 -0.0148119 6.6112 0.00031182C5.74802 -0.0072728 4.8919 0.156541 4.09233 0.482281C3.29276 0.808021 2.56561 1.28923 1.95288 1.89809C0.807952 3.03965 0.118694 4.56058 0.0145835 6.17512C-0.0455698 7.08008 0.0807889 7.98773 0.385802 8.84172C0.690816 9.6957 1.16795 10.4778 1.78758 11.1393C2.40722 11.8008 3.15611 12.3276 3.98769 12.687C4.81927 13.0464 5.71577 13.2307 6.62149 13.2284C8.08845 13.2148 9.51222 12.7295 10.6828 11.844L10.8252 11.7398L10.9499 11.8647L11.6304 12.5442C11.964 12.8777 12.298 13.2112 12.6323 13.5447L13.8995 14.8136C13.9585 14.8727 14.0284 14.9196 14.1055 14.9516C14.1825 14.9835 14.2651 15 14.3485 15C14.4319 15 14.5145 14.9835 14.5915 14.9516C14.6685 14.9196 14.7385 14.8727 14.7975 14.8136L14.8115 14.7996C14.9312 14.681 14.999 14.5197 15.0001 14.3512C15.0011 14.1826 14.9353 14.0205 14.8171 13.9004ZM6.6037 11.9445H6.54464C5.85108 11.9425 5.16499 11.8012 4.52696 11.5289C3.88893 11.2566 3.31195 10.8588 2.83019 10.3592C2.08836 9.60896 1.58554 8.65514 1.38539 7.61854C1.18523 6.58193 1.29674 5.50915 1.70581 4.53604C2.11487 3.56294 2.80309 2.73329 3.68332 2.15209C4.56356 1.57089 5.59621 1.26429 6.65056 1.27113C7.34308 1.26868 8.0292 1.40398 8.66909 1.66917C9.30898 1.93437 9.88992 2.32419 10.3782 2.816C10.8827 3.32514 11.2813 3.92953 11.5508 4.59413C11.8203 5.25873 11.9554 5.9703 11.9481 6.68756C11.931 8.09308 11.3594 9.43476 10.3579 10.4197C9.35639 11.4046 8.00644 11.9527 6.60276 11.9445H6.6037Z"
        fill="#18468F"
      />
    </svg>
  );
}

export default SearchIcon;
