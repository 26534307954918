import { createSlice } from "@reduxjs/toolkit";

export interface Grantpermission {
  value: boolean;
}

const initialState: Grantpermission = {
  value: false,
};

export const grantPermissionSlice = createSlice({
  name: "UploadDoc",
  initialState,
  reducers: {
    grantPermission: (state) => {
      state.value = false;
    },
    setGrantPermission: (state) => {
      state.value = true;
    },
  },
});
export const { grantPermission, setGrantPermission } = grantPermissionSlice.actions;
const GrantPermissionReducer = grantPermissionSlice.reducer;
export default GrantPermissionReducer;