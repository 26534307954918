import { FC, useState } from "react";
import { Document, UserModel } from "../../../models";
import { HorizontalStack } from "../../Stack";
import { mergeStyles, Stack, Text } from "@fluentui/react";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import useModal from "../../../hooks/useModal";
import { DocPreviewModal } from "../../modals/DocPreviewModal";
import { Thumbnails } from "../../Thumbnails";
import OpenFile from "../../../navigation/NoobOrPro";
import { colorPallete } from "../../../assets/color";
import CopyWhiteIcon from "../../../assets/Icons/svg/copyWhiteIcon";
import ZoomWhiteIcon from "../../../assets/Icons/svg/zoomWhiteIcon";
import { CreateDocumentModal } from "../../modals/CreateDocumentModal";
import { CreateProjectModal } from "../../modals/CreateProjectModal";
import { getSplitDocumentName } from "../../../utils";

initializeFileTypeIcons();

const styles = {
  DocCardDivStyle: {
    display: "flex",
    backgroundColor: "",
    height: "208px",
    width: "100%",
    border: "1px solid var(--stroke, #EAEAEA)",
  },
  IconStyle: {
    paddingTop: "2%",
    paddingRight: "2%",
    paddingBottom: "2%",
    overflow: "visible",
  },
  MotionDiv: {
    display: "flex",
    bottom: "0px",
    alignItems: "center",
    height: "69%",
    width: "100%",
    paddingBottom: "20%",
    borderBottomLeftRadius: "0.4rem",
    borderBottomRightRadius: "0.4rem",
    justifyContent: "center",
    paddingLeft: "5%",
    backgroundColor: "",
  },
  documentWrapper: {
    height: "74px",
    backgroundColor: "#E8ECF4",
    padding: "8px",
    zIndex: 5,
    top: 0,
    width: "100%",
    cursor: "pointer",
  },
  docText: {
    color: colorPallete.black1,
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "16px",
    fontFamily: "Poppins",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    overflow: "hidden",
    backgroundColor: "",
  },
};

const documentDivStyle = mergeStyles({
  height: "fit-content",
  width: "10rem",
  backgroundColor: "",
  position: "relative",
  overflow: "hidden",
});

const stackStyle = mergeStyles({
  position: "absolute",
  bottom: "50px",
  paddingLeft: "2%",
  fontSize: "20px",
  left: "40px",
  paddingRight: "2%",
  width: "50%",
});

type Props = {
  user?: UserModel;
  document: Document;
};

export const DocumentInCard: FC<Props> = (props) => {
  const [hoverId, setHoverId] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [projectModel, setProjectModel] = useState(false);
  const { isModalOpen, showModal, hideModal } = useModal();

  function showPreviewModal() {
    setPreviewModal(true);
  }
  function hidePreviewModal() {
    setPreviewModal(false);
  }

  function showProjectModal() {
    setProjectModel(true);
  }
  function hideProjectModal() {
    setProjectModel(false);
  }
  function getModelValue() {
    hideModal();
    showProjectModal();
  }
  function displayUplaod() {
    showModal();
  }

  return (
    <div style={styles.DocCardDivStyle}>
      <div
        className={documentDivStyle}
        title={props.document?.rootDocumentName}
        onMouseEnter={() => setHoverId(true)}
        onMouseLeave={() => setHoverId(false)}
      >
        <Thumbnails
          driveItemId={props.document.driveItemId}
          hoverId={hoverId}
          height={206}
        />
        <Stack
          onClick={() => {
            OpenFile(
              props.document.documentLink,
              props.document.documentWebLink
            );
          }}
          style={{ ...styles.documentWrapper, position: "absolute" }}
        >
          <Text
            style={{
              ...styles.docText,
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {getSplitDocumentName(props.document?.rootDocumentName)}
          </Text>
        </Stack>
        {hoverId && (
          <>
            <HorizontalStack
              horizontalAlign="space-between"
              className={stackStyle}
            >
              <Stack onClick={showModal} style={{ cursor: "pointer" }}>
                <CopyWhiteIcon />
              </Stack>
              <Stack onClick={showPreviewModal} style={{ cursor: "pointer" }}>
                <ZoomWhiteIcon />
              </Stack>
            </HorizontalStack>
          </>
        )}
      </div>

      <CreateDocumentModal
        fileName={props.document.rootDocumentName}
        isModalOpen={isModalOpen}
        showModal={showModal}
        hideModal={hideModal}
        project={getModelValue}
        copy={true}
        document={props.document}
      />
      <CreateProjectModal
        isModalOpen={projectModel}
        showModal={showProjectModal}
        hideModal={hideProjectModal}
        displayDoc={displayUplaod}
      />
      <DocPreviewModal
        fileImage={
          <Thumbnails
            driveItemId={props.document.driveItemId}
            hoverId={hoverId}
            height={600}
          />
        }
        fileName={props.document?.rootDocumentName}
        showModal={showPreviewModal}
        hideModal={hidePreviewModal}
        isModalOpen={previewModal}
      />
    </div>
  );
};
