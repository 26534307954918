import { DefaultButton, FontIcon, mergeStyles, Text } from "@fluentui/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { colorPallete } from "../../assets/color";
interface ViewAllButtonProps {
  onClickFunction: () => void;
  collapsed?: boolean;
}

const iconClass = mergeStyles({
  color: "rgb(26 59 110)",
  marginLeft: "5px",
  fontSize: "11.5px",
});

const ViewAllButtonStyles = {
  root: {
    border: "inherit",
    backgroundColor: "inherit",
    justifyContent: "flex-end",
  },
  rootHovered: {
    backgroundColor: "inherit",
  },
  rootPressed: {
    backgroundColor: "inherit",
  },
};

export const ViewAll: FC<ViewAllButtonProps> = (props) => {
  const { t } = useTranslation();
  return (
    <DefaultButton styles={ViewAllButtonStyles} onClick={props.onClickFunction}>
      <Text style={styles.text}>
        {props.collapsed ? t("collapsed_view") : t("view_all")}
      </Text>
      {!props.collapsed ? (
        <FontIcon className={iconClass} iconName="ChromeBackMirrored" />
      ) : (
        <FontIcon className={iconClass} iconName="ChromeBack" />
      )}
    </DefaultButton>
  );
};

const styles = {
  text: {
    color: colorPallete.btBlue2,
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontStyle: "normal",
  },
};
