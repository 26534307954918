import { configureStore } from "@reduxjs/toolkit";
import {
  getPrecedentDocumentReducer,
  getDocumentVersionsReducer,
  getSearchedDocumentReducer,
  getAllPDF,
  getDocuSignCodeReducer,
  shareDocumentReducer,
  createNewVersionReducer,
  shareSentDocumentReducer,
  getSentDocumentAccessReducer,
  getSearchedVersionsReducer,
  createTemplateReducer,
} from "../Documents/document";
import { DocumentStackReducer } from "../DocumentStackSlice";
import DocumentViewReducer from "../DocumentViewSlice";
import MessageBarReducer from "../MessageBarSlice";
import {
  getAllProjectReducer,
  getProjectDetailsReducer,
  getSearchedProjectReducer,
  getWorkingGroupReducer,
} from "../Projects/project";
import isSearchEnabledReducer from "../SearchEnabledSlice";
import isSearchReducer from "../SearchSlice";
import UploadDocReducer from "../UploadDocSlice";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import { getAllUsersReducer } from "../UserListSlice/userList";
import { UpdateUserDetailsReducer, getUserReducer } from "../UserSlice/user";
import GrantPermissionReducer from "../GrantPermissionSlice/grantPermission";
import DeleteProjectReducer from "../ProjectDeleteSlice";
import LoginHintReducer from "../LoginHintSlice";
import documentVersionsReducer from "../DocumentVersionSlice";
import WelcomeUserReducer from "../WelcomeUserSlice";


const customMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});
export const store: any = configureStore({
  reducer: {
    isSearchEnabled: isSearchEnabledReducer,
    searchFiles: isSearchReducer,
    DocumentView: DocumentViewReducer,
    MessageBarView: MessageBarReducer,
    DocumentStack: DocumentStackReducer,
    UploadDoc: UploadDocReducer,
    GrantPermission: GrantPermissionReducer,
    fetchPrecedentDocument: getPrecedentDocumentReducer,
    fetchProjects: getAllProjectReducer,
    fetchSearchedProject: getSearchedProjectReducer,
    fetchSearchedDocument: getSearchedDocumentReducer,
    fetchSearchedVersions: getSearchedVersionsReducer,
    fetchProjectDetails: getProjectDetailsReducer,
    fetchAllUsers: getAllUsersReducer,
    fetchDocumentVersion: getDocumentVersionsReducer,
    fetchPDF: getAllPDF,
    fetchDocuSignAccessToken: getDocuSignCodeReducer,
    shareDocument: shareDocumentReducer,
    fetchNewVersionDetails: createNewVersionReducer,
    shareSentDocument: shareSentDocumentReducer,
    getSentVersionAccess: getSentDocumentAccessReducer,
    createTemplate: createTemplateReducer,
    fetchUser: getUserReducer,
    deleteProject: DeleteProjectReducer,
    fetchLoginHint: LoginHintReducer,
    fetchDocumentWithVersions: documentVersionsReducer,
    getWorkingGroup: getWorkingGroupReducer,
    WelcomeUser: WelcomeUserReducer,
    updateUserDetails:UpdateUserDetailsReducer

  },
  middleware: customMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
