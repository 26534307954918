import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Document } from '../../../models'

export interface DocumentVersionState {
  documents: Document[]
}

const initialState: DocumentVersionState = {
  documents: [],
}

export const documentVersionsSlice = createSlice({
  name: 'precedentFiles',
  initialState,
  reducers: {
    setDocumentWithVersions: (state,action: PayloadAction<Document[]>) => {
      state.documents = action.payload;
    },
  },
})
export const { setDocumentWithVersions} = documentVersionsSlice.actions;
const documentVersionsReducer =documentVersionsSlice.reducer;
export default documentVersionsReducer  