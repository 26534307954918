/* eslint-disable jsx-a11y/alt-text */
import {
  DefaultButton,
  IStyleFunctionOrObject,
  ITextFieldStyleProps,
  ITextFieldStyles,
  Label,
  Modal,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { FC, useState, useCallback, FormEvent } from "react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import Loader from "../../Loader";
import { updateUserDetails } from "../../../modules/redux/UserSlice/user";
import { t } from "i18next";
const MyProfileModal: FC<any> = (props) => {
  const user = JSON.parse(localStorage.getItem("user")!);

  const [firstName, setFirstName] = useState(user ? user?.firstName : "");
  const [lastName, setLastName] = useState(user ? user?.lastName : "");
  const [company, setCompany] = useState(
    user ? user?.dataDictionary?.company : ""
  );
  const [title, setTitle] = useState(user ? user?.dataDictionary?.title : "");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [modal, setModal] = useState<boolean>(false);
  const UpdateUserState = useAppSelector((state) => state.updateUserDetails);

  const dispatch = useAppDispatch();
  const modalStyles = {
    root: {
      background: "rgba(0, 0, 0, 0.5)",
    },
    main: {
      borderRadius: "12px",
      backgroundColor: "#ECF0F9",
      width: "530px",
      minHeight: "132px",
      height: modal
        ? statusMessage === "success"
          ? "132px"
          : "241px"
        : "360px",
    },
  };
  const TextFieldStyles: IStyleFunctionOrObject<
    ITextFieldStyleProps,
    ITextFieldStyles
  > = {
    root: {
      height: "36px",
      backgroundColor: "white",
      borderRadius: "6px",
      width: "230px",
      padding: "1px 0.5px",
      border: "1px solid #E8ECF4",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      fontFamily: "Poppins",
    },
    field: {
      backgroundColor: "white",
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "19.5px",
      fontFamily: "Poppins",
      borderRadius: "6px",
      selectors: {
        "::placeholder": {
          color: "#B7B6B6",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "19.5px",
          fontFamily: "Poppins",
        },
      },
    },
  };
  const EmailStyles: IStyleFunctionOrObject<
    ITextFieldStyleProps,
    ITextFieldStyles
  > = {
    root: {
      height: "36px",
      backgroundColor: "#E8ECF4",
      borderRadius: "6px",
      width: "476px",
      padding: "1px ",
      border: "1px solid #E8ECF4",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      fontFamily: "Poppins",
    },
    field: {
      backgroundColor: "#E8ECF4",
      color: "#323232",
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "19.5px",
      fontFamily: "Poppins",
    },
  };
  const labelStyle = {
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#717070",
  };
  const onChangeFirstName = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue?.length === 0) {
        setFirstNameError(true);
      } else {
        setFirstNameError(false);
      }
      setFirstName(newValue || "");
    },
    []
  );
  const onChangeLastName = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue?.length === 0) {
        setLastNameError(true);
      } else {
        setLastNameError(false);
      }
      setLastName(newValue || "");
    },
    []
  );
  const onChangeCompany = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setCompany(newValue || "");
    },
    []
  );
  const onChangeTitle = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setTitle(newValue || "");
    },
    []
  );
  const disable = () => {
    setFirstNameError(false);
    setLastNameError(false);
    setStatusMessage("");
    setModal(false);
    props.hideModal();
  };

  function Update() {
    if (firstName.length === 0) {
      setFirstNameError(true);
    }
    if (lastName.length === 0) {
      setLastNameError(true);
    }
    if (firstName.length !== 0 && lastName.length !== 0) {
      let data = {
        firstName: firstName,
        lastName: lastName,
        Company: company,
        Title: title,
      };
      dispatch(updateUserDetails(data)).then((res: any) => {
        localStorage.setItem("user", JSON.stringify(res.payload.data));
        setModal(true);
        res.payload.code === 200
          ? setStatusMessage("success")
          : setStatusMessage("error");
        setTimeout(() => {
          disable();
          window.location.reload();
        }, 1500);
      });
    }
  }

  const loaderJSX = (state: any) => {
    return (
      <VerticalStack className="loader">
        {state?.isLoading ? (
          <>
            <Stack className="loading">
              <Loader />
            </Stack>
            <Text
              style={{ padding: "1%", fontSize: "20px", fontFamily: "Poppins" }}
            >
              {t("updating")}
            </Text>
          </>
        ) : (
          <>
            {statusMessage === "error" ? (
              <Stack
                style={{
                  gap: "5px",
                }}
              >
                <Stack
                  style={{
                    height: "107px",
                    padding: "0px 10px ",
                    gap: "10px",
                  }}
                >
                  <HorizontalStack
                    style={{
                      width: "100%",
                      alignItems: "center",
                      gap: "2%",
                    }}
                  >
                    <img src={require("../../../assets/Icons/Warning.png")} />
                    <Text
                      style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                        fontWeight: 500,
                        fontFamily: "Poppins",
                        color: "#A82C0C",
                      }}
                    >
                      {t("something_went_wrong")}
                    </Text>
                  </HorizontalStack>
                  <Text
                    style={{
                      fontSize: "13px",
                      lineHeight: "20px",
                      fontWeight: 400,
                      fontFamily: "Poppins",
                      color: "#717070",
                    }}
                  >
                    {t("update_msg")}
                  </Text>
                </Stack>
                <HorizontalStack
                  style={{
                    width: "514px",
                    borderTop: "1px solid #E8ECF4",
                    padding: "15px 0 0 15px",
                    gap: "13px",
                  }}
                >
                  <DefaultButton
                    style={{
                      width: "112px",
                      height: "36px",
                      backgroundColor: "#A82C0C",
                      color: "#FFFFFF",
                      borderRadius: "6px",
                      border: "1px solid #A82C0C",
                      fontSize: "13px",
                      lineHeight: "19.5px",
                      alignItems: "center",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                    }}
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    {t("try_again")}
                  </DefaultButton>
                  <DefaultButton
                    style={{
                      width: "108px",
                      height: "36px",
                      backgroundColor: "#FCF0ED",
                      color: "#323232",
                      borderRadius: "6px",
                      border: "1px solid #FCF0ED",
                      fontSize: "13px",
                      lineHeight: "19.5px",
                      alignItems: "center",
                      fontWeight: 500,
                      fontFamily: "Poppins",
                    }}
                    onClick={() => {
                      disable();
                    }}
                  >
                    {t("cancel")}
                  </DefaultButton>
                </HorizontalStack>
              </Stack>
            ) : (
              <HorizontalStack
                style={{
                  width: "100%",
                  alignItems: "center",
                  gap: "2%",
                  padding: "10px",
                }}
              >
                <img src={require("../../../assets/Icons/SuccessUpdate.png")} />
                <Text
                  style={{
                    fontSize: "15px",
                    lineHeight: "20px",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    color: "#18468F",
                  }}
                >
                  {t("profile_updated")}
                </Text>
              </HorizontalStack>
            )}
          </>
        )}
      </VerticalStack>
    );
  };

  return (
    <Modal
      styles={modalStyles}
      isOpen={props.isModalOpen}
      onDismiss={props.hideModal}
      isBlocking={true}
    >
      <>
        <Stack style={{ padding: "13px 10px 0px 15px", gap: "2px" }}>
          <Text
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              fontFamily: "Poppins",
              color: "#323232",
            }}
          >
            {modal ? String(t(statusMessage)) : t("my_profile") }
          </Text>
        </Stack>
        {modal ? (
          <VerticalStack
            style={{
              backgroundColor: "white",
              height: statusMessage === "success" ? "72px" : "187px",
              margin: "8px",
              borderRadius: "8px",
              width: "514px",
            }}
          >
            {loaderJSX(UpdateUserState)}
          </VerticalStack>
        ) : (
          <VerticalStack
            style={{
              backgroundColor: "white",
              height: "305px",
              margin: "8px",
              borderRadius: "8px",
              width: "514px",
            }}
          >
            <Stack style={{ padding: "14px 20px", gap: "5px" }}>
              <HorizontalStack
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Stack>
                  <Label required style={labelStyle}>
                    {t("first_name")}
                  </Label>
                  <TextField
                    styles={TextFieldStyles}
                    borderless
                    id="firstName"
                    value={firstName}
                    onChange={onChangeFirstName}
                    placeholder={String(t("first_name"))}
                  />
                  {firstNameError && (
                    <span
                      style={{
                        padding: "4px",
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 400,
                        fontFamily: "Poppins",
                        color: "#A82C0C",
                      }}
                    >
                      {t("required_field")}
                    </span>
                  )}
                </Stack>
                <Stack>
                  <Label required style={labelStyle}>
                    {t("last_name")}
                  </Label>
                  <TextField
                    styles={TextFieldStyles}
                    borderless
                    id="LastName"
                    value={lastName}
                    onChange={onChangeLastName}
                    placeholder={String(t("last_name"))}
                  />
                  {lastNameError && (
                    <span
                      style={{
                        padding: "4px",
                        fontSize: "10px",
                        lineHeight: "12px",
                        fontWeight: 400,
                        fontFamily: "Poppins",
                        color: "#A82C0C",
                      }}
                    >
                      {t("required_field")}
                    </span>
                  )}
                </Stack>
              </HorizontalStack>
              <HorizontalStack
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Stack>
                  <Label style={labelStyle}>{t("company")}</Label>
                  <TextField
                    styles={TextFieldStyles}
                    borderless
                    id="Company"
                    value={company}
                    onChange={onChangeCompany}
                    placeholder={String(t("company_name"))}
                  />
                </Stack>
                <Stack>
                  <Label style={labelStyle}>{t("title")}</Label>
                  <TextField
                    styles={TextFieldStyles}
                    borderless
                    id="Title"
                    value={title}
                    onChange={onChangeTitle}
                    placeholder={String(t("title"))}
                  />
                </Stack>
              </HorizontalStack>
              <Stack>
                <Label style={labelStyle}>{t("email_address")}</Label>
                <TextField
                  styles={EmailStyles}
                  borderless
                  id="Email"
                  disabled
                  value={user?.email}
                />
              </Stack>
            </Stack>
            <HorizontalStack
              style={{
                width: "514px",
                borderTop: "1px solid #E8ECF4",
                padding: "15px 0 0 15px",
                gap: "13px",
              }}
            >
              <DefaultButton
                style={{
                  width: "108px",
                  height: "36px",
                  backgroundColor: "#18468F",
                  color: "white",
                  borderRadius: "6px",
                  border: "1px solid #18468F",
                  fontSize: "13px",
                  lineHeight: "19.5px",
                  alignItems: "center",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                }}
                onClick={() => {
                  Update();
                }}
              >
                {t("update")}
              </DefaultButton>
              <DefaultButton
                style={{
                  width: "108px",
                  height: "36px",
                  backgroundColor: "#E8ECF4",
                  color: "#323232",
                  borderRadius: "6px",
                  border: "1px solid #E8ECF4",
                  fontSize: "13px",
                  lineHeight: "19.5px",
                  alignItems: "center",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                }}
                onClick={() => {
                  disable();
                }}
              >
                {t("cancel")}
              </DefaultButton>
            </HorizontalStack>
          </VerticalStack>
        )}
      </>
    </Modal>
  );
};
export default MyProfileModal;
