import React from "react";

function TrashIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="12" width="12" height="7" rx="1" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11317 1.13208C7.76288 1.13208 7.42695 1.27123 7.17926 1.51892C6.93157 1.76661 6.79242 2.10254 6.79242 2.45283V3.77358H13.2075V2.45283C13.2075 2.10254 13.0684 1.76661 12.8207 1.51892C12.573 1.27123 12.237 1.13208 11.8868 1.13208H8.11317ZM14.3396 3.77358V2.45283C14.3396 1.8023 14.0812 1.17841 13.6212 0.718417C13.1612 0.258422 12.5373 0 11.8868 0H8.11317C7.46264 0 6.83875 0.258422 6.37876 0.718417C5.91876 1.17841 5.66034 1.8023 5.66034 2.45283V3.77358H1.5094C1.19678 3.77358 0.943359 4.02701 0.943359 4.33962C0.943359 4.65224 1.19678 4.90566 1.5094 4.90566H2.83015V17.5472C2.83015 18.1977 3.08857 18.8216 3.54857 19.2816C4.00856 19.7416 4.63245 20 5.28298 20H14.7169C15.3675 20 15.9914 19.7416 16.4514 19.2816C16.9113 18.8216 17.1698 18.1977 17.1698 17.5472V4.90566H18.4905C18.8031 4.90566 19.0566 4.65224 19.0566 4.33962C19.0566 4.02701 18.8031 3.77358 18.4905 3.77358H14.3396ZM3.96223 4.90566V17.5472C3.96223 17.8975 4.10138 18.2334 4.34907 18.4811C4.59676 18.7288 4.9327 18.8679 5.28298 18.8679H14.7169C15.0672 18.8679 15.4032 18.7288 15.6509 18.4811C15.8985 18.2334 16.0377 17.8975 16.0377 17.5472V4.90566H3.96223ZM8.11317 8.49057C8.42578 8.49057 8.67921 8.74399 8.67921 9.0566V14.717C8.67921 15.0296 8.42578 15.283 8.11317 15.283C7.80056 15.283 7.54713 15.0296 7.54713 14.717V9.0566C7.54713 8.74399 7.80056 8.49057 8.11317 8.49057ZM11.8868 8.49057C12.1994 8.49057 12.4528 8.74399 12.4528 9.0566V14.717C12.4528 15.0296 12.1994 15.283 11.8868 15.283C11.5741 15.283 11.3207 15.0296 11.3207 14.717V9.0566C11.3207 8.74399 11.5741 8.49057 11.8868 8.49057Z"
        fill="#323232"
      />
    </svg>
  );
}

export default TrashIcon;
