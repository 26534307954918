import { createSlice } from "@reduxjs/toolkit";

export interface BoxViewState {
  value: boolean;
}

const initialState: BoxViewState = {
  value: true,
};

export const DocumentViewSlice = createSlice({
  name: "DocumentView",
  initialState,
  reducers: {
    setBoxView: (state) => {
      state.value = true;
    },
    setGridView: (state) => {
      state.value = false;
    },
  },
});
export const { setBoxView, setGridView } = DocumentViewSlice.actions;
const DocumentViewReducer = DocumentViewSlice.reducer;
export default DocumentViewReducer;
