import { Stack, Text, IconButton, mergeStyles } from "@fluentui/react";
import { useState, Dispatch, SetStateAction } from "react";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { useTranslation } from "react-i18next";
import { BtFileObject } from "../../../models";
import CrossOutlinedIcon from "../../../assets/Icons/svg/crossOutlineIcon";
import WordDocIcon from "../../../assets/Icons/svg/wordDocIcon";
initializeFileTypeIcons();

interface FileCardProps {
  fileObject: BtFileObject;
  onRemove: () => void;
  onSave: any;
}

const FileCardStackStyle = mergeStyles({
  backgroundColor: "",
  gap: "8px",
  alignItems: "flex-start",
  maxHeight: "200px",
  overflowY: "auto",
  width: "100%",
});

const newFileNameTextStyle = mergeStyles({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontFamily: "Poppins",
  color: "#1F2430",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18px",
  minWidth: "200px",
  width: "400px",
});

const FileCard: React.FC<FileCardProps> = ({
  fileObject,
  onRemove,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newFileName, setNewFileName] = useState(fileObject.fileName);
  const { t } = useTranslation();
  const handleNameChange = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFileName(event.target.value);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNewFileName(fileObject.fileName);
  };

  return (
    <HorizontalStack
      style={{
        border: "1px solid ",
        borderRadius: "5px",
        borderColor:
          // newFileName.length === 0 ||
          // fileObject.fileName.endsWith(".doc") ||
          // fileObject.fileName.endsWith(".DOC")
          //   ? "#a92b1a"
          "#2b579a",
        width: "100%",
        minHeight: "40px",
        alignItems: "center",
        gap: "6px",
        position: "relative",
        alignSelf: "center",
      }}
    >
      <Stack style={{ marginLeft: "14px" }}>
        <WordDocIcon />
      </Stack>
      <HorizontalStack style={{ alignItems: "center" }}>
        {isEditing ? (
          <Stack horizontal verticalAlign="center">
            <input
              title={newFileName}
              type="text"
              value={newFileName}
              onChange={handleInputChange}
              onBlur={() => {
                onSave(newFileName);
                setIsEditing(false);
              }}
              style={{ fontFamily: "Poppins", width: "450px" }}
            />
          </Stack>
        ) : (
          <HorizontalStack
            style={{ width: "100%" }}
            horizontalAlign="space-between"
            verticalAlign="center"
            onClick={handleNameChange}
          >
            <Text block className={newFileNameTextStyle} title={newFileName}>
              {newFileName}
            </Text>
          </HorizontalStack>
        )}
        <Stack
          onClick={onRemove}
          title={String(t("remove"))}
          style={{ cursor: "pointer", position: "absolute", right: 10 }}
        >
          <CrossOutlinedIcon />
        </Stack>
      </HorizontalStack>
    </HorizontalStack>
  );
};

interface DocumentListProps {
  multipleFiles: BtFileObject[];
  setMultipleFiles: Dispatch<SetStateAction<BtFileObject[]>>;
  newVersion?: boolean;
}

const DocumentList: React.FC<DocumentListProps> = ({
  multipleFiles,
  setMultipleFiles,
  newVersion,
}) => {
  const handleRemove = (file: BtFileObject) => {
    // TODO: Remove the file from the list
    setMultipleFiles((prevFiles) =>
      prevFiles.filter((current) => current.fileName !== file.fileName)
    );
  };

  function updateFileNameWithExtension(name: string, filename: string) {
    const lowerFilename = filename.toLowerCase();
    if (lowerFilename.endsWith(".doc")) {
      if (!name.toLowerCase().endsWith(".doc")) {
        return name + ".doc";
      }
    } else if (lowerFilename.endsWith(".docx")) {
      if (!name.toLowerCase().endsWith(".docx")) {
        return name + ".docx";
      }
    }
    return name;
  }

  const handleSave = (fileObject: BtFileObject, name: string) => {
    const newFileName = updateFileNameWithExtension(name, fileObject.file.name);
    let document: BtFileObject = {
      fileName: newFileName,
      file: fileObject.file,
    };
    let copyArray = [...multipleFiles];
    const index = multipleFiles.findIndex(
      (docObject) => docObject.fileName === fileObject.fileName
    );
    if (index !== -1) {
      copyArray.splice(index, 1, document);
    }
    setMultipleFiles(copyArray);
  };

  return (
    <VerticalStack className={FileCardStackStyle}>
      {multipleFiles &&
        multipleFiles.map((file) => (
          <FileCard
            key={file.fileName}
            fileObject={file}
            onRemove={() => handleRemove(file)}
            onSave={(fileName: string) => handleSave(file, fileName)}
          />
        ))}
    </VerticalStack>
  );
};

export default DocumentList;
