import { FAQ } from "../../models";

const Data: FAQ[] = [
  {
    question: "What is Dexterity?",
    answer:
      " Dexterity is a secure Word plug-in for document negotiation. Behind the scenes, we reduce the work you have to do as a lawyer and financier to prepare a compliant document. We work hard to remove all the extra steps to draft a document; negotiate documents back and forth, and then Docusign those documents once they are finalized to your satisfaction.",
  },
  {
    question: "Where can I find a document?",
    answer:
      "You can draft, negotiate, or sign documents.  Documents live in one of those 3 tabs.  You can always upload your own document by selecting it, or browse to it, or drag and drop it to begin.",
    link: "https://mac.dexterity.legal/home",
  },
  {
    question: "What if I don't use Word or Outlook?",
    answer:
      " We work with lawyers, financiers, and investors who use Word or Outlook as a part of the agreements they negotiate.  We are optimized for their workflow, and we do that in Word and Outlook.  If you don't use Word, then we are probably not the right plug-in to help you create and negotiate documents.",
  },
  {
    question: "Where can I find the most recent documents I've worked on?",
    answer:
      "We keep your precedent files stored in the order you've used them in recently.  Our lawyers tell us that it's the easiest way to find the documents they want.",
  },
  {
    question:
      "What if I want an older document I haven't worked on in a while?",
    answer:
      "No problem!  You can always view all your documents by clicking on 'view all ->' in the right hand corner of your document's ribbon.",
  },
  {
    question: "I am having trouble, how can I get help?",
    answer:
      "You can always email, call, or chat us - we posted the numbers below to reach us anytime.",
  },
  {
    question: "What does the Word Plug-In do?",
    answer:
      "The plug-in provides Draft, Negotiate, and Sign functionality (we integrated Docusign via API).",
  },
];

export default Data;
