import React from "react";

function ShareBlackIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M0.484375 10.0262L9.42818 14.9005L20.3843 20.9353L23.5147 0.509766L0.484375 10.0262Z"
        fill="#E8ECF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8721 0.16559C24.053 0.373481 24.0397 0.696493 23.8423 0.88706L9.29685 14.9296C9.09946 15.1202 8.79276 15.1061 8.61182 14.8982C8.43088 14.6903 8.44421 14.3673 8.6416 14.1768L23.187 0.134222C23.3844 -0.0563441 23.6911 -0.0423001 23.8721 0.16559Z"
        fill="#323232"
      />
      <path
        d="M13.8182 17.3593L8.96973 14.5508V23.4869L13.8182 17.3593Z"
        fill="#E8ECF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.72245 14.1141C8.87001 14.0222 9.05268 14.0192 9.20288 14.1062L14.0514 16.9147C14.1782 16.9882 14.2673 17.1175 14.2941 17.2674C14.321 17.4172 14.2829 17.5719 14.1902 17.689L9.34169 23.8167C9.21101 23.9818 8.99611 24.0431 8.80417 23.9699C8.61223 23.8967 8.48438 23.7048 8.48438 23.4898V14.5536C8.48438 14.3731 8.5749 14.2059 8.72245 14.1141ZM9.45407 15.4172V22.0794L13.0687 17.511L9.45407 15.4172Z"
        fill="#323232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8194 0.113094C23.955 0.22818 24.0215 0.41098 23.9938 0.592012L20.8635 21.0175C20.8386 21.1796 20.7413 21.3192 20.602 21.3927C20.4626 21.4662 20.2982 21.4647 20.1601 21.3886L9.20583 15.3549L9.20396 15.3538L0.262025 10.4806C0.09302 10.3885 -0.00920183 10.2008 0.000653915 10.0006C0.0105096 9.80048 0.13062 9.62492 0.307766 9.55172L23.3381 0.0352841C23.5001 -0.0316915 23.6839 -0.00199198 23.8194 0.113094ZM1.65735 10.0911L9.65335 14.4489L20.0134 20.1553L22.9011 1.31292L1.65735 10.0911Z"
        fill="#323232"
      />
    </svg>
  );
}

export default ShareBlackIcon;
