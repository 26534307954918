import { Stack, Text } from "@fluentui/react";
import React, { FC } from "react";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { DocumentWithTrack } from "../NewShareModal";
import { useTranslation } from "react-i18next";
import { Document } from "../../../models";
import Cross from "./icons/Cross";
import PdfIcon from "../../../assets/Icons/svg/pdfIcon";

type SelectedDocumentProps = {
  modal?: string;
  setSelectedDocuments?: React.Dispatch<React.SetStateAction<Document[]>>;
  documentWithTrack: DocumentWithTrack;
  setDocumentWithTrack: React.Dispatch<
    React.SetStateAction<DocumentWithTrack[]>
  >;
  length: number;
};

const SelectedDocumentStyles = {
  width: "208px",
  height: "40px",
  padding: "6px 10px",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "6px",
  border: "1px solid var(--LIGHT-BLUE, #FCF0ED)",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontWeight: 500,
  backgroundColor: "#FCF0ED",
};

const versionDocumentStyle = {
  padding: "4px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "4px",
  border: "1px solid var(--LIGHT-BLUE, #FCF0ED)",
  backgroundColor: "#FFF",
  fontFamily: "Poppins",
  fontSize: "10px",
  fontWeight: 500,
  color: "#A82C0C",
  height: "20px",
};

const PdfTrackCard: FC<SelectedDocumentProps> = (props) => {
  const { t } = useTranslation();

  const handleRemovePdf = () => {
    props?.setDocumentWithTrack((prev) =>
      prev.map((item) => {
        if (
          item.document.rootDocumentId ===
          props.documentWithTrack.document.rootDocumentId
        ) {
          return {
            ...item,
            includePdf: false
          };
        } else {
          return item;
        }
      })
    );
  };
  

  return (
    <HorizontalStack style={{ position: "relative", alignItems: "center" }}>
      <div
        style={{
          position: "absolute",
          top: "-7px",
          right: "0",
          transform: "translate(50%, 0)",
          cursor: "pointer",
        }}
        onClick={handleRemovePdf}
      >
        <Cross color={"#A82C0C"} />
      </div>
      <Stack>
        <svg
          width="9"
          height="2"
          viewBox="0 0 9 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 1H8.5" stroke="#A82C0C" />
        </svg>
      </Stack>
      <HorizontalStack style={SelectedDocumentStyles}>
        <HorizontalStack style={{ gap: "8px", alignItems: "center" }}>
          <PdfIcon />
          <VerticalStack style={{ gap: "4px" }}>
            <Text
              style={{
                fontFamily: "Poppins",
                fontSize: "9px",
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "130px",
                lineHeight: "12px",
              }}
              title={props?.documentWithTrack.document?.rootDocumentName}
            >
              {props?.documentWithTrack.document?.rootDocumentName?.replace(
                /\.[^/.]+$/,
                ""
              )}
            </Text>
            <HorizontalStack style={{ alignItems: "center", gap: "4px" }}>
              <Text
                style={{
                  color: "var(--primary-Red, #A82C0C)",
                  fontFamily: "Poppins",
                  fontSize: "9px",
                  lineHeight: "12px",
                  fontWeight: "400",
                }}
              >
                PDF of Redline
              </Text>
            </HorizontalStack>
          </VerticalStack>
        </HorizontalStack>
        <HorizontalStack style={versionDocumentStyle}>
          {`P${props?.documentWithTrack.document?.documentVersion}`}
        </HorizontalStack>
      </HorizontalStack>
    </HorizontalStack>
  );
};

export default PdfTrackCard;
