import { FC, PropsWithChildren } from "react";



const cardStyle = {
  padding: "6.5px",
  width: "fit-content",
  height: "fit-content",
  boxShadow:"4px 4px 4px 0px rgba(0, 0, 0, .15)",
  backgroundColor:"white",
};

export const ProjectCard: FC<PropsWithChildren> = (props) => {
  const { children, ...rest } = props;
  return (
    <div {...rest} style={cardStyle}>
      {children}
    </div>
  );
};
