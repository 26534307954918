import { FontSizes, Stack, Text, mergeStyles } from "@fluentui/react";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

const Docusign: FC = () => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  let url = params.get("url");
  let code = params.get("code");
  let username = params.get("userName");
  let email = params.get("email");
  if (params.has("userName") && params.has("email")) {
    localStorage.setItem("userName", username ? username : "");
    localStorage.setItem("email", email ? email : "");
  }
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const {t} = useTranslation();

  useEffect(() => {
    if (url && !code) {
      window.location.replace(decodeURIComponent(url));
    }
    if (code) {
      setIsLoading(true);
      setMessage("");
      axios
        .get(`https://bt-api.nl-demo.com/api/v1/docusign/code?code=${code}`)
        .then((response) => {
          axios
            .post(
              `https://bt-api.nl-demo.com/api/v1/docusign/user`,
              {
                displayName: localStorage.getItem("userName"),
                email: localStorage.getItem("email"),
              },
              {
                headers: {
                  docusignAccessToken: response.data,
                },
              }
            )
            .then((res) => {
              setIsLoading(false);
              setMessage("User has been added");
             
              localStorage.removeItem("email");
              localStorage.removeItem("userName");
            })
            .catch(() => {
              setMessage("There was some error while adding user");
            });
        });
    }
  }, [url, code]);

  const loaderJSX = isLoading && (
    <Stack className={mergeStyles(styles.loaderStyles)}>
      <Loader />
      <Text style={styles.loader}>{t("loading")}</Text>
    </Stack>
  );
  return (
    <Stack>
    {
      loaderJSX ? loaderJSX : 
      message && (
        <Stack style={{height:"100%", alignItems:'center', display:'flex'}}>
    <Stack style={styles.container}>
      <Text className={mergeStyles(styles.text)}>
        {message}
      </Text>
    </Stack>
    </Stack>
      )
    }
    </Stack>
  );
};

export default Docusign;

const styles = {
  text: {
    fontSize:FontSizes.size42
  },
  container: {
    margin: "2rem",
    backgroundColor: "",
    alignSelf: 'center',
    alignItems: "center",
    justifyContent: "center",
    height:'50vh',
    width:'78%',
    border:"2px solid #2b579a",
    borderRadius: '0.4rem',
    marginTop: "15vh",
    boxShadow: "0 8px 16px 0 rgba(0, 0, 0, .21), 0 4px 8px 0 rgba(0, 0, 0, .15)",
  },
  loaderStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "18rem",
    alignItems: "center",
    marginTop: "1.5%",
  },
  loader:{
    padding:'2vh',
    fontSize:FontSizes.size32
  }
};
