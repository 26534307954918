import React from "react";

export default function Multiple() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M1 10L7 13L13 10M1 7L7 10L13 7M7 1L1 4L7 7L13 4L7 1Z"
          stroke="#717070"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
