import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Project, response, UserModel } from "../../../models";
import { ApiClient } from "../../../network/ApiClient";
import { ApiEndpoints } from "../../../network/ApiEndpoints";
import { getSortedArrayMRU } from "../../../utils";

type InitialState = {
  isLoading: boolean;
  data: Project[];
  error: string;
  metadata?: {
    count: number;
    offset: number;
    limit: number;
  };
};

const initialState: InitialState = {
  isLoading: false,
  data: [],
  error: "",
  metadata: {
    count: 0,
    offset: 0,
    limit: 0,
  },
};

type ProjectInitialState = {
  isLoading: boolean;
  data: Project | null;
  error: string;
};

const projectInitialState: ProjectInitialState = {
  isLoading: false,
  data: null,
  error: "",
};

type WorkingGroupInitialState = {
  isLoading: boolean;
  data: UserModel[];
  error: string;
};

const workingGroupInitialState: WorkingGroupInitialState = {
  isLoading: false,
  data: [],
  error: "",
};

export const getAllProjects = createAsyncThunk(
  "getAllProjects",
  async (payload: { limit?: number; offset?: number }) => {
    const response = await ApiClient.get<any>(
      ApiEndpoints.getAllProjects(),
      {
        Limit: payload.limit,
        Offset: payload.offset,
      },
      {}
    );
    return response;
  }
);

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProjects.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllProjects.fulfilled,
      (state, action: PayloadAction<response>) => {
        const newData = action.payload.data.data;
        state.isLoading = false;
        if (
          action.payload.data.metadata === null ||
          action.payload.data.metadata?.offset === 0
        ) {
          var projects = [...newData];
          state.data = projects; // For the first load, replace data
        } else {
          state.data = state.data.concat(newData); // For subsequent loads, concatenate data
        }
        state.error = "";
        state.metadata = action.payload.data.metadata;
      }
    );
    builder.addCase(getAllProjects.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const getSearchedProjects = createAsyncThunk(
  "getSearchedProjects",
  async (payload: { projectName: string; limit: number; offset: number }) => {
    // const encodedSearchParam = encodeURIComponent(payload.projectName);
    let response = await ApiClient.get<any>(
      ApiEndpoints.searchFolders(),
      {
        searchParam: payload.projectName,
        limit: payload.limit,
        offset: payload.offset,
      },
      {}
    );
    return response.data;
  }
);
export const projectSearchSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSearchedProjects.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getSearchedProjects.fulfilled,
      (state, action: PayloadAction<response>) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.error = "";
        state.metadata = action.payload.metadata;
      }
    );
    builder.addCase(getSearchedProjects.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const getAllProjectDetails = createAsyncThunk(
  "getAllProjectData",
  async (projectId: number) => {
    let response = await ApiClient.get<any>(
      ApiEndpoints.getFolderDetails(projectId),
      {},
      {}
    );
    return response.data;
  }
);
export const getProjectDetailsSlice = createSlice({
  name: "projects",
  initialState: projectInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProjectDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllProjectDetails.fulfilled,
      (state, action: PayloadAction<Project>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getAllProjectDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.data = null;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const getWorkingGroup = createAsyncThunk(
  "getWorkingGroup",
  async (projectId: number) => {
    let response = await ApiClient.get<any>(
      ApiEndpoints.getWorkingGroup(projectId),
      {},
      {}
    );
    return response.data;
  }
);
export const getWorkingGroupSlice = createSlice({
  name: "workingGroup",
  initialState: workingGroupInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWorkingGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getWorkingGroup.fulfilled,
      (state, action: PayloadAction<UserModel[]>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getWorkingGroup.rejected, (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const deleteProject = createAsyncThunk(
  "deleteProject",
  async (projectId: number) => {
    let response: any = await ApiClient.delete<any>(
      ApiEndpoints.deleteProject(projectId),
      {},
      {}
    );
    return response;
  }
);

export const getAllProjectReducer = projectSlice.reducer;
export const getSearchedProjectReducer = projectSearchSlice.reducer;
export const getProjectDetailsReducer = getProjectDetailsSlice.reducer;
export const getWorkingGroupReducer = getWorkingGroupSlice.reducer;
