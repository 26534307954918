import { Stack, IStackStyles, Text } from "@fluentui/react";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../BackButton";
import { disableSearch } from "../../modules/redux/SearchEnabledSlice";
import { useTranslation } from "react-i18next";

interface PageTitleProps {
  pageTitle: string;
  search: string;
  project?: boolean;
}

const StackStyles: IStackStyles = {
  root: {
    height: "4rem",
    justifyContent: "center",
    backgroundColor: "",
    marginBottom: "3rem",
    marginTop: "2rem",
  },
};

export const PageTitle: FC<PageTitleProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  function click() {
    dispatch(disableSearch());
    navigate(-1);
  }
  return (
    <Stack styles={StackStyles}>
      <Stack style={styles.top}>
        <BackButton onClickFunction={click} />
      </Stack>
        <Stack style={styles.middle}>
          <Text style={styles.header}>{props.pageTitle}</Text>
        </Stack>
    </Stack>
  );
};

const styles = {
  middle: {
    backgroundColor: "",
    width: "100%",
    alignItems: "flex-start",
    paddingLeft: "1%",
  },
  center: {
    backgroundColor: "",
    width: "100%",
    alignItems: "center",
    paddingLeft: "1%",
  },
  header: {
    fontSize: "32px",
    fontFamily:"Poppins",
    fontWeight:"500",
    lineHeight:"42px",
    color:"#000"
  },
  top: {
    backgroundColor: "",
    alignItems: "flex-start",
  },
  heading:{
    paddingBottom: 15,
    paddingTop: 10,
  }
};
