import { createSlice } from "@reduxjs/toolkit";

export interface UploadDocState {
  value: boolean;
}

const initialState: UploadDocState = {
  value: false,
};

export const UploadDocSlice = createSlice({
  name: "UploadDoc",
  initialState,
  reducers: {
    uploadDoc: (state) => {
      state.value = false;
    },
    setUploadDoc: (state) => {
      state.value = true;
    },
  },
});
export const { uploadDoc, setUploadDoc } = UploadDocSlice.actions;
const UploadDocReducer = UploadDocSlice.reducer;
export default UploadDocReducer;
