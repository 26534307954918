import { FC } from "react";
import useModal from "../../../hooks/useModal";
import { VerticalStack } from "../../Stack";
import { DefaultButton, IStackStyles, Text } from "@fluentui/react";
import { CreateProjectModal } from "../CreateProjectModal";
import { ProjectCard } from "../../Cards/ProjectCard";
import { useTranslation } from "react-i18next";
import { colorPallete } from "../../../assets/color";

const headerStyle = {
  fontSize: "18px",
  color: colorPallete.black1,
  fontWeight: 400,
  fontFamily:"Poppins",

};

const addIcon = {
  iconName: "CircleAdditionSolid",
  styles: {
    root: { color: colorPallete.btBlue, fontSize: "48px" },
  },
};

const buttonStyle = {
  backgroundColor: colorPallete.white,
  borderColor: colorPallete.white,
  margin: "1rem",
};

const verticalStackStyles: IStackStyles = {
  root: {
    height: "100%",
    backgroundColor: "",
  },
};

export const NewProject: FC = () => {
  const { isModalOpen, showModal, hideModal } = useModal();
  const { t } = useTranslation();

  return (
    <ProjectCard>
      <div
        className="Card"
        onClick={showModal}
      >
        <VerticalStack
          verticalAlign="center"
          styles={verticalStackStyles}
          horizontalAlign="center"
        >
          <DefaultButton
            iconProps={addIcon}
            style={buttonStyle}
            title={String(t("click_to_create_new_project"))}
          />
          <Text style={{...headerStyle,  textAlign:"center"}}>{t("create_new_project")}</Text>
        </VerticalStack>
        <CreateProjectModal
          isModalOpen={isModalOpen}
          showModal={showModal}
          hideModal={hideModal}
        />
      </div>
    </ProjectCard>
  );
};
